<template>
  <div :class="['main-container', { collapsed: isCollapsed }]">
    <Sidebar @toggle="handleToggle" :collapsed="isCollapsed" />
    <div :class="['content-container', { collapsed: isCollapsed }]">
      <div style="
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      " v-if="loading">
        <LoadingSpinner />
      </div>
      <div v-if="!loading">
        <InstructorHeader pageTitle="My Courses" />
        
        <div class="courses-actions">
          <div class="new-course-button" @click="showNewCourseModal = true">
            New Course
            <span class="material-symbols-outlined" style="padding-left: 1vh">
              add
            </span>
          </div>
        </div>

        <!-- Course Statistics -->
        <div class="stats-row">
          <div class="stat-card">
            <span class="material-symbols-outlined">school</span>
            <div class="stat-value">{{ totalCourses }}</div>
            <div class="stat-label">Active Courses</div>
          </div>
          <div class="stat-card">
            <span class="material-symbols-outlined">groups</span>
            <div class="stat-value">{{ totalStudents }}</div>
            <div class="stat-label">Total Students</div>
          </div>
          <div class="stat-card">
            <span class="material-symbols-outlined">assignment</span>
            <div class="stat-value">{{ totalAssignments }}</div>
            <div class="stat-label">Total Assignments</div>
          </div>
          <div class="stat-card">
            <span class="material-symbols-outlined">analytics</span>
            <div class="stat-value">{{ averagePerformance }}%</div>
            <div class="stat-label">Average Performance</div>
          </div>
        </div>

        <!-- Course Grid -->
        <div class="courses-grid">
          <div v-for="course in courses" :key="course.course_id" class="course-card" @click="viewCourse(course)">
            <div class="course-header" :style="{ backgroundColor: getCourseColor(course.course_code) }">
              <div class="course-code">{{ course.course_code }}</div>
              <div class="course-actions">
                <span class="material-symbols-outlined action-icon" @click.stop="editCourse(course)">edit</span>
                <span class="material-symbols-outlined action-icon" @click.stop="archiveCourse(course)">archive</span>
              </div>
            </div>
            <div class="course-content">
              <h3 class="course-name">{{ course.course_name }}</h3>
              <div class="course-stats">
                <div class="stat-item">
                  <span class="material-symbols-outlined">groups</span>
                  <span>{{ course.students }} Students</span>
                </div>
                <div class="stat-item">
                  <span class="material-symbols-outlined">assignment</span>
                  <span>{{ course.assignments }} Assignments</span>
                </div>
                <div class="stat-item">
                  <span class="material-symbols-outlined">analytics</span>
                  <span>{{ course.average }}% Average</span>
                </div>
              </div>
              <div class="course-progress">
                <div class="progress-label">
                  <span>Course Progress</span>
                  <span>{{ course.progress }}%</span>
                </div>
                <div class="progress-bar">
                  <div class="progress-fill" :style="{ width: `${course.progress}%`, backgroundColor: getCourseColor(course.course_code) }"></div>
                </div>
              </div>
            </div>
            <div class="course-footer">
              <div class="next-deadline" v-if="course.next_deadline">
                <span class="material-symbols-outlined">event</span>
                <span>Next: {{ formatDate(course.next_deadline) }}</span>
              </div>
              <div class="view-course">
                View Course <span class="material-symbols-outlined">chevron_right</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- New Course Modal -->
    <div v-if="showNewCourseModal" class="modal-overlay" @click="showNewCourseModal = false">
      <div class="modal-content" @click.stop>
        <h2>Create New Course</h2>
        <form @submit.prevent="createCourse">
          <div class="form-group">
            <label>Course Code</label>
            <input v-model="newCourse.code" type="text" placeholder="e.g. CS101" required />
          </div>
          <div class="form-group">
            <label>Course Name</label>
            <input v-model="newCourse.name" type="text" placeholder="e.g. Introduction to Computer Science" required />
          </div>
          <div class="form-group">
            <label>Description</label>
            <textarea v-model="newCourse.description" placeholder="Course description..." rows="3"></textarea>
          </div>
          <div class="form-actions">
            <button type="button" class="cancel-btn" @click="showNewCourseModal = false">Cancel</button>
            <button type="submit" class="create-btn">Create Course</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/InstructorSidebar.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useInstructorStore } from "@/store/instructorData";
import axiosInstance from "@/utils/axiosInstance";
import InstructorHeader from "@/components/InstructorHeader.vue";

export default {
  name: "InstructorCourses",
  components: {
    Sidebar,
    LoadingSpinner,
    InstructorHeader,
  },
  data() {
    return {
      loading: true,
      isCollapsed: false,
      courses: [],
      showNewCourseModal: false,
      newCourse: {
        code: '',
        name: '',
        description: ''
      },
      courseColors: {
        'CS': '#4CAF50',
        'MATH': '#2196F3',
        'PHY': '#9C27B0',
        'CHEM': '#F44336',
        'BIO': '#4CAF50',
        'ENG': '#FF9800',
        'HIST': '#795548',
      }
    };
  },
  computed: {
    instructorStore() {
      return useInstructorStore();
    },
    totalCourses() {
      return this.courses.length;
    },
    totalStudents() {
      return this.courses.reduce((sum, course) => sum + course.students, 0);
    },
    totalAssignments() {
      return this.courses.reduce((sum, course) => sum + course.assignments, 0);
    },
    averagePerformance() {
      const validCourses = this.courses.filter(course => course.average > 0);
      if (validCourses.length === 0) return 0;
      const total = validCourses.reduce((sum, course) => sum + course.average, 0);
      return (total / validCourses.length).toFixed(1);
    }
  },
  async mounted() {
    await this.fetchCourses();
  },
  methods: {
    handleToggle(collapsed) {
      this.isCollapsed = !collapsed;
    },
    async fetchCourses() {
      try {
        const response = await axiosInstance.get("/instructor/courses", {
          params: { email: this.instructorStore.email }
        });
        
        this.courses = response.data.map(course => ({
          ...course,
          progress: this.calculateProgress(course)
        }));
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        this.loading = false;
      }
    },
    calculateProgress(course) {
      // Calculate course progress based on completed assignments and timeline
      const now = new Date();
      const start = new Date(course.start_date);
      const end = new Date(course.end_date);
      const total = end - start;
      const current = now - start;
      return Math.min(Math.round((current / total) * 100), 100);
    },
    getCourseColor(courseCode) {
      const prefix = courseCode.match(/[A-Z]+/)?.[0] || 'DEFAULT';
      return this.courseColors[prefix] || '#607D8B';
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    },
    viewCourse(course) {
      this.$router.push({
        name: 'InstructorGrading',
        params: { courseId: course.course_id }
      });
    },
    editCourse(course) {
      // TODO: Implement course editing
      console.log('Edit course:', course);
    },
    archiveCourse(course) {
      // TODO: Implement course archiving
      console.log('Archive course:', course);
    },
    async createCourse() {
      try {
        const response = await axiosInstance.post("/instructor/courses", {
          ...this.newCourse,
          instructor_email: this.instructorStore.email
        });
        
        this.courses.push({
          ...response.data,
          progress: 0,
          students: 0,
          assignments: 0,
          average: 0
        });
        
        this.showNewCourseModal = false;
        this.newCourse = { code: '', name: '', description: '' };
      } catch (error) {
        console.error("Error creating course:", error);
      }
    }
  }
};
</script>

<style scoped>
.main-container {
  display: flex;
  transition: all 0.3s ease-in-out;
  font-family: var(--main-font);
  margin: 2vh 2vw;
}

.main-container.collapsed .content-container {
  margin-left: 6vw;
}

.main-container:not(.collapsed) .content-container {
  margin-left: 18vw;
}

.content-container {
  flex: 1;
  transition: margin-left 0.3s ease;
  color: var(--primary);
}

.courses-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.navigation-info {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--classi2);
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 1rem;
  color: var(--secondary-text);
  margin: 0;
}

.courses-actions {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.new-course-button {
  display: flex;
  align-items: center;
  background-color: var(--classi2);
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.new-course-button:hover {
  background-color: var(--classi1);
}

/* Stats Row */
.stats-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-card {
  background: var(--card-bg);
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-card .material-symbols-outlined {
  font-size: 2rem;
  color: var(--classi2);
  margin-bottom: 0.5rem;
}

.stat-value {
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary);
  margin: 0.5rem 0;
}

.stat-label {
  font-size: 0.9rem;
  color: var(--secondary-text);
}

/* Course Grid */
.courses-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1.5rem;
}

.course-card {
  background: var(--card-bg);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  cursor: pointer;
}

.course-card:hover {
  transform: translateY(-4px);
}

.course-header {
  padding: 1rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-code {
  font-weight: 600;
  font-size: 1.1rem;
}

.course-actions {
  display: flex;
  gap: 0.5rem;
}

.action-icon {
  cursor: pointer;
  padding: 0.3rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.action-icon:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.course-content {
  padding: 1.5rem;
}

.course-name {
  margin: 0 0 1rem 0;
  font-size: 1.2rem;
  color: var(--primary);
}

.course-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: var(--secondary-text);
}

.stat-item .material-symbols-outlined {
  font-size: 1.2rem;
  color: var(--classi2);
}

.course-progress {
  margin-top: 1rem;
}

.progress-label {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: var(--secondary-text);
  margin-bottom: 0.5rem;
}

.progress-bar {
  height: 6px;
  background-color: var(--border-color);
  border-radius: 3px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  transition: width 0.3s ease;
}

.course-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
}

.next-deadline {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--secondary-text);
}

.view-course {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: var(--classi2);
  font-weight: 500;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: var(--secondary);
  padding: 2rem;
  border-radius: 12px;
  width: 500px;
  max-width: 90vw;
}

.modal-content h2 {
  margin: 0 0 1.5rem 0;
  color: var(--primary);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--primary);
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background: var(--background);
  color: var(--primary);
  font-size: 1rem;
}

.form-group textarea {
  resize: vertical;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.cancel-btn,
.create-btn {
  padding: 0.8rem 1.5rem;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.cancel-btn {
  background: var(--background);
  color: var(--primary);
  border: 1px solid var(--border-color);
}

.create-btn {
  background: var(--classi2);
  color: white;
  border: none;
}

.cancel-btn:hover,
.create-btn:hover {
  transform: translateY(-2px);
}
</style> 