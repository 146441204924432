<template>
  <div
    ref="tiptapContainer"
    v-if="editor"
    :class="['tiptap-container', 'fade-in-out', { expanded: isExpanded }]"
  >
    <div v-if="isExpanded && editable" class="control-group sticky-toolbar">
      <div class="button-group">
        <button
          @click="editor.chain().focus().toggleBold().run()"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          <Tooltip text="Bold">
            <span class="material-symbols-outlined"> format_bold </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleItalic().run()"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          <Tooltip text="Italic">
            <span class="material-symbols-outlined"> format_italic </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleStrike().run()"
          :disabled="!editor.can().chain().focus().toggleStrike().run()"
          :class="{ 'is-active': editor.isActive('strike') }"
        >
          <Tooltip text="Strikethrough">
            <span class="material-symbols-outlined"> strikethrough_s </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleCodeBlock().run()"
          :class="{ 'is-active': editor.isActive('codeBlock') }"
        >
          <Tooltip text="Insert code block">
            <span class="material-symbols-outlined"> code </span>
          </Tooltip>
        </button>
        <button @click="editor.commands.unsetAllMarks()">
          <Tooltip text="Clear formatting">
            <span class="material-symbols-outlined"> format_clear </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{
            'is-active': editor.isActive('heading', {
              level: 1,
            }),
          }"
        >
          <Tooltip text="Heading 1">
            <span class="material-symbols-outlined"> format_h1 </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{
            'is-active': editor.isActive('heading', {
              level: 2,
            }),
          }"
        >
          <Tooltip text="Heading 2">
            <span class="material-symbols-outlined"> format_h2 </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          :class="{
            'is-active': editor.isActive('heading', {
              level: 3,
            }),
          }"
        >
          <Tooltip text="Heading 3">
            <span class="material-symbols-outlined"> format_h3 </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          <Tooltip text="Bullet list">
            <span class="material-symbols-outlined">
              format_list_bulleted
            </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
        >
          <Tooltip text="Numbered list">
            <span class="material-symbols-outlined">
              format_list_numbered
            </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'is-active': editor.isActive('blockquote') }"
        >
          <Tooltip text="Block quote">
            <span class="material-symbols-outlined"> format_quote </span>
          </Tooltip>
        </button>
        <button @click="editor.chain().focus().setHorizontalRule().run()">
          <Tooltip text="Horizontal rule">
            <span class="material-symbols-outlined"> horizontal_rule </span>
          </Tooltip>
        </button>

        <button
          @click="editor.chain().focus().undo().run()"
          :disabled="!editor.can().chain().focus().undo().run()"
        >
          <Tooltip text="Undo">
            <span class="material-symbols-outlined"> undo </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().redo().run()"
          :disabled="!editor.can().chain().focus().redo().run()"
        >
          <Tooltip text="Redo">
            <span class="material-symbols-outlined"> redo </span>
          </Tooltip>
        </button>

        <div>
          <button @click="triggerColorPicker">
            <Tooltip text="Text color">
              <span
                class="material-symbols-outlined"
                :style="{
                  color: editor.getAttributes('textStyle').color,
                }"
              >
                format_color_text
              </span>
            </Tooltip>
          </button>
          <input
            ref="colorPicker"
            type="color"
            @input="setColor"
            :value="editor.getAttributes('textStyle').color"
            class="hidden-color-picker"
          />
        </div>

        <div>
          <button @click="triggerHighlightPicker">
            <Tooltip text="Highlighter">
              <span
                class="material-symbols-outlined"
                :style="{
                  color: editor.getAttributes('highlight').color,
                }"
              >
                format_ink_highlighter
              </span>
            </Tooltip>
          </button>
          <input
            ref="highlightPicker"
            type="color"
            @input="setHighlightColor"
            :value="editor.getAttributes('highlight').color"
            class="hidden-highlight-picker"
          />
        </div>
      </div>
    </div>
    <editor-content
      ref="editor"
      :class="['editor', { editorExpanded: isExpanded }]"
      :editor="editor"
      tabindex="0"
      @mousedown="focusEditor"
    />

    <div v-if="isExpanded" class="save-status">
      <span v-if="saveStatus === 'saving'" class="saving">
        <span class="dot-animation">Saving</span>
      </span>
      <span v-else-if="saveStatus === 'saved'" class="saved">
        <span class="material-symbols-outlined">check_circle</span> Saved
      </span>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import Color from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import Placeholder from "@tiptap/extension-placeholder";
import Tooltip from "./Tooltip.vue";
import { Markdown } from "tiptap-markdown";

export default {
  components: {
    EditorContent,
    Highlight,
    Typography,
    Placeholder,
    Tooltip,
  },

  props: ["editable", "content"],

  data() {
    return {
      editor: null,
      isExpanded: false,
      saveStatus: null,
      debounceTimeout: null,
      lastContent: null,
      lastSaveTime: Date.now(),
      minSaveInterval: 5000, // Minimum time between saves (5 seconds)
      contentChanged: false,
      isInitializing: true, // Flag to track initial content loading
    };
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        Markdown,
        Color.configure({ types: [TextStyle.name, ListItem.name] }),
        TextStyle.configure({ types: [ListItem.name] }),
        StarterKit,
        Placeholder.configure({
          placeholder: "Your answer here...",
        }),
        Highlight.configure({ multicolor: true }),
      ],
      content: `<p></p>`,
      editable: this.editable,
      onUpdate: ({ editor }) => {
        // Only track changes and save if we're not in initialization phase
        if (!this.isInitializing) {
          this.contentChanged = true;
          this.debouncedSave();
        }
      },
      onBlur: ({ editor }) => {
        // Save immediately when the editor loses focus if content has changed
        if (this.contentChanged && !this.isInitializing) {
          this.saveContentNow();
        }
      }
    });

    // Store the initial content
    this.editor.commands.insertContent(this.content.response_content);
    this.lastContent = this.editor.getJSON();
    
    // Set a timeout to mark initialization as complete after the editor has fully loaded
    setTimeout(() => {
      this.isInitializing = false;
    }, 500);

    document.addEventListener("click", this.handleClickOutside);
    
    // Add window beforeunload event to save content when user leaves the page
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    
    // Clear any pending debounce timers
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
      // If there are pending changes, save them before unmounting
      if (this.contentChanged) {
        this.saveContentNow();
      }
    }
    this.editor.destroy();
  },

  methods: {
    focusEditor() {
      this.editor.commands.focus();
      this.isExpanded = true;
    },
    
    // Handle page unload event
    handleBeforeUnload(event) {
      if (this.contentChanged && this.debounceTimeout && !this.isInitializing) {
        // Save content before leaving the page
        clearTimeout(this.debounceTimeout);
        this.saveContentNow();
      }
    },
    
    // Improved debounce function with a longer timeout
    debouncedSave() {
      // Clear previous timeout if it exists
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      
      // Check if we've saved recently - if so, wait longer before saving again
      const timeSinceLastSave = Date.now() - this.lastSaveTime;
      if (timeSinceLastSave < this.minSaveInterval) {
        // Only show saving status if we're actually going to save soon
        this.saveStatus = 'saving';
        
        // Set a timeout that will save after the minimum interval has passed
        this.debounceTimeout = setTimeout(() => {
          this.saveContent();
        }, this.minSaveInterval - timeSinceLastSave + 1000); // Add 1 second buffer
      } else {
        // Normal debounce behavior (3 seconds)
        this.saveStatus = 'saving';
        this.debounceTimeout = setTimeout(() => {
          this.saveContent();
        }, 3000); // 3 second debounce
      }
    },
    
    // Force immediate save
    saveContentNow() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = null;
      }
      this.saveContent();
    },
    
    // Save content if it has actually changed
    saveContent() {
      // Don't save during initialization
      if (this.isInitializing) {
        this.saveStatus = null;
        return;
      }
      
      const currentContent = this.editor.getJSON();
      
      // Check if content is empty
      const isEmpty = !this.editor.state.doc.textContent.trim().length;
      
      // Check if content has significantly changed
      const hasChanged = isEmpty || this.hasContentChanged(currentContent, this.lastContent);
      
      if (hasChanged) {
        // Update last save time
        this.lastSaveTime = Date.now();
        
        // Update stored content
        this.lastContent = currentContent;
        
        // Emit the content update
        if (isEmpty) {
          this.$emit("update:content", null);
        } else {
          this.$emit("update:content", currentContent);
        }
        
        // Reset the content changed flag
        this.contentChanged = false;
        
        // Show saved status
        this.saveStatus = 'saved';
        
        // Reset save status after 2 seconds
        setTimeout(() => {
          this.saveStatus = null;
        }, 2000);
      } else {
        // Content hasn't actually changed enough to save
        this.saveStatus = null;
      }
    },
    
    // Helper method to compare content and determine if it's changed enough to save
    hasContentChanged(newContent, oldContent) {
      if (!oldContent) return true;
      
      // Simple string comparison of the JSON structures
      // We could implement more sophisticated comparison as needed
      return JSON.stringify(newContent) !== JSON.stringify(oldContent);
    },
    
    handleClickOutside(event) {
      const tiptapContainer = this.$refs.tiptapContainer;
      if (tiptapContainer && !tiptapContainer.contains(event.target)) {
        // If there's a pending save, save immediately before closing
        // But only if we're not in initialization phase
        if (this.contentChanged && !this.isInitializing) {
          this.saveContentNow();
        }
        this.isExpanded = false;
      }
    },
    
    triggerColorPicker() {
      this.$refs.colorPicker.click();
    },
    
    setColor(event) {
      this.editor.chain().focus().setColor(event.target.value).run();
    },
    
    triggerHighlightPicker() {
      this.$refs.highlightPicker.click();
    },
    
    setHighlightColor(event) {
      const color = event.target.value;
      this.editor.chain().focus().toggleHighlight({ color }).run();
    },
  },

  // Add a watch for editable prop changes
  watch: {
    editable(newValue) {
      // If the editor becomes non-editable, save any pending changes
      if (!newValue && this.contentChanged) {
        this.saveContentNow();
      }
      
      // Update the editor's editable state
      if (this.editor) {
        this.editor.setEditable(newValue);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tiptap-container {
  position: relative;
  width: 100%;
  height: 10vh;
  background-color: var(--accent3);
  color: var(--primary);
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  font-family: var(--main-font);
  transition: all ease 0.7s;
  overflow: auto;
  font-size: 1vw;
}

.tiptap-container.expanded {
  height: 53vh;
}

.sticky-toolbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.control-group {
  padding: 0.5rem;
  border: none;
  border-radius: 1rem;
  background-color: var(--accent3);
}

.button-group {
  margin: 0 1rem;
  display: flex;
}

.control-group button {
  cursor: pointer;
  color: var(--primary);
  background-color: var(--accent3);
  border: none;
  border-radius: 1rem;
  margin-right: 1rem;
  padding: 0.5rem;
}

.control-group button:hover {
  background-color: var(--primary);
  color: var(--secondary);
  transition: all ease 0.3s;
}

.editor {
  height: fit-content;
  cursor: text;
  padding: 2vh 2vw;
  line-height: 1.5rem;
  transition: all 0.5s ease;
}

.editorExpanded {
  height: 45vh;
}

.save-status {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 0.9vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.saving {
  color: var(--primary);
  opacity: 0.8;
}

.saved {
  color: green;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.saved .material-symbols-outlined {
  font-size: 1vw;
}

.dot-animation::after {
  content: '';
  animation: dots 1.5s infinite;
}

@keyframes dots {
  0%, 20% { content: ''; }
  40% { content: '.'; }
  60% { content: '..'; }
  80%, 100% { content: '...'; }
}

.hidden-color-picker {
  position: relative;
  width: 0px;
  right: 2.2vw;
  opacity: 0;
  pointer-events: none;
  margin: none;
}

.hidden-highlight-picker {
  position: relative;
  right: 2.2vw;
  width: 0px;
  opacity: 0;
  pointer-events: none;
  padding: none;
}

.tiptap {
  outline: none;

  :first-child {
    margin-top: 0;
  }

  strong {
    font-weight: 700;
  }

  ul,
  ol {
    padding: 0 3rem;
  }

  h1,
  h2,
  h3 {
    text-wrap: pretty;
    margin-bottom: 0.3vh;
  }

  h1 {
    font-size: 1.5vw;
  }

  h2 {
    font-size: 1.3vw;
  }

  h3 {
    font-size: 1.1vw;
  }

  code {
    border-left: 0.2rem solid var(--primary);
    font-size: 0.8rem;
    padding: 0 0 0 1rem;
    margin: 0;
  }

  pre {
    border-radius: 0.5rem;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      border-left: 0.2rem solid var(--primary);
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--primary);
  }

  p.is-editor-empty:first-child::before {
    color: var(--gray-4);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
</style>
