<template>
  <nav :class="['sidebar', { collapsed: isCollapsed }]">
    <div class="top" @click="toggleSidebar">
      <span class="material-symbols-outlined hamburger"> menu </span>
      <router-link to="/student/dashboard" class="logo" v-if="!isCollapsed">
        <div style="flex: 1; display: flex; justify-content: left;">
          <router-link to="/" class="logo">
            <span class="classi1">c</span>
            <span class="classi1">l</span>
            <span class="classi2">a</span>
            <span class="classi1">s</span>
            <span class="classi1">s</span>
            <span class="classi2">i</span>
            <span class="beta-badge">BETA</span>
          </router-link>
        </div>
      </router-link>
    </div>
    <div v-if="!isCollapsed" class="nav-links">
      <router-link
        v-for="(item, index) in menuItems"
        :key="index"
        :to="item.route"
        class="nav-link"
        active-class="active-link"
      >
        <span v-if="!isCollapsed">{{ item.title }}</span>
      </router-link>
    </div>
    <div v-if="!isCollapsed" style="width:100%; display: flex; justify-content: space-between; align-items: center">
      <div class="themeDiv">
        <ThemeSwitcher @themeChange="handleThemeChange" />
      </div>
    </div>
  </nav>
</template>

<script>
import ThemeSwitcher from "./ThemeSwitcher.vue";
import { useStudentStore } from "@/store/studentData";

export default {
  data() {
    return {
      userAvatar: require("@/assets/JohnSnow.jpg"),
      menuItems: [
        { title: "Dashboard", route: "/student/dashboard" },
        { title: "Assignments", route: "/student/assignments" },
        { title: "Classes", route: "/student/classes" },
        { title: "Calendar", route: "/student/calendar" },
        { title: "Grades", route: "/student/grades" },
        { title: "AURA", route: "/student/chat" },
      ],
    };
  },
  components: {
    ThemeSwitcher,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.studentStore = useStudentStore();
  },
  methods: {
    toggleSidebar() {
      this.$emit("toggle", this.isCollapsed);
    },
    handleThemeChange(currentTheme) {
      this.$emit("themeChange", currentTheme);
    },
    async logout() {
      await this.studentStore.logout();
      this.$router.push("/login");
    },
  },
  computed: {
    isCollapsed() {
      return this.collapsed;
    },
  },
};
</script>

<style scoped>
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  height: 100%;
  width: 18vw;
  box-shadow: var(--box-shadow);
  font-family: var(--accent-font);
  transition: width 0.3s;
  z-index: 1000;
}

.sidebar.collapsed {
  width: 6vw;
}

.top {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.hamburger {
  margin: 2vh auto;
  font-size: 1.5vw;
  cursor: pointer;
  color: var(--primary);
  transform: rotate(0deg);
  transition: all ease 0.3s;
}

.hamburger:hover {
  color: var(--classi2);
  transform: rotate(360deg);
  transition: all ease 0.3s;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 2.5vw;
  font-weight: 100;
  letter-spacing: 0.2vw;
  font-family: var(--accent-font);
  text-decoration: none;
  margin-right: 1vw;
}

.classi1 {
  color: var(--classi1);
  text-transform: none;
}

.classi2 {
  color: var(--classi2);
  text-transform: none;
}

.classi1:hover,
.classi2:hover {
  transform: translateY(-5%);
}

.beta-badge {
  color: var(--secondary);
  background-color: darkslategrey;
  text-align: center;
  padding: 0.4vw;
  margin-left: 0.3vw;
  border-radius: 0.5vw;
  font-size: 0.8vw;
}

.nav-links {
  flex: 9;
}

.nav-link {
  display: block;
  text-decoration: none;
  color: var(--primary);
  font-size: 1.1vw;
  transition: background-color 0.3s ease;
  padding: 4vh 3vw 4vh 6vw;
}

.nav-link:hover {
  background-color: var(--classi2);
  color: var(--secondary);
}

.active-link,
.exact-active-link {
  background-color: var(--primary);
  color: var(--secondary);
}

.themeDiv {
  flex: 1;
  justify-content: left;
  align-items: flex-end;
  padding: 2rem;
  display: flex;
}

.logout-button {
  font-size: 1.5vw;
  margin-right: 1vw;
  padding: 0.6vw;
  border-radius: 5rem;  
  cursor: pointer;
}

.logout-button:hover {
  color: var(--classi2);
}
</style>
