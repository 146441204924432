<template>
    <div :class="['main-container', { collapsed: isCollapsed }]">
        <Sidebar @themeChange="handleThemeChange" @toggle="handleToggle" :collapsed="isCollapsed" />
        <div :class="['content-container', { collapsed: isCollapsed }]">
            <Transition name="slide-in">
                <div>
                    <StudentHeader v-if="typingComplete" :title="`Hello, ${studentStore.firstName}`" />
                    <LoadingSpinner class="spinner" v-if="loading && typingComplete" />
                    <div v-if="renderContent && !loading && isMainContent" class="dashboard-content">
                        <!-- Main Column -->
                        <div class="main-column">
                            <div class="completed-assignments">
                                <CompletedAssignmentsChart class="donut-chart" :assignmentsData="chartData"
                                    :pieBorderColor="pieBorderColor" />
                            </div>

                            <!-- Word of the Day -->
                            <div class="wotd">
                                <div class="section-label">
                                    <i class="fas fa-book section-icon"></i>
                                    Word of the Day
                                </div>
                                <div class="wotd-content">
                                    <div class="wotd-word">{{ wordOfTheDay.word }}</div>
                                    <div class="wotd-info">[{{ wordOfTheDay.pronunciation }}] | <i>{{
                                        wordOfTheDay.word_type }}</i></div>
                                    <div class="wotd-meaning">{{ wordOfTheDay.meaning }}</div>
                                    <div class="wotd-example">"{{ wordOfTheDay.example_sentence }}"</div>
                                </div>
                            </div>

                            <!-- Next Deadline -->
                            <div class="next-deadline" v-if="upcomingDeadlines.length > 0"
                                @click="goToAssignment(upcomingDeadlines[0])">
                                <div class="section-label">
                                    <i class="fas fa-calendar-alt section-icon"></i>
                                    Next Deadline
                                </div>
                                <div class="next-deadline-content">
                                    <div class="deadline-date">
                                        <div class="date-box">
                                            <div class="month">{{ formatMonth(upcomingDeadlines[0].due_date) }}</div>
                                            <div class="day">{{ formatDay(upcomingDeadlines[0].due_date) }}</div>
                                        </div>
                                    </div>
                                    <div class="deadline-details">
                                        <div class="deadline-title">{{ upcomingDeadlines[0].title }}</div>
                                        <div class="deadline-time">{{ getTimeLeft(upcomingDeadlines[0].due_date) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Side Column - Notices only -->
                        <div class="side-column">
                            <!-- Notice Board -->
                            <div class="notice-board">
                                <div class="section-label">
                                    <i class="fas fa-bullhorn section-icon"></i>
                                    Notices
                                </div>
                                <div class="notices-list">
                                    <div v-for="notice in notices" :key="notice.id" class="notice-item">
                                        <div class="notice-header">
                                            <div class="notice-title"> <span class="material-symbols-outlined"
                                                    style="font-size: medium; padding-right: 0.5rem;">
                                                    keep
                                                </span>{{ notice.title }}</div>
                                            <div class="notice-date">{{ formatNoticeDate(notice.date) }}</div>
                                        </div>
                                        <div class="notice-description">{{ notice.description }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
            <Transition name="slide-out">
                <div v-if="!typingComplete" class="welcome-message">
                    <Typewriter :text="`Hello, ${studentStore.firstName}`" />
                    <div class="welcome-line">
                        <Typewriter text="Welcome to " :offset="2000" />
                        <Typewriter @typing-complete="showDashboard" text="" :classi="true" :offset="3500" />
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import { useStudentStore } from "@/store/studentData";
import Typewriter from "@/components/Typewriter.vue";
import CompletedAssignmentsChart from "@/components/CompletedAssignmentsChart.vue";
import axiosInstance from "@/utils/axiosInstance";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useAssignmentStore } from "@/store/assignmentData";

export default {
    name: "StudentDashboard",
    components: {
        StudentHeader,
        Sidebar,
        LoadingSpinner,
        Typewriter,
        CompletedAssignmentsChart,
    },
    data() {
        return {
            loading: true,
            isCollapsed: true,
            typingComplete: false,
            renderContent: false,
            isMainContent: true,
            chartData: [],
            wordOfTheDay: {},
            theme: localStorage.getItem("theme"),
            notices: [
                {
                    id: 1,
                    title: "School Assembly",
                    description:
                        "There will be a school assembly on Monday at 8:00 AM in the main hall.",
                    date: "2024-08-10",
                },
                {
                    id: 2,
                    title: "Parent-Teacher Conference",
                    description:
                        "Parent-Teacher conferences will be held next Friday from 3:00 PM to 6:00 PM.",
                    date: "2024-08-15",
                },
                {
                    id: 3,
                    title: "Science Fair",
                    description:
                        "The annual science fair will take place on September 1st. All students are encouraged to participate.",
                    date: "2024-09-01",
                },
                {
                    id: 4,
                    title: "Debate Competition",
                    description:
                        "Students interested in participating, please contact Ms. Shah for details.",
                    date: "2024-09-01",
                },
            ]
        };
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
        assignmentStore() {
            return useAssignmentStore();
        },
        assignments() {
            return this.assignmentStore.assignments;
        },
        pieBorderColor() {
            return this.theme === "dark" ? "black" : "white";
        },
        upcomingDeadlines() {
            const now = new Date();
            const twoWeeksFromNow = new Date();
            twoWeeksFromNow.setDate(now.getDate() + 14);

            return Object.values(this.assignments)
                .filter(assignment => {
                    const dueDate = new Date(assignment.due_date);
                    return dueDate >= now && dueDate <= twoWeeksFromNow &&
                        (assignment.status === "Incomplete" || assignment.status === "In Progress");
                })
                .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
                .slice(0, 3);
        }
    },
    methods: {
        handleToggle(collapsed) {
            this.isCollapsed = !collapsed;
        },
        handleThemeChange(currentTheme) {
            this.theme = currentTheme;
        },
        async showDashboard() {
            this.typingComplete = true;
            await this.delay(200);
            this.isCollapsed = false;
            await this.delay(200);
            this.renderContent = true;
        },
        delay(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        handleAssignmentsClick(status) {
            this.$router.push({
                name: "StudentAssignments",
                query: { selectedFilters: status },
            });
        },
        async getWordOfTheDay() {
            try {
                const wordResponse = await axiosInstance.get(
                    "/students/get-word-of-the-day"
                );
                this.wordOfTheDay = wordResponse.data;
            } catch (error) {
                console.log("Word not found", error);
            }
        },
        getAssignmentsStatus() {
            var graded = 0;
            var submitted = 0;
            var inprogress = 0;
            var incomplete = 0;

            Object.values(this.assignments).forEach((item) => {
                switch (item.status) {
                    case "Graded":
                        graded += 1;
                        break;
                    case "Submitted":
                        submitted += 1;
                        break;
                    case "In Progress":
                        inprogress += 1;
                        break;
                    case "Incomplete":
                        incomplete += 1;
                        break;
                    default:
                        break;
                }
            });

            this.chartData = [
                {
                    label: "Graded",
                    color: "rgb(115, 179, 115)",
                    value: graded,
                    total: graded + submitted + inprogress + incomplete,
                    onClick: () => this.handleAssignmentsClick("graded"),
                },
                {
                    label: "Submitted",
                    color: "rgb(118, 161, 187)",
                    value: submitted,
                    total: graded + submitted + inprogress + incomplete,
                    onClick: () => this.handleAssignmentsClick("submitted"),
                },
                {
                    label: "In Progress",
                    color: "rgb(205, 205, 131)",
                    value: inprogress,
                    total: graded + submitted + inprogress + incomplete,
                    onClick: () => this.handleAssignmentsClick("inProgress"),
                },
                {
                    label: "Incomplete",
                    color: "rgb(203, 114, 114)",
                    value: incomplete,
                    total: graded + submitted + inprogress + incomplete,
                    onClick: () => this.handleAssignmentsClick("incomplete"),
                },
            ];
        },
        navigateTo(routeName) {
            this.$router.push({ name: routeName });
        },
        goToAssignment(assignment) {
            this.$router.push({
                name: "StudentAssignment",
                params: { assignmentId: assignment.assignment_id },
            });
        },
        formatMonth(dateString) {
            const date = new Date(dateString);
            return date.toLocaleString('default', { month: 'short' }).toUpperCase();
        },
        formatDay(dateString) {
            const date = new Date(dateString);
            return date.getDate();
        },
        formatNoticeDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('default', { month: 'short', day: 'numeric' });
        },
        getTimeLeft(dateString) {
            const dueDate = new Date(dateString);
            const now = new Date();
            const diffTime = dueDate - now;
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays === 0) {
                const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                return diffHours === 0 ? 'Less than an hour' : `${diffHours} hour(s)`;
            } else if (diffDays === 1) {
                return 'Tomorrow';
            } else {
                return `${diffDays} days`;
            }
        }
    },
    async mounted() {
        const welcomed = localStorage.getItem("welcomed");

        if (!welcomed) {
            localStorage.setItem("welcomed", true);
        } else {
            this.typingComplete = true;
            this.isCollapsed = false;
            this.renderContent = true;
        }

        await this.getWordOfTheDay();
        if (!sessionStorage.getItem("assignmentData")) {
            await this.assignmentStore.getAssignments();
        }

        this.getAssignmentsStatus();
        this.loading = false;
    },
};
</script>

<style scoped>
.main-container {
    display: flex;
    transition: all 0.3s ease-in-out;
    font-family: var(--main-font);
    margin: 2vh 2vw;
    height: 96vh;
}

.main-container.collapsed .content-container {
    margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
    margin-left: 18vw;
}

.content-container {
    flex: 1;
    transition: margin-left 0.3s ease-in-out;
    color: var(--primary);
}

.spinner {
    margin: 20vh auto;
}

.welcome-message {
    margin-top: 30vh;
    margin-left: 10vw;
}

.welcome-line {
    display: flex;
}

.slide-out-leave-active,
.slide-in-enter-active {
    transition: all ease-out 0.5s;
}

.slide-in-enter-from,
.slide-out-leave-to {
    transform: translateY(-30vh);
    opacity: 0;
}

/* Dashboard Layout */
.dashboard-content {
    display: flex;
    gap: 3rem;
    height: calc(100vh - 9rem);
    padding: 2rem 2rem 0 2rem;
    margin: 0 auto;
}

.main-column {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.side-column {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-y: auto;
    padding-right: 1rem;
}

.section-label {
    font-size: 0.9rem;
    color: var(--accent4);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.section-icon {
    color: var(--classi2);
    margin-right: 0.5rem;
    font-size: 1rem;
}

/* Chart */
.completed-assignments {
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
    background-color: white;
    border-radius: 12px;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); */
    /* border-top: 4px solid var(--classi2); */
    padding: 1.5rem;
}

.donut-chart {
    height: 100%;
    max-width: 25vw;
}

/* Next Deadline */
.next-deadline {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    border-left: 4px solid #e67e22;
}

.next-deadline:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}

.next-deadline-content {
    display: flex;
    align-items: center;
}

.deadline-date {
    margin-right: 1.5rem;
}

.date-box {
    background-color: #e67e22;
    color: white;
    border-radius: 8px;
    padding: 0.5rem;
    width: 4rem;
    text-align: center;
}

.month {
    font-size: 0.8rem;
    font-weight: 500;
}

.day {
    font-size: 1.8rem;
    font-weight: 300;
}

.deadline-details {
    flex: 1;
}

.deadline-title {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.deadline-time {
    color: #e67e22;
    font-size: 0.9rem;
}

/* Word of the Day */
.wotd {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    border-left: 4px solid var(--classi2);
}

.wotd-content {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.wotd-word {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--classi2);
    margin-bottom: 0.5rem;
}

.wotd-info {
    font-size: 0.9rem;
    color: var(--accent4);
    margin-bottom: 1rem;
}

.wotd-meaning {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
}

.wotd-example {
    font-size: 0.9rem;
    color: var(--accent4);
    font-style: italic;
    line-height: 1.4;
}

/* Notice Board */
.notice-board {
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--classi2);
    border-top: 4px solid var(--classi2);
}

.notices-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    overflow-y: auto;
}

.notice-item {
    padding: 1rem;
    border-radius: 8px;
    background-color: rgba(0, 139, 139, 0.1);
    margin-bottom: 0.5rem;
}

.notice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.notice-date {
    font-size: 0.8rem;
    color: var(--classi2);
    font-weight: 500;
}

.notice-title {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: #333;
}

.notice-description {
    font-size: 0.9rem;
    color: var(--accent4);
    line-height: 1.5;
}
</style>
