import axios from "axios";
import { getTenant } from "./tenantStore";

const axiosInstance = axios.create({    
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true,
});

axiosInstance.interceptors.request.use(
    (config) => {
        // Add CSRF token
        const csrfToken = document.cookie
            .split(";")
            .find((item) => item.trim().startsWith("csrf_access_token="));
        if (csrfToken) {
            config.headers["X-CSRF-TOKEN"] = csrfToken.split("=")[1];
        }
        
        // Add tenant header from centralized store
        const tenant = getTenant();
        if (tenant) {
            config.headers["X-Tenant"] = tenant;
        }
        
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add response interceptor to handle auth errors globally
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        // Handle 401 Unauthorized errors
        if (error.response && (error.response.status === 401 || error.response.status === 422)) {
            console.warn('Authentication error, redirecting to login page');
            
            // Clear any stored auth data
            localStorage.removeItem("studentData");
            localStorage.removeItem("instructorData");
            sessionStorage.removeItem("welcomed");
            
            // Only redirect if we're not already on the login page
            const currentPath = window.location.pathname;
            if (!currentPath.includes('/login')) {
                // Use timeout to avoid interrupting current error handling
                setTimeout(() => {
                    window.location.href = '/login';
                }, 100);
            }
        }
        
        return Promise.reject(error);
    }
);

export default axiosInstance;
