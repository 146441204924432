<template>
  <div class="sliding-button">
    <div class="options">
      <div class="option" :class="{ active: assignmentType === 'document' }" @click="$emit('select-option', 'document')">
        Create from Document
      </div>
      <div class="option" :class="{ active: assignmentType === 'scratch' }" @click="$emit('select-option', 'scratch')">
        Create from Scratch
      </div>
      <div class="option" :class="{ active: assignmentType === 'ai' }" @click="$emit('select-option', 'ai')">
        Create with AI
      </div>
    </div>
    <div class="slider" :style="sliderStyle"></div>
  </div>
</template>

<script>
export default {
  name: 'AssignmentTypeSelector',
  props: {
    assignmentType: {
      type: String,
      required: true
    }
  },
  computed: {
    sliderStyle() {
      const positions = {
        'document': "translateX(0%)",
        'scratch': "translateX(100%)",
        'ai': "translateX(200%)"
      };
      
      return {
        transform: positions[this.assignmentType] || "translateX(0%)",
        width: "33.33%",
      };
    }
  }
}
</script>

<style scoped>
.sliding-button {
  position: relative;
  width: 70%;
  height: 5vh;
  margin: 2vh auto;
  background-color: var(--background-color);
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.options {
  display: flex;
  height: 5vh;
}

.option {
  flex: 1;
  display: flex;
  height: 5vh;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  font-size: 1vw;
  transition: color 0.3s;
  z-index: 2;
}

.option.active {
  color: #fff;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: var(--classi2);
  border-radius: 2rem;
  transition: transform 0.3s;
  z-index: 1;
}
</style> 