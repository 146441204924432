<template>
    <div class="student-grades">
        <Sidebar @toggle="handleToggle" :collapsed="isCollapsed" />
        
        <div class="content-area" :class="{ 'sidebar-collapsed': isCollapsed }">
            <StudentHeader title="Grades" />
            
            <div v-if="loading" class="loading-state">
                <LoadingSpinner />
            </div>
            
            <div v-else class="grades-dashboard">
                <!-- Top Stats Row -->
                <div class="stats-row">
                    <div class="overall-card">
                        <div class="stat-value">{{ overallAverage }}%</div>
                        <div class="stat-label">Overall Average</div>
                    </div>
                    
                    <div class="course-selector" v-if="!selectedCourse">
                        <div 
                            class="selector-item"
                            :class="{ active: !selectedCourse }"
                            @click="selectedCourse = ''"
                        >
                            All
                        </div>
                        <div 
                            v-for="course in uniqueCourses" 
                            :key="course"
                            class="selector-item"
                            :class="{ active: selectedCourse === course }"
                            :style="selectedCourse === course ? { borderBottomColor: colors[course] || '#888' } : {}"
                            @click="selectCourse(course)"
                        >
                            {{ course }}
                        </div>
                    </div>
                    
                    <div v-if="selectedCourse" class="back-link" @click="selectedCourse = ''">
                        <span class="material-symbols-outlined">arrow_back</span>
                        Back to All Courses
                    </div>
                </div>
                
                <!-- Selected Course Card (shows when a course is selected) -->
                <div 
                    v-if="selectedCourse" 
                    class="selected-course-card"
                    :style="{ backgroundColor: colors[selectedCourse] }"
                >
                    <div class="card-content">
                        <div style="width: 100%; display: flex; justify-content: space-between;">
                            <h2 class="course-title">{{ selectedCourse }}</h2>
                            <div class="grade-circle">
                                <span>{{ averages[selectedCourse] }}%</span>
                            </div>
                        </div>
                        <div class="card-stats">
                            <div class="stat-item">
                                <div class="metric">{{ coursesAssignments[selectedCourse]?.length || 0 }}</div>
                                <div class="metric-label">Total</div>
                            </div>
                            <div class="stat-item">
                                <div class="metric">{{ getHighestGrade(selectedCourse) }}%</div>
                                <div class="metric-label">Highest</div>
                            </div>
                            <div class="stat-item">
                                <div class="metric">{{ getLowestGrade(selectedCourse) }}%</div>
                                <div class="metric-label">Lowest</div>
                            </div>                            
                        </div>
                    </div>                                
                </div>
                
                <!-- Main Content Area with Flexbox -->
                <div class="main-content-flex">
                    <!-- Course Cards Column (Left Side) - Only shown when no course is selected -->
                    <div class="courses-column" v-if="!selectedCourse">            
                        <div class="course-cards">
                            <div 
                                v-for="course in uniqueCourses" 
                                :key="course"
                                class="course-card"
                                :style="{ backgroundColor: colors[course] }"
                                @click="selectCourse(course)"
                            >
                                <div class="card-content">
                                    <div style="width: 100%; display: flex; justify-content: space-between;">
                                    {{ course }}
                                    <div class="grade-circle">
                                        <span>{{ averages[course] }}%</span>
                                    </div>
                                    </div>
                                    <div class="card-stats">
                                        <div class="stat-item">
                                            <div class="metric">{{ coursesAssignments[course]?.length || 0 }}</div>
                                            <div class="metric-label">Total</div>
                                        </div>
                                        <div class="stat-item">
                                            <div class="metric">{{ getHighestGrade(course) }}%</div>
                                            <div class="metric-label">Highest</div>
                                        </div>
                                        <div class="stat-item">
                                            <div class="metric">{{ getLowestGrade(course) }}%</div>
                                            <div class="metric-label">Lowest</div>
                                        </div>                                        
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    
                    <!-- Right Column (Chart and Table) -->
                    <div class="data-column">
                        <!-- Chart Area -->
                        <div class="chart-area">
                            <h2 v-if="!selectedCourse">Performance Overview</h2>
                            <h2 v-else>{{ selectedCourse }} Performance</h2>
                            
                            <div class="chart-container">
                                <GradesLineChart
                                    v-if="!selectedCourse"
                                    :chartData="chartData"
                                    :assignments="gradedAssignments"
                                    @pointClicked="handlePointClick"
                                />
                                <GradesBarChart
                                    v-else
                                    :chartData="selectedCourseChartData"
                                    :assignments="coursesAssignments[selectedCourse]"
                                    @barClicked="handleBarChartClick"
                                />
                            </div>
                        </div>
                        
                        <!-- Subject Strengths Radar -->
                        <div v-if="!selectedCourse" class="chart-area">                            
                            <h2>Compared to Class Average</h2>
                            <div class="chart-container">
                                <SubjectStrengthsRadar
                                    :assignments="gradedAssignments"
                                    :classAverages="classAverages"
                                />
                            </div>
                        </div>
                        
                        <!-- Grades Table - Only shown when a course is selected -->
                        <div class="grades-table" v-if="selectedCourse">
                            <div class="table-header">
                                <h2>{{ selectedCourse }} Assignments</h2>
                            </div>

                            <table v-if="filteredAssignments.length > 0">
                                <thead>
                                    <tr>
                                        <th class="assignment-col">Assignment</th>
                                        <th class="date-col">Due Date</th>
                                        <th class="score-col">Score</th>
                                        <th class="grade-col">Grade</th>
                                        <th class="action-col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr 
                                        v-for="assignment in filteredAssignments" 
                                        :key="assignment.assignment_id"
                                        @click="goToAssignment(assignment.assignment_id)"
                                    >
                                        <td class="assignment-col">{{ assignment.title }}</td>
                                        <td class="date-col">{{ formatDate(assignment.due_date) }}</td>
                                        <td class="score-col">
                                            {{ parseFloat(assignment.marks_obtained).toFixed(1) }} / {{ assignment.marks_total.toFixed(1) }}
                                        </td>
                                        <td class="grade-col">
                                            <div class="grade-pill" :style="{ backgroundColor: getBadgeColor(assignment) }">
                                                {{ getPercentage(assignment) }}%
                                            </div>
                                        </td>
                                        <td class="action-col">
                                            <span class="material-symbols-outlined">chevron_right</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <div v-else class="empty-state">
                                <span class="material-symbols-outlined">assignment_late</span>
                                <p>No assignments found</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import GradesLineChart from "@/components/GradesLineChart.vue";
import GradesBarChart from "@/components/GradesBarChart.vue";
import axiosInstance from "@/utils/axiosInstance";
import { useStudentStore } from "@/store/studentData";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import SubjectStrengthsRadar from "@/components/SubjectStrengthsRadar.vue";

export default {
    name: "StudentGrades",
    components: {
        StudentHeader,
        Sidebar,
        GradesLineChart,
        GradesBarChart,
        LoadingSpinner,
        SubjectStrengthsRadar,
    },
    data() {
        return {
            isCollapsed: false,
            loading: true,
            gradedAssignments: [],
            percentages: {},
            selectedCourse: "",
            colors: {
                Physics: "#3F51B5",
                History: "#673AB7",
                English: "#009688",
                Science: "#00BCD4",
                Mathematics: "#4CAF50",
            },
            classAverages: {}
        };
    },
    computed: {
        uniqueCourses() {
            return Array.from(new Set(this.gradedAssignments.map(a => a.course)));
        },
        
        coursesAssignments() {
            const result = {};
            this.uniqueCourses.forEach(course => {
                result[course] = this.gradedAssignments.filter(a => a.course === course);
            });
            return result;
        },
        
        filteredAssignments() {
            let assignments = this.gradedAssignments;
            
            if (this.selectedCourse) {
                assignments = assignments.filter(a => a.course === this.selectedCourse);
            }
            
            // Sort by due date (newest first)
            assignments = [...assignments].sort((a, b) => new Date(b.due_date) - new Date(a.due_date));
            
            // If no course is selected, limit to the most recent assignments
            if (!this.selectedCourse) {
                assignments = assignments.slice(0, 10);
            }
            
            return assignments;
        },
        
        chartData() {
            const courses = {};

            this.gradedAssignments.forEach((assignment) => {
                const percentage = this.calculatePercentage(assignment);
                
                if (assignment.course in this.percentages) {
                    this.percentages[assignment.course].push(percentage);
                } else {
                    this.percentages[assignment.course] = [percentage];
                }

                const date = new Date(assignment.due_date);
                const title = assignment.title;
                
                if (!courses[assignment.course]) {
                    courses[assignment.course] = [];
                }
                
                const id = assignment.assignment_id;
                courses[assignment.course].push({
                    id,
                    title,
                    date,
                    percentage,
                });
            });

            // Sort data points by date for each course
            Object.keys(courses).forEach((course) => {
                courses[course].sort((a, b) => a.date - b.date);
            });

            // Create datasets for the chart
            const datasets = Object.keys(courses).map((course) => ({
                label: course,
                data: courses[course].map((dataPoint) => ({
                    id: dataPoint.id,
                    title: dataPoint.title,
                    x: dataPoint.date,
                    y: dataPoint.percentage,
                })),
                fill: false,
                borderColor: this.getColor(course),
                backgroundColor: this.getColorWithOpacity(course, 0.1),
                tension: 0.3,
                borderWidth: 2,
                pointBackgroundColor: this.getColor(course),
                pointBorderColor: '#fff',
                pointRadius: 5,
                pointHoverRadius: 7,
            }));

            return {
                datasets,
            };
        },
        
        selectedCourseChartData() {
            if (!this.selectedCourse) return null;
            
            const courseAssignments = this.gradedAssignments
                .filter(a => a.course === this.selectedCourse)
                .sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
            
            if (courseAssignments.length === 0) return null;
            
            const data = courseAssignments.map(assignment => {
                const percentage = this.calculatePercentage(assignment);
                return {
                    id: assignment.assignment_id,
                    title: assignment.title,
                    x: assignment.title,
                    y: percentage,
                }
            });
            
            return {
                datasets: [
                    {
                        label: this.selectedCourse,
                        data: data,
                        backgroundColor: this.getColorWithOpacity(this.selectedCourse, 0.7),
                        borderColor: this.getColor(this.selectedCourse),
                        borderWidth: 1,
                        borderRadius: 6,
                        barThickness: 40,
                        maxBarThickness: 50,
                    }
                ]
            };
        },
        
        averages() {
            const averages = {};
            for (let [course, pcts] of Object.entries(this.percentages)) {
                const getAvgs = (array) => array.reduce((a, b) => a + b) / array.length;
                averages[course] = getAvgs(pcts).toFixed(1);
            }
            return averages;
        },
        
        overallAverage() {
            const allPercentages = Object.values(this.percentages).flat();
            if (allPercentages.length === 0) return "0.0";
            return (allPercentages.reduce((a, b) => a + b, 0) / allPercentages.length).toFixed(1);
        }
    },
    methods: {
        handleToggle(collapsed) {
            this.isCollapsed = !collapsed;
        },
        
        handlePointClick(datasetIndex, pointIndex) {
            const course = this.chartData.datasets[datasetIndex];
            const assignment = course.data[pointIndex];
            this.goToAssignment(assignment.id);
        },
        
        handleBarChartClick(datasetIndex, pointIndex) {
            const dataset = this.selectedCourseChartData.datasets[datasetIndex];
            const assignment = dataset.data[pointIndex];
            this.goToAssignment(assignment.id);
        },
        
        async loadData() {
            this.loading = true;
            await Promise.all([
                this.getAllGrades(),
                this.getClassAverages()
            ]);
            this.loading = false;
        },
        
        async getAllGrades() {
            try {
                const gradesResponse = await axiosInstance.get("/students/get-all-grades");
                this.gradedAssignments = gradesResponse.data;
            } catch (error) {
                console.error("Error fetching grades:", error);
            }
        },
        
        async getClassAverages() {
            try {
                const response = await axiosInstance.get("/students/get-class-averages");
                this.classAverages = response.data;
            } catch (error) {
                console.error("Error fetching class averages:", error);
                // Fallback to default values if API fails
                this.classAverages = {
                    Physics: 78.5,
                    History: 76.2,
                    English: 82.4,
                    Science: 74.8,
                    Mathematics: 71.9,
                };
            }
        },
        
        selectCourse(course) {
            this.selectedCourse = this.selectedCourse === course ? "" : course;
        },
        
        calculatePercentage(assignment) {
            return (parseFloat(assignment.marks_obtained) / assignment.marks_total) * 100;
        },
        
        getPercentage(assignment) {
            return this.calculatePercentage(assignment).toFixed(1);
        },
        
        getBadgeColor(assignment) {
            return this.getGradeColor(this.calculatePercentage(assignment));
        },
        
        getColor(course) {
            return this.colors[course] || '#888';
        },
        
        getColorWithOpacity(course, opacity) {
            const hexColor = this.getColor(course);
            const r = parseInt(hexColor.slice(1, 3), 16);
            const g = parseInt(hexColor.slice(3, 5), 16);
            const b = parseInt(hexColor.slice(5, 7), 16);
            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
        },
        
        getGradeColor(percentage) {
            percentage = parseFloat(percentage);
            if (percentage >= 90) return "#4CAF50"; 
            if (percentage >= 80) return "#8BC34A"; 
            if (percentage >= 70) return "#FFEB3B"; 
            if (percentage >= 60) return "#FF9800"; 
            return "#F44336"; 
        },
        
        getOverallGradeColor() {
            return this.getGradeColor(this.overallAverage);
        },
        
        getHighestGrade(course) {
            const assignments = this.gradedAssignments.filter(a => a.course === course);
            if (assignments.length === 0) return "0.0";
            
            const percentages = assignments.map(a => this.calculatePercentage(a));
            return Math.max(...percentages).toFixed(1);
        },
        
        getLowestGrade(course) {
            const assignments = this.gradedAssignments.filter(a => a.course === course);
            if (assignments.length === 0) return "0.0";
            
            const percentages = assignments.map(a => this.calculatePercentage(a));
            return Math.min(...percentages).toFixed(1);
        },
        
        formatDate(dateString) {
            const date = new Date(dateString);
            const options = { month: 'short', day: 'numeric', year: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        },
        
        goToAssignment(id) {
            this.$router.push({
                name: "StudentAssignment",
                params: { assignmentId: id },
            });
        },
    },
    mounted() {
        this.loadData();
    },
};
</script>

<style scoped>
.student-grades {
    display: flex;
    transition: all 0.3s ease-in-out;
    font-family: var(--main-font);
    color: var(--primary);
    margin: 2vh 2vw;
}

.content-area {
    flex: 1;
    margin-left: 18vw;
    transition: margin-left 0.3s ease; 
    overflow-y: auto;
    height: 100vh;
    box-sizing: border-box;
}

.content-area.sidebar-collapsed {
    margin-left: 5vw;
}

.loading-state {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.grades-dashboard {
    padding: 1rem 0 3rem;
    margin: 0 auto;
}

/* Top Stats Row */
.stats-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 0.1rem solid black;
    transition: all 0.3s ease;
}

.stats-row.selected-course-active {
    padding: 0;
}

.overall-card {
    display: flex;
    flex-direction: column;
}

.stat-value {
    font-size: 2vw;
    line-height: 1;
    color: var(--classi2);
}

.stat-label {
    font-size: 0.9rem;
    opacity: 0.6;
    margin-top: 0.25rem;
}

.back-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s ease;
}

.back-link:hover {
    opacity: 1;
}

/* Selected Course Card */
.selected-course-card {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    color: white;
    box-shadow: var(--box-shadow);
    animation: slideIn 0.3s ease;
    margin-bottom: 1rem;
}

@keyframes slideIn {
    from {
        transform: translateX(-20px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.course-title {
    font-size: 1.2rem;
    margin: 0;
    font-weight: normal;
}

.card-content {
    padding: 1.5rem;
}

.grade-circle {
    width: 5vw;
    height: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
    background-color: rgba(255, 255, 255, 0.15);
}

.grade-circle span {
    font-size: 1.1vw;
}

.card-stats {
    display: flex;
    width: 100%;
    justify-content: space-around;    
}

.stat-item {
    text-align: center;
}

.metric {
    font-size: 1vw;
    font-weight: 500;
}

.metric-label {
    font-size: 0.8rem;
    opacity: 0.8;
    margin-top: 0.2rem;
}

.card-footer {
    padding: 0.8rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.15);
    font-weight: 500;
    font-size: 1.1rem;
}

/* Main Flexbox Layout */
.main-content-flex {
    display: flex;
    gap: 2rem;
}

.column-title {
    font-size: 1.1rem;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 1.5rem;
}

/* Left Column - Course Cards */
.courses-column {
    flex: 1;
}

.course-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.course-card {
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s ease;
    color: white;
    box-shadow: var(--box-shadow);
}

.course-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.card-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grade-circle {
    width: 5vw;
    height: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
    background-color: rgba(255, 255, 255, 0.15);
}

.grade-circle span {
    font-size: 1.1vw;
}

.card-stats {
    display: flex;
    width: 100%;
    justify-content: space-around;    
}

.stat-item {
    text-align: center;
}

.metric {
    font-size: 1vw;
    font-weight: 500;
}

.metric-label {
    font-size: 0.8rem;
    opacity: 0.8;
    margin-top: 0.2rem;
}

.card-footer {
    padding: 0.8rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.15);
    font-weight: 500;
    font-size: 1.1rem;
}

/* Right Column - Data */
.data-column {    
    flex: 3;
    transition: all 0.3s ease;
}

.chart-area h2 {
    font-size: 1.1rem;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 1rem;
}

.chart-container {    
    height: 33vh;
    width: 100%;
}

/* Grades Table */
.grades-table {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 1.5rem;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.table-header h2 {
    font-size: 1.1rem;
    font-weight: normal;
    margin: 0;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th {
    text-align: left;
    padding: 0.75rem;
    font-weight: normal;
    opacity: 0.7;
    font-size: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    cursor: default;
}

td {
    padding: 0.75rem;
    font-size: 0.85rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    transition: all 0.2s ease;
}

tr {
    cursor: pointer;
    transition: all 0.2s ease;
}

tr:hover {
    transform: translateY(-5px);
    box-shadow: var(--box-shadow);
    position: relative;
    z-index: 1;
}

tr:hover td {
    background-color: rgba(255, 255, 255, 0.03);
}

.assignment-col {
    max-width: 30%;
}

.course-col {
    width: 15%;
}

.date-col {
    width: 15%;
}

.score-col {
    width: 15%;
}

.grade-col {
    width: 10%;
}

.action-col {
    width: 5%;
    text-align: right;
}

.course-badge {
    display: inline-block;
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    color: white;
    font-size: 0.8rem;
}

.grade-pill {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 100px;
    color: white;
    font-size: 0.8rem;
    min-width: 3rem;
    text-align: center;
}

.empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    opacity: 0.4;
}

.empty-state .material-symbols-outlined {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.course-selector {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: nowrap;
    overflow-x: auto;
    cursor: pointer;
}

.selector-item:hover {
    color: var(--classi2);
    transition: all 0.2s ease;
}
</style>
