<template>
    <div class="chart-wrapper">
        <Line :data="chartData" :options="chartOptions" />
    </div>
</template>

<script>
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from "chart.js";
import { Line } from "vue-chartjs";
import "chartjs-adapter-date-fns";
import { format } from "date-fns";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
);

export default {
    name: "GradesLineChart",
    components: {
        Line
    },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        assignments: {
            type: Array,
            required: true,
        }
    },
    methods: {
        handlePointClick(event, elements) {
            if (elements.length > 0) {
                const element = elements[0];
                const datasetIndex = element.datasetIndex;
                const pointIndex = element.index;

                this.$emit("pointClicked", datasetIndex, pointIndex);
            }
        },
    },
    computed: {
        primaryColor() {
            const theme = localStorage.getItem("theme");
            if (theme === "dark") {
                return "white";
            } else {
                return "black";
            }
        },
        chartOptions() {
            const minPercentage = Math.min(
                ...this.assignments.map(
                    (assignment) =>
                        (parseFloat(assignment.marks_obtained) /
                            assignment.marks_total) *
                        100
                )
            );
            const maxPercentage = Math.max(
                ...this.assignments.map(
                    (assignment) =>
                        (parseFloat(assignment.marks_obtained) /
                            assignment.marks_total) *
                        100
                )
            );

            const minY = Math.max(0, Math.floor(minPercentage / 10) * 10 - 5);
            const maxY = Math.min(100, Math.ceil(maxPercentage / 10) * 10 + 10);

            return {
                onClick: this.handlePointClick,
                pointRadius: 5,
                hoverBorderWidth: 20,
                animation: {
                    y: {
                        type: "number",
                        easing: "linear",
                        duration: 500,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 20,
                    }
                },
                scales: {
                    x: {
                        type: "time",
                        time: {
                            unit: "month",
                            stepSize: 1,
                            tooltipFormat: "PP",
                        },
                        title: {
                            display: true,
                            text: "Date",
                        },
                        grid: {
                            display: false,
                        },
                        border: {
                            width: 2,
                            color: "#28282B",
                        },
                    },
                    y: {
                        beginAtZero: false,
                        min: minY,
                        max: maxY,
                        title: {
                            display: true,
                            text: "Percentage",
                        },
                        ticks: {
                            callback: (value) => value + "%",
                            color: "darkgrey",
                        },
                        grid: {
                            display: false,
                        },
                        border: {
                            width: 2,
                            color: "#28282B",
                        },
                    },
                },
                plugins: {
                    legend: {
                        position: "bottom",
                        labels: {
                            usePointStyle: true,
                        },
                    },
                    tooltip: {
                        padding: 10,
                        displayColors: false,
                        backgroundColor: "#28282B",
                        cornerRadius: 20,
                        callbacks: {
                            title: (tooltipItems) => {
                                return (
                                    tooltipItems[0].dataset.label +
                                    " [" +
                                    format(tooltipItems[0].parsed.x, "PP") +
                                    "]"
                                );
                            },
                            label: (context) =>
                                `${context.raw.title}: ${context.raw.y.toFixed(
                                    1
                                )}%`,
                        },
                        titleFont: {
                            size: 14,
                        },
                        bodyFont: {
                            size: 14,
                        },
                    },
                    hover: {
                        mode: "nearest",
                        intersect: false,
                    },
                },
            };
        }
    },
};
</script>

<style scoped>
.chart-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
