<template>
  <div :class="['main-container', { collapsed: isCollapsed }]">
    <Sidebar @themeChange="handleThemeChange" @toggle="handleToggle" :collapsed="isCollapsed" />
    <div :class="['content-container', { collapsed: isCollapsed }]">
      <div style="
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      " v-if="loading">
        <LoadingSpinner />
      </div>
      <div v-if="!loading && isMainContent">
        <InstructorHeader pageTitle="Assignments" />
        
        <!-- Action button row -->
        <div class="assignment-actions">
          <div class="new-assignment-button" @click="showNewAssignmentModal = true">
            New Assignment
            <span class="material-symbols-outlined" style="padding-left: 1vh">
              add
            </span>
          </div>
        </div>
        
        <!-- Tabs Navigation -->
        <div class="tabs-container">
          <div 
            :class="['tab', { active: activeTab === 'upcoming' }]" 
            @click="activeTab = 'upcoming'"
          >
            Upcoming
          </div>
          <div 
            :class="['tab', { active: activeTab === 'past' }]" 
            @click="activeTab = 'past'"
          >
            Past
          </div>
        </div>
        
        <!-- Tab Content -->
        <div class="tab-content">
          <TaskList
            v-if="activeTab === 'upcoming'"
            :tasks="upcomingTasks"
            :filters="filters"
            @assignmentActivated="goToAssignment"
          />
          
          <TaskList
            v-if="activeTab === 'past'"
            :tasks="pastTasks"
            :filters="filters"
            @assignmentActivated="goToAssignment"
          />
        </div>

        <!-- New Assignment Modal -->
        <NewAssignmentModal 
          v-if="showNewAssignmentModal" 
          @close="showNewAssignmentModal = false"
          @assignment-created="handleAssignmentCreated"
        />
      </div>
      <router-view v-if="!loading && !isMainContent"></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/InstructorSidebar.vue";
import TaskList from "@/components/TaskList.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import NewAssignmentModal from "@/components/assignment/NewAssignmentModal.vue";
import { useInstructorStore } from "@/store/instructorData";
import axiosInstance from "@/utils/axiosInstance";
import { convertGMTtoLocal } from "@/utils/generalUtils";
import InstructorHeader from "@/components/InstructorHeader.vue";

export default {
  name: "InstructorAssignments",
  components: {
    Sidebar,
    TaskList,
    LoadingSpinner,
    NewAssignmentModal,
    InstructorHeader
  },
  data() {
    return {
      isCollapsed: false,
      loading: false,
      activeTab: "upcoming",
      showNewAssignmentModal: false,
      assignments: {},
      filters: {
        completion: {
          graded: false,
          submitted: false,
          inProgress: false,
          incomplete: false,
        },
        subjects: [],
        dueDate: {
          today: false,
          thisWeek: false,
          thisMonth: false,
        },
      },
    };
  },
  computed: {
    instructorStore() {
      return useInstructorStore();
    },
    isMainContent() {
      return this.$route.path === "/instructor/assignments";
    },
    upcomingTasks() {
      const now = new Date();
      return Object.values(this.assignments).filter(task => {
        const dueDate = task.due || task.due_date;
        return new Date(dueDate) >= now;
      });
    },
    pastTasks() {
      const now = new Date();
      return Object.values(this.assignments).filter(task => {
        const dueDate = task.due || task.due_date;
        return new Date(dueDate) < now;
      });
    }
  },
  methods: {
    handleToggle(collapsed) {
      this.isCollapsed = !collapsed;
    },
    handleThemeChange(currentTheme) {
      this.theme = currentTheme;
    },
    clearFilters() {
      this.filters = {
        completion: {
          graded: false,
          submitted: false,
          inProgress: false,
          incomplete: false,
        },
        subjects: [],
        dueDate: { today: false, thisWeek: false, thisMonth: false },
      };
    },
    goToAssignment(assignment) {
      this.isCollapsed = true;
      this.$router.push({
        name: "InstructorAssignment",
        params: { assignmentId: assignment.assignment_id },
      });
    },
    handleAssignmentCreated(assignmentData) {
      // Store assignment metadata in localStorage for use in the creation flow
      localStorage.setItem('pendingAssignment', JSON.stringify({
        ...assignmentData,
        email: this.instructorStore.email
      }));
      
      // Navigate to the assignment creation page
      this.$router.push({
        name: "AssignmentCreation"
      });
    },
    async getAssignments() {
      this.loading = true;
      try {
        const response = await axiosInstance.post(
          "/grader/previous-assignments",
          {
            email: this.instructorStore.email,
          }
        );

        this.assignments = {};
        for (const assignment of response.data) {
          const createdDate = new Date(assignment.created_at);
          const dueDate = new Date(assignment.due_date);
          
          this.assignments[assignment.assignment_id] = {
            assignment_id: assignment.assignment_id,
            title: assignment.title,
            course: assignment.course || "N/A",
            due: dueDate,
            assigned: createdDate,
            created: createdDate,
            dueFormatted: convertGMTtoLocal(assignment.due_date),
            createdFormatted: convertGMTtoLocal(assignment.created_at),
            submissions: assignment.submissions,
            average: (assignment.average || 0).toFixed(2),
            status: "Graded" // Default status, can be updated as needed
          };
        }
      } catch (error) {
        console.error("Error fetching assignments:", error);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.getAssignments();
    // Add route change listener
    this.$router.afterEach((to, from) => {
      // If we're returning to the main view
      if (to.path === "/instructor/assignments") {
        this.getAssignments();
        this.isCollapsed = false;
      }
    });
  }
};
</script>

<style scoped>
.main-container {
  display: flex;
  transition: all 0.3s ease-in-out;
  font-family: var(--main-font);
  margin: 2vh 2vw;
}

.main-container.collapsed .content-container {
  margin-left: 6vw;
}

.main-container:not(.collapsed) .content-container {
  margin-left: 18vw;
}

.content-container {
  flex: 1;
  transition: margin-left 0.3s ease;
  color: var(--primary);
}

.spinner {
  margin: 20vh auto;
}

.navigation-info {
  font-size: 0.9vw;
  color: var(--accent4);
  margin-bottom: 0.8vh;
}

.welcome-message {
  font-size: 1.5vw;
  font-weight: 600;
  margin: 0;
}

.assignment-actions {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.new-assignment-button {
  display: flex;
  align-items: center;
  background-color: var(--classi2);
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 1rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.new-assignment-button:hover {
  background-color: var(--classi1);
}

.tabs-container {
  display: flex;
  margin: 3vh 0 2vh 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tab {
  padding: 1vh 2vw;
  font-size: 1.1vw;
  cursor: pointer;
  position: relative;
  color: var(--accent4);
  transition: all 0.2s ease;
}

.tab.active {
  color: var(--classi2); 
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--classi2);
}

.tab:hover:not(.active) {
  color: var(--primary);
}

.tab-content {
  min-height: 50vh;
}
</style>
