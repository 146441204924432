<template>
  <div>
    <div class="modal-overlay" @click="closeModal"></div>
    <div class="modal-container">
      <div class="modal-header">
        <h2>New Assignment</h2>
        <div class="close-button" @click="closeModal">
          <span class="material-symbols-outlined">close</span>
        </div>
      </div>
      
      <div class="modal-content">
        <div class="form-group">
          <label for="title">Assignment Title*</label>
          <input 
            id="title" 
            v-model="title" 
            type="text" 
            class="form-input"
            placeholder="Enter assignment title"
            required
          />
        </div>
        
        <div class="form-group">
          <label for="course">Course*</label>
          <div class="select-wrapper">
            <select 
              id="course" 
              v-model="course" 
              class="form-input"
              required
            >
              <option value="" disabled>Select a course</option>
              <option v-for="course in courses" :key="course.id" :value="course.id">
                {{ course.name }}
              </option>
            </select>
            <span class="material-symbols-outlined select-icon">expand_more</span>
          </div>
        </div>
        
        <div class="form-group">
          <label for="description">Description (optional)</label>
          <textarea 
            id="description" 
            v-model="description" 
            class="form-input form-textarea"
            placeholder="Enter assignment description"
          ></textarea>
        </div>
        
        <div class="form-group date-time-group">
          <label for="dueDate">Due Date*</label>
          <div class="date-time-inputs">
            <div class="date-input-wrapper">
              <span class="material-symbols-outlined date-icon">calendar_today</span>
              <input 
                id="dueDate" 
                v-model="dueDate" 
                type="date" 
                class="form-input date-input"
                required
              />
            </div>
            <div class="time-input-wrapper">
              <span class="material-symbols-outlined time-icon">schedule</span>
              <input 
                id="dueTime" 
                v-model="dueTime" 
                type="time" 
                class="form-input time-input"
                required
              />
            </div>
          </div>
        </div>
        
        <div class="form-group">
          <label>Make Assignment Live*</label>
          <div class="radio-group">
            <label class="radio-label">
              <span class="custom-radio">
                <input 
                  type="radio" 
                  v-model="makeAssignmentLive" 
                  :value="'immediately'"
                  name="assignmentLive"
                />
                <span class="radio-checkmark"></span>
              </span>
              Immediately
            </label>
            <label class="radio-label">
              <span class="custom-radio">
                <input 
                  type="radio" 
                  v-model="makeAssignmentLive" 
                  :value="'scheduled'"
                  name="assignmentLive"
                />
                <span class="radio-checkmark"></span>
              </span>
              Schedule for later
            </label>
          </div>
          
          <div v-if="makeAssignmentLive === 'scheduled'" class="scheduled-container date-time-group">
            <div class="date-input-wrapper">
              <span class="material-symbols-outlined date-icon">calendar_today</span>
              <input 
                v-model="liveDate" 
                type="date" 
                class="form-input date-input"
                required
              />
            </div>
            <div class="time-input-wrapper">
              <span class="material-symbols-outlined time-icon">schedule</span>
              <input 
                v-model="liveTime" 
                type="time" 
                class="form-input time-input"
                required
              />
            </div>
          </div>
        </div>
      </div>
      
      <!-- Modal Footer -->
      <div class="modal-footer">
        <button 
          @click="createAssignment" 
          class="button primary-button"
          :disabled="!isFormValid"
        >
          Continue
          <span class="material-symbols-outlined">arrow_forward</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useInstructorStore } from "@/store/instructorData";
import axiosInstance from "@/utils/axiosInstance";

export default {
  name: 'NewAssignmentModal',
  data() {
    return {
      title: '',
      course: '',
      description: '',
      dueDate: '',
      dueTime: '23:59',
      makeAssignmentLive: 'immediately',
      liveDate: '',
      liveTime: '',
      courses: [
        { id: 'CS101', name: 'Introduction to Computer Science' },
        { id: 'MATH202', name: 'Advanced Mathematics' },
        { id: 'PHYS101', name: 'Physics I' }
      ],
    };
  },
  computed: {
    instructorStore() {
      return useInstructorStore();
    },
    isFormValid() {
      const validTitle = this.title.trim() !== '';
      const validCourse = this.course !== '';
      const validDueDate = this.dueDate !== '';
      const validDueTime = this.dueTime !== '';
      
      const validLiveDate = this.makeAssignmentLive === 'immediately' || 
        (this.makeAssignmentLive === 'scheduled' && this.liveDate !== '' && this.liveTime !== '');
      
      return validTitle && validCourse && validDueDate && validDueTime && validLiveDate;
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    createAssignment() {
      if (!this.isFormValid) {
        return;
      }
      
      // Prepare assignment data
      const assignmentData = {
        title: this.title,
        course: this.course,
        description: this.description,
        due_date: this.formatDateTime(this.dueDate, this.dueTime),
        live_date: this.makeAssignmentLive === 'immediately' 
          ? new Date().toISOString() 
          : this.formatDateTime(this.liveDate, this.liveTime)
      };
      
      // Emit event with assignment data
      this.$emit('assignment-created', assignmentData);
      this.closeModal();
    },
    formatDateTime(date, time) {
      return `${date}T${time}:00`;
    }
  },
  mounted() {
    // Set default dates
    const today = new Date();
    const oneWeekLater = new Date();
    oneWeekLater.setDate(today.getDate() + 7);
    
    this.dueDate = oneWeekLater.toISOString().split('T')[0];
    this.liveDate = today.toISOString().split('T')[0];
    this.liveTime = today.getHours().toString().padStart(2, '0') + ':' + 
                   today.getMinutes().toString().padStart(2, '0');
    
    // Set default course if available
    if (this.courses.length > 0) {
      this.course = this.courses[0].id;
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1100; /* Increased z-index to be above sidebar */
  backdrop-filter: blur(3px);
  transition: all 0.3s ease;
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  max-width: 800px;
  background-color: var(--accent3);
  border-radius: 1.2rem;
  z-index: 1101; /* Higher than overlay */
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  animation: modalFadeIn 0.3s ease;
  overflow: hidden;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -48%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 2.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  background-color: var(--accent3);
}

.modal-header h2 {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
  color: var(--primary);
}

.close-button {
  cursor: pointer;
  color: var(--accent4);
  transition: all 0.2s ease;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--primary);
}

.close-button .material-symbols-outlined {
  font-size: 1.8rem;
}

.modal-content {
  padding: 0 2.5rem 2rem;
  max-height: 60vh;
  overflow-y: auto;
  transition: all 0.3s ease;
}

.form-group {
  margin-bottom: 1.8rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.8rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--primary);
}

.form-input {
  width: 100%;
  padding: 1rem 1.2rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.6rem;
  font-size: 1rem;
  transition: all 0.2s ease;
  background-color: white;
  color: var(--primary);
}

.form-input:focus {
  outline: none;
  border-color: var(--classi2);
  box-shadow: 0 0 0 3px rgba(var(--classi2-rgb), 0.1);
}

.form-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.form-textarea {
  min-height: 120px;
  resize: vertical;
}

.select-wrapper {
  position: relative;
}

.select-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--accent4);
}

.date-time-group {
  display: flex;
  flex-direction: column;
}

.date-time-inputs {
  display: flex;
  gap: 1rem;
}

.date-input-wrapper,
.time-input-wrapper {
  position: relative;
  flex: 1;
}

.date-icon,
.time-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--accent4);
  z-index: 1;
}

.date-input,
.time-input {
  padding-left: 3rem;
}

.radio-group {
  display: flex;
  gap: 2.5rem;
  margin-top: 0.8rem;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.custom-radio {
  position: relative;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.8rem;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: all 0.2s ease;
}

.custom-radio:hover input ~ .radio-checkmark {
  border-color: rgba(0, 0, 0, 0.3);
}

.custom-radio input:checked ~ .radio-checkmark {
  border-color: var(--classi2);
}

.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background: var(--classi2);
}

.custom-radio input:checked ~ .radio-checkmark:after {
  display: block;
}

.scheduled-container {
  display: flex;
  gap: 1rem;
  margin-top: 1.2rem;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1.8rem 2.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.button {
  padding: 0.9rem 1.8rem;
  border: none;
  border-radius: 0.6rem;
  font-size: 1.05rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.button .material-symbols-outlined {
  font-size: 1.2rem;
}

.button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.primary-button {
  background-color: var(--classi2);
  color: white;
  margin-left: 1rem;
  box-shadow: 0 3px 8px rgba(var(--classi2-rgb), 0.2);
}

.primary-button:hover:not(:disabled) {
  background-color: var(--classi1);
  transform: translateY(-2px);
  box-shadow: 0 5px 12px rgba(var(--classi1-rgb), 0.25);
}

/* For smaller screens */
@media (max-width: 768px) {
  .modal-container {
    width: 90vw;
  }
  
  .date-time-inputs,
  .scheduled-container {
    flex-direction: column;
  }
  
  .step {
    width: 2rem;
    height: 2rem;
  }
  
  .step-line {
    max-width: 3rem;
  }
}
</style> 