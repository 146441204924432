<template>
    <div class="course-container">
        <div class="title-row">
            <button @click="goBack" class="back-button">
                <span class="material-symbols-outlined">arrow_back</span>
                <span>Back to Classes</span>
            </button>
            <h1 class="course-title">{{ course.course_name }}</h1>
            <div class="course-code-badge">{{ courseCode }}</div>
        </div>
        
        <LoadingSpinner v-if="loading" />
        
        <div v-else class="course-content">
            <!-- Tab Navigation -->
            <div class="tabs-container">
                <div 
                    v-for="tab in tabs" 
                    :key="tab.id" 
                    :class="['tab', { active: activeTab === tab.id }]"
                    @click="activeTab = tab.id"
                >
                    <span class="material-symbols-outlined">{{ tab.icon }}</span>
                    <span>{{ tab.label }}</span>
                </div>
            </div>
            
            <!-- Main Tab Content -->
            <div v-if="activeTab === 'overview'" class="tab-content">
                <div class="main-panel">
                    <!-- Grade Overview -->
                    <div class="grade-overview panel">
                        <div class="panel-header">
                            <h2>Grade Overview</h2>
                        </div>
                        <div class="panel-content grade-content">
                            <div class="overall-grade">
                                <div class="grade-circle">
                                    <span>{{ courseGrade || 'N/A' }}</span>
                                </div>
                                <div class="grade-label">Overall Grade</div>
                            </div>
                            <div class="grade-stats">
                                <div class="stat-item">
                                    <div class="stat-value">{{ completedAssignments }}/{{ totalAssignments }}</div>
                                    <div class="stat-label">Assignments Completed</div>
                                </div>
                                <div class="stat-item">
                                    <div class="stat-value">{{ upcomingDeadlines }}</div>
                                    <div class="stat-label">Upcoming Deadlines</div>
                                </div>
                                <div class="stat-item">
                                    <div class="stat-value">{{ attendanceRate || 'N/A' }}</div>
                                    <div class="stat-label">Attendance Rate</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Upcoming Assignments -->
                    <div class="upcoming panel">
                        <div class="panel-header">
                            <h2>Upcoming Assignments</h2>
                            <button class="view-all-btn" @click="viewAllAssignments">View All</button>
                        </div>
                        <div class="panel-content">
                            <TaskList
                                class="assignment-list"
                                :shortened="true"
                                title=""
                                :tasks="tasks"
                                :filters="filters"
                                @assignmentActivated="goToAssignment"
                            />
                        </div>
                    </div>
                </div>
                
                <div class="sidebar-panel">
                    <!-- Announcements (reduced size) -->
                    <div class="announcements panel">
                        <div class="panel-header">
                            <h2>Announcements</h2>
                            <span class="announcement-count">{{ announcements.length }}</span>
                        </div>
                        <div class="announcements-container panel-content">
                            <div
                                v-for="announcement in announcements.slice(0, 2)"
                                :key="announcement.announcement_id"
                                class="announcement-card"
                            >
                                <div class="announcement-header">
                                    <h3 class="announcement-title">{{ announcement.title }}</h3>
                                    <p class="announcement-meta">
                                        <span class="material-symbols-outlined">calendar_today</span>
                                        {{ formatDate(announcement.created_at) }}
                                    </p>
                                </div>
                                <div class="announcement-content">
                                    <p>{{ announcement.content }}</p>
                                </div>
                            </div>
                            <button 
                                v-if="announcements.length > 2" 
                                class="view-all-announcements"
                                @click="viewAllAnnouncements"
                            >
                                View All Announcements
                            </button>
                        </div>
                    </div>
                    
                    <!-- Upcoming Sessions -->
                    <div class="upcoming-sessions panel">
                        <div class="panel-header">
                            <h2>Upcoming Sessions</h2>
                        </div>
                        <div class="panel-content">
                            <div class="session-card">
                                <div class="session-date">
                                    <div class="date-box">
                                        <div class="month">OCT</div>
                                        <div class="day">15</div>
                                    </div>
                                    <div class="time">9:00 - 10:30 AM</div>
                                </div>
                                <div class="session-details">
                                    <h3>Lecture: Introduction to Topic</h3>
                                    <div class="session-location">
                                        <span class="material-symbols-outlined">location_on</span>
                                        <span>Room 201</span>
                                    </div>
                                </div>
                            </div>
                            <div class="session-card">
                                <div class="session-date">
                                    <div class="date-box">
                                        <div class="month">OCT</div>
                                        <div class="day">17</div>
                                    </div>
                                    <div class="time">2:00 - 3:30 PM</div>
                                </div>
                                <div class="session-details">
                                    <h3>Lab Session</h3>
                                    <div class="session-location">
                                        <span class="material-symbols-outlined">location_on</span>
                                        <span>Computer Lab</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Resources Tab Content -->
            <div v-if="activeTab === 'resources'" class="tab-content resources-tab">
                <div class="resources-container panel">
                    <div class="panel-header">
                        <h2>Course Resources</h2>
                    </div>
                    <div class="panel-content resources-grid full-grid">
                        <div class="resource-item" @click="openResource('syllabus')">
                            <span class="material-symbols-outlined resource-icon">description</span>
                            <div class="resource-text">
                                <h3>Course Syllabus</h3>
                                <p>Course outline and requirements</p>
                            </div>
                        </div>
                        <div class="resource-item" @click="openResource('readings')">
                            <span class="material-symbols-outlined resource-icon">menu_book</span>
                            <div class="resource-text">
                                <h3>Reading Materials</h3>
                                <p>Required and recommended readings</p>
                            </div>
                        </div>
                        <div class="resource-item" @click="openResource('lectures')">
                            <span class="material-symbols-outlined resource-icon">slideshow</span>
                            <div class="resource-text">
                                <h3>Lecture Slides</h3>
                                <p>Presentations from lectures</p>
                            </div>
                        </div>
                        <div class="resource-item" @click="openResource('additional')">
                            <span class="material-symbols-outlined resource-icon">folder_open</span>
                            <div class="resource-text">
                                <h3>Additional Materials</h3>
                                <p>Supplementary resources</p>
                            </div>
                        </div>
                        <div class="resource-item" @click="openResource('videos')">
                            <span class="material-symbols-outlined resource-icon">video_library</span>
                            <div class="resource-text">
                                <h3>Lecture Recordings</h3>
                                <p>Video recordings of past lectures</p>
                            </div>
                        </div>
                        <div class="resource-item" @click="openResource('practice')">
                            <span class="material-symbols-outlined resource-icon">fitness_center</span>
                            <div class="resource-text">
                                <h3>Practice Materials</h3>
                                <p>Practice problems and exercises</p>
                            </div>
                        </div>
                        <div class="resource-item" @click="openResource('software')">
                            <span class="material-symbols-outlined resource-icon">laptop_mac</span>
                            <div class="resource-text">
                                <h3>Software & Tools</h3>
                                <p>Required software and setup instructions</p>
                            </div>
                        </div>
                        <div class="resource-item" @click="openResource('references')">
                            <span class="material-symbols-outlined resource-icon">auto_stories</span>
                            <div class="resource-text">
                                <h3>References</h3>
                                <p>Reference materials and documentation</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Discussion Tab Content -->
            <div v-if="activeTab === 'discussion'" class="tab-content discussion-tab">
                <div class="discussion-container panel">
                    <div class="panel-header">
                        <h2>Discussion Board</h2>
                    </div>
                    <div class="discussion-content">
                        <div class="message-container full-height" ref="messageContainer">
                            <div v-if="discussionPosts.length === 0" class="no-posts">
                                No discussions yet. Start a conversation!
                            </div>
                            <div v-for="(post, index) in discussionPosts" :key="index" class="post">
                                <div class="post-header">
                                    <div class="post-author">
                                        <span class="material-symbols-outlined">account_circle</span>
                                        <span>{{ post.first_name }} {{ post.last_name }}</span>
                                    </div>
                                    <div class="post-time">
                                        <span class="material-symbols-outlined">schedule</span>
                                        {{ formatDate(post.posted_at) }}
                                    </div>
                                </div>
                                <div class="post-content">
                                    {{ post.content }}
                                </div>
                            </div>
                        </div>
                        <div class="message-input-container">
                            <textarea
                                v-model="discussionInput"
                                class="message-input"
                                placeholder="Share your thoughts with the class..."
                                @keydown.enter.prevent="clickSendButton"
                            ></textarea>
                            <button ref="sendButton" class="send-button" @click="createDiscussionPost">
                                <span class="material-symbols-outlined">send</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Announcements Tab (shown when viewing all) -->
            <div v-if="activeTab === 'announcements'" class="tab-content announcements-tab">
                <div class="all-announcements panel">
                    <div class="panel-header">
                        <h2>All Announcements</h2>
                    </div>
                    <div class="panel-content all-announcements-list">
                        <div
                            v-for="announcement in announcements"
                            :key="announcement.announcement_id"
                            class="announcement-card full"
                        >
                            <div class="announcement-header">
                                <h3 class="announcement-title">{{ announcement.title }}</h3>
                                <p class="announcement-meta">
                                    <span class="material-symbols-outlined">calendar_today</span>
                                    {{ formatDate(announcement.created_at) }}
                                </p>
                            </div>
                            <div class="announcement-content full">
                                <p>{{ announcement.content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import Tooltip from "@/components/Tooltip.vue";
import axiosInstance from "@/utils/axiosInstance";
import TaskList from "./TaskList.vue";
import LoadingSpinner from "./LoadingSpinner.vue";
import { useAssignmentStore } from "@/store/assignmentData";
import { useStudentStore } from "@/store/studentData";

export default {
    name: "StudentCourse",
    data() {
        return {
            isCollapsed: false,
            loading: true,
            announcements: [],
            filters: {
                completion: {
                    graded: false,
                    submitted: false,
                    inProgress: false,
                    incomplete: false,
                },
                subjects: [],
                dueDate: {
                    today: false,
                    thisWeek: false,
                    thisMonth: true,
                },
            },
            discussionPosts: [],
            discussionInput: "",
            activeTab: 'overview',
            tabs: [
                { id: 'overview', label: 'Overview', icon: 'dashboard' },
                { id: 'resources', label: 'Resources', icon: 'library_books' },
                { id: 'discussion', label: 'Discussion', icon: 'forum' },
                { id: 'announcements', label: 'Announcements', icon: 'campaign', hidden: true }
            ],
            // Placeholder data for new components
            courseGrade: 'A-',
            completedAssignments: 3,
            totalAssignments: 5,
            upcomingDeadlines: 2,
            attendanceRate: '90%',
        };
    },
    props: ["courseCode"],
    components: {
        StudentHeader,
        Sidebar,
        Tooltip,
        TaskList,
        LoadingSpinner,
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
        assignmentStore() {
            return useAssignmentStore();
        },
        tasks() {
            return Object.values(this.assignmentStore.assignments);
        },
        course() {
            return this.studentStore.getCourse(this.courseCode)[0];
        },
        visibleTabs() {
            return this.tabs.filter(tab => !tab.hidden);
        }
    },
    methods: {
        handleToggle(collapsed) {
            this.isCollapsed = collapsed;
        },
        goBack() {
            this.$router.go(-1);
        },
        clickSendButton() {
            this.$refs.sendButton.click();
        },
        sortPostsByDate(posts) {
            return posts.slice().sort((a, b) => {
                return new Date(a.posted_at) - new Date(b.posted_at);
            });
        },
        async getAnnouncements() {
            try {
                const announcementsResponse = await axiosInstance.post(
                    "/students/get-announcements",
                    {
                        course_code: this.courseCode,
                    }
                );
                this.announcements = announcementsResponse.data;
                this.loading = false;
            } catch (error) {
                console.log("Announcements not found");
            }
        },
        async getDiscussionPosts() {
            try {
                const postsResponse = await axiosInstance.post(
                    "/students/get-discussion-posts",
                    {
                        course_code: this.courseCode,
                    }
                );
                const sortedPosts = this.sortPostsByDate(postsResponse.data);
                this.discussionPosts = sortedPosts;

                this.loading = false;
            } catch (error) {
                console.log("Discussion posts not found");
            }
        },
        async createDiscussionPost() {
            const datetime = new Date().toISOString();
            const content = this.discussionInput;
            const post = {
                first_name: this.studentStore.firstName,
                last_name: this.studentStore.lastName,
                content: content,
                posted_at: datetime,
            };

            this.discussionPosts.push(post);
            this.discussionInput = "";
            this.scrollToBottom();
            try {
                const setResponse = await axiosInstance.post(
                    "/students/create-discussion-post",
                    {
                        course_code: this.courseCode,
                        content: content,
                        posted_at: datetime,
                    }
                );
            } catch (error) {
                console.log("Could not create discussion post", error);
            }
        },
        formatDate(date) {
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            };
            return new Date(date).toLocaleDateString(undefined, options);
        },
        goToAssignment(assignment) {
            this.$router.push({
                name: "StudentAssignment",
                params: { assignmentId: assignment.assignment_id },
            });
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.messageContainer;
                container.scrollTop = container.scrollHeight;
            });
        },
        openResource(resourceType) {
            // Placeholder function for opening different resources
            console.log(`Opening resource: ${resourceType}`);
            // In a real implementation, this would navigate to or display the resource
        },
        viewAllAnnouncements() {
            this.activeTab = 'announcements';
        },
        viewAllAssignments() {
            // Navigate to StudentAssignments with filter for this course
            this.$router.push({
                name: "StudentAssignments",
                query: { courseCode: this.courseCode }
            });
        }
    },
    mounted() {
        this.getAnnouncements();
        this.getDiscussionPosts();
    },
};
</script>

<style scoped>
.course-container {
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.title-row {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    position: relative;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: transparent;
    color: var(--primary);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.back-button:hover {
    background-color: var(--classi2);
    color: white;
    border-color: var(--classi2);
}

.course-title {
    margin: 0 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    flex-grow: 1;
}

.course-code-badge {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--classi2);
    background-color: rgba(var(--classi2-rgb), 0.1);
    padding: 0.4rem 0.8rem;
    border-radius: 6px;
}

.course-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: calc(100% - 4rem);
}

/* Tabs */
.tabs-container {
    display: flex;
    gap: 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.5rem;
}

.tab {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.2s ease;
    font-weight: 500;
}

.tab:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.tab.active {
    background-color: var(--classi2);
    color: white;
}

.tab-content {
    flex: 1;
    display: flex;
    gap: 1.5rem;
    overflow: auto;
}

/* Overview Tab */
.main-panel {
    flex: 7;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.sidebar-panel {
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.panel {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
}

.panel-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.panel-header h2 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
}

.panel-content {
    flex: 1;
    overflow: auto;
    padding: 1rem;
}

/* Resources Tab */
.resources-tab, .discussion-tab, .announcements-tab {
    height: 100%;
}

.resources-container, .discussion-container, .all-announcements {
    width: 100%;
    height: 90%;
    border: 1px solid var(--classi2);
}

.full-grid {
    grid-template-columns: repeat(4, 1fr);
    height: 100%;
    overflow-y: auto;
}

/* Discussion Tab */
.discussion-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.full-height {
    height: 100%;
    max-height: none;
}

.message-container {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
}

/* Announcements Tab */
.all-announcements-list {
    padding: 1.5rem;
}

.announcement-card.full .announcement-content {
    max-height: none;
}

.announcement-card.full {
    margin-bottom: 2rem;
    padding: 1.5rem;
}

/* Grade Overview */
.grade-content {
    display: flex;
    padding: 1.5rem;
}

.overall-grade {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding-right: 1.5rem;
}

.grade-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(var(--classi2-rgb), 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 700;
    color: var(--classi2);
    margin-bottom: 1rem;
}

.grade-label {
    font-size: 0.9rem;
    color: var(--accent4);
}

.grade-stats {
    flex: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.stat-item {
    text-align: center;
}

.stat-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primary);
    margin-bottom: 0.5rem;
}

.stat-label {
    font-size: 0.8rem;
    color: var(--accent4);
}

/* Course Resources */
.resources-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem;
}

.resource-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
    transition: all 0.2s ease;
}

.resource-item:hover {
    background-color: rgba(var(--classi2-rgb), 0.05);
    transform: translateY(-2px);
}

.resource-icon {
    font-size: 2rem;
    color: var(--classi2);
}

.resource-text h3 {
    margin: 0 0 0.25rem 0;
    font-size: 1rem;
    font-weight: 600;
}

.resource-text p {
    margin: 0;
    font-size: 0.8rem;
    color: var(--accent4);
}

/* Upcoming */
.upcoming {
    flex: 1;
}

.view-all-btn {
    background: none;
    border: none;
    color: var(--classi2);
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
}

.view-all-btn:hover {
    text-decoration: underline;
}

.assignment-list {
    height: 100%;
}

/* Announcements (reduced) */
.announcements {
    margin-bottom: 1.5rem;
}

.announcement-count {
    background-color: var(--classi2);
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0.2rem 0.6rem;
    border-radius: 12px;
}

.announcement-card {
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    transition: transform 0.2s ease;
}

.announcement-card:hover {
    transform: translateY(-2px);
}

.announcement-header {
    margin-bottom: 0.5rem;
}

.announcement-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
}

.announcement-meta {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.7rem;
    color: var(--accent4);
    margin: 0;
}

.announcement-content p {
    margin: 0;
    font-size: 0.8rem;
    line-height: 1.4;
    max-height: 3.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.announcement-content.full p {
    max-height: none;
    -webkit-line-clamp: initial;
    font-size: 0.9rem;
    line-height: 1.6;
}

.view-all-announcements {
    width: 100%;
    background: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: var(--classi2);
    padding: 0.5rem;
    border-radius: 8px;
    font-size: 0.8rem;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.view-all-announcements:hover {
    background-color: rgba(var(--classi2-rgb), 0.05);
}

/* Upcoming Sessions */
.session-card {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.02);
}

.session-date {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date-box {
    background-color: var(--classi2);
    color: white;
    border-radius: 8px;
    padding: 0.5rem;
    width: 50px;
    text-align: center;
    margin-bottom: 0.5rem;
}

.month {
    font-size: 0.7rem;
    font-weight: 600;
}

.day {
    font-size: 1.2rem;
    font-weight: 700;
}

.time {
    font-size: 0.7rem;
    color: var(--accent4);
}

.session-details {
    flex: 1;
}

.session-details h3 {
    margin: 0 0 0.5rem 0;
    font-size: 0.9rem;
    font-weight: 600;
}

.session-location {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.8rem;
    color: var(--accent4);
}

/* Discussion Posts */
.no-posts {
    text-align: center;
    color: var(--accent4);
    padding: 1rem;
}

.post {
    margin-bottom: 1rem;
}

.post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
}

.post-author, .post-time {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.7rem;
    color: var(--accent4);
}

.post-content {
    background-color: rgba(var(--classi2-rgb), 0.05);
    padding: 0.75rem;
    border-radius: 8px;
    font-size: 0.8rem;
    line-height: 1.4;
}

.message-input-container {
    display: flex;
    gap: 0.5rem;
    padding: 0.75rem;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.message-input {
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0.5rem;
    font-size: 0.8rem;
    resize: none;
    min-height: 2rem;
    max-height: 5rem;
    font-family: inherit;
}

.message-input:focus {
    outline: none;
    border-color: var(--classi2);
}

.send-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--classi2);
    color: white;
    border: none;
    border-radius: 8px;
    width: 2rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.send-button:hover {
    background-color: rgba(var(--classi2-rgb), 0.8);
}

@media (max-width: 1200px) {
    .tab-content {
        flex-direction: column;
    }
    
    .sidebar-panel {
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .sidebar-panel > div {
        flex: 1 1 45%;
    }
    
    .resources-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .full-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .resources-grid, .full-grid {
        grid-template-columns: 1fr;
    }
    
    .tabs-container {
        overflow-x: auto;
        padding-bottom: 0.25rem;
    }
    
    .tab {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
    }
}
</style>
