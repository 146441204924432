<template>
    <div class="main-container">
        <div class="section1">
            <div class="header">
                <router-link to="/" class="logo" aria-label="Classi logo, go to homepage">
                    <span class="classi1">c</span>
                    <span class="classi1">l</span>
                    <span class="classi2">a</span>
                    <span class="classi1">s</span>
                    <span class="classi1">s</span>
                    <span class="classi2">i</span>
                </router-link>
                <div class="header-buttons">
                    <button class="top-btn primary-btn" @click="goToCalender" aria-label="Get in touch via calendar">
                        Get in touch
                    </button>
                    <router-link to="/login" class="top-btn secondary-btn" aria-label="Log in to your account">
                        Log In
                    </router-link>
                </div>
            </div>
            <div class="section1-body">
                <div class="tagline">
                    <p class="animate-text">Simplify Teaching</p>
                    <p class="animate-text delay-1">Amplify Learning</p>
                    <p class="animate-text delay-2" style="color: var(--classi2);">Let AI handle the rest</p>
                </div>
                <img :src="dashboardImg" class="dashboard-img" alt="Classi dashboard preview" />
            </div>
        </div>
        <div class="section2">
            <div class="byline">
                Classi is a modern, AI-powered learning management system built for the Indian classroom. It is designed
                to help schools enhance <span style="color: var(--classi2);">efficiency</span> for teachers and <span
                    style="color: var(--classi2);">accessibility</span> for students. Here's how:
            </div>
            <div class="feature-pills-container" ref="pillRef">
                <div class="feature-pill" :class="{ active: activePill === 'chat' }" @click="setActivePill('chat')" 
                     role="tab" aria-selected="activePill === 'chat'" tabindex="0" @keypress.enter="setActivePill('chat')">
                    Textbook Chat
                </div>
                <div class="feature-pill" :class="{ active: activePill === 'autograder' }" @click="setActivePill('autograder')"
                     role="tab" aria-selected="activePill === 'autograder'" tabindex="0" @keypress.enter="setActivePill('autograder')">
                    Autograder
                </div>
                <div class="feature-pill" :class="{ active: activePill === 'summarization' }" @click="setActivePill('summarization')"
                     role="tab" aria-selected="activePill === 'summarization'" tabindex="0" @keypress.enter="setActivePill('summarization')">
                    Lecture Summarization
                </div>
                <div class="active-indicator" :style="indicatorStyle"></div>
            </div>
            <div class="feature-description" v-if="activePill === 'chat'">
                Chat with your textbook like it's your personal tutor. <br/>Ask questions, get explanations, and deepen your understanding of any topic in your curriculum.
            </div>
            <div class="feature-description" v-if="activePill === 'autograder'">
                Save hours of grading time with our AI-powered autograder. <br/> Instantly evaluate digital <span style="color: var(--classi2);">or handwritten</span> student submissions while providing detailed feedback and learning insights.
            </div>
            <div class="feature-description" v-if="activePill === 'summarization'">
                Transform lengthy lectures into concise, structured notes. <br/> Our AI captures key concepts, examples, and important points, making review and revision more effective.
            </div>
        </div>
        <div class="section3">
            <GuestChatBox v-if="activePill === 'chat'" />
            <video controls class="video" v-if="activePill === 'autograder'">
                <source :src="autograderVideo" type="video/mp4">
                <p>Your browser does not support HTML video. Please upgrade to a modern browser.</p>
            </video>
            <div class="lecture-sum" v-if="activePill === 'summarization'">
                COMING SOON
            </div>
        </div>
        <footer class="footer">
            <div class="footer-content">
                <div class="footer-logo">
                    <span class="classi1">cl</span>
                    <span class="classi2">a</span>
                    <span class="classi1">ss</span>
                    <span class="classi2">i</span>
                </div>
                <!-- <div class="footer-links">
                    <a href="#" aria-label="About us">About</a>
                    <a href="#" aria-label="Contact us">Contact</a>
                    <a href="#" aria-label="Privacy policy">Privacy</a>
                    <a href="#" aria-label="Terms of service">Terms</a>
                </div> -->
                <!-- <div class="social-links">
                    <a href="#" target="_blank" rel="noopener noreferrer" aria-label="Facebook page"><i class="fab fa-facebook"></i></a>
                    <a href="#" target="_blank" rel="noopener noreferrer" aria-label="Twitter profile"><i class="fab fa-twitter"></i></a>
                    <a href="https://www.linkedin.com/company/105563444/admin/dashboard/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn profile"><i class="fab fa-linkedin"></i></a>
                    <a href="#" target="_blank" rel="noopener noreferrer" aria-label="Instagram profile"><i class="fab fa-instagram"></i></a>
                </div> -->
            </div>
            <div class="copyright">
                © {{ new Date().getFullYear() }} Classi. All rights reserved.
            </div>
        </footer>
    </div>
</template>

<script>
import GuestChatBox from '@/components/GuestChatBox.vue';
import axiosInstance from '@/utils/axiosInstance';
export default {
    components: {
        GuestChatBox
    },
    data() {
        return {
            dashboardImg: require("@/assets/app_dashboard.png"),
            autograderVideo: require("@/assets/autograder_video.mp4"),
            activePill: 'chat',
            isLoading: true,
            serverError: false
        };
    },
    async mounted() {
        // Add FontAwesome if not already included
        if (!document.getElementById('font-awesome-css')) {
            const link = document.createElement('link');
            link.id = 'font-awesome-css';
            link.rel = 'stylesheet';
            link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css';
            document.head.appendChild(link);
        }
        
        // Ping server with proper error handling
        try {
            this.isLoading = true;
            const response = await axiosInstance.get('/auth/ping');
            this.isLoading = false;
        } catch (error) {
            console.error('Server ping failed:', error);
            this.serverError = true;
            this.isLoading = false;
        }
        
        // Add animation classes after component mounts
        setTimeout(() => {
            document.querySelectorAll('.animate-text').forEach(el => {
                el.classList.add('visible');
            });
        }, 300);
    },
    methods: {
        scrollToPill() {
            this.$refs.pillRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        setActivePill(pill) {
            this.activePill = pill;
            this.scrollToPill();
        },
        goToCalender() {            
            window.open('https://calendar.app.google/pqhbri84xNdzwgQQ6', '_blank', 'noopener,noreferrer');
        },
    },
    computed: {
        indicatorStyle() {
            const pills = ['chat', 'autograder', 'summarization'];
            const index = pills.indexOf(this.activePill);
            const width = 100 / pills.length;
            
            return {
                left: `${index * width}%`,
                width: `${width}%`
            };
        },
    },
}
</script>

<style scoped>
.main-container {
    margin: 2vw;
    font-family: var(--main-font);
    color: #333;
    overflow-x: hidden;
}

.section1 {
    padding: 3vh 3vw;
    background-color: rgb(173, 216, 230, 0.5);
    border-radius: 2rem;
    transition: all 0.3s ease;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vh;
}

.logo {
    display: flex;
    align-items: center;
    font-size: 3vw;
    font-weight: 100;
    letter-spacing: 0.2vw;
    font-family: var(--accent-font);
    text-decoration: none;
    transition: transform 0.3s ease;
}

.logo:hover {
    transform: translateY(-5%);
}

.header-buttons {
    display: flex; 
    gap: 1vw;
}

.top-btn {
    color: white;
    cursor: pointer;
    border-radius: 2rem;
    padding: 1.5vh 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 1vw;
    border: none;
    transition: all 0.3s ease;
}

.primary-btn {
    background-color: var(--classi2);
}

.secondary-btn {
    background-color: #333;
}

.top-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.classi1 {
    color: var(--classi1);
    text-transform: none;
    transition: transform 0.3s ease;
}

.classi2 {
    color: var(--classi2);
    text-transform: none;
    transition: transform 0.3s ease;
}

.classi1:hover,
.classi2:hover {
    transform: translateY(-5%);
}

.section1-body {
    display: flex;
    gap: 2vw;
}

.tagline {
    flex: 1;
    font-family: var(--main-font);
    font-size: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
}

.animate-text {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease, transform 0.6s ease;
}

.animate-text.visible {
    opacity: 1;
    transform: translateY(0);
}

.delay-1 {
    transition-delay: 0.2s;
}

.delay-2 {
    transition-delay: 0.4s;
}

.dashboard-img {
    flex: 2;
    width: 50vw;
    border-radius: 2rem;
    box-shadow: var(--box-shadow);
    transition: all 0.4s ease;
}

.dashboard-img:hover {
    transform: scale(1.02);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.section2 {
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.byline {
    padding: 0 10vw;
    justify-content: center;
    font-size: 1.7vw;
    text-align: center;
    margin-bottom: 3vh;
    line-height: 1.6;
}

.feature-pills-container {
    position: relative;
    width: 50vw;
    background: #f0f0f0;
    border-radius: 2rem;
    display: flex;
    justify-content: space-around;
    /* padding: 1vh; */
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
}

.active-indicator {
    position: absolute;
    bottom: 0;
    height: 100%;
    background-color: var(--classi2);
    border-radius: 2rem;
    transition: all 0.3s ease;
    opacity: 0.9;
    z-index: 0;
}

.feature-pill {
    flex: 1;
    padding: 1.5vh 1vw;
    font-size: 1.3vw;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
    z-index: 1;
    white-space: nowrap;
    border-radius: 2rem;
}

.feature-pill:hover:not(.active) {
    background-color: rgba(255, 255, 255, 0.3);
}

.feature-pill.active {
    color: white;
    font-weight: 500;
}

.section3 {
    margin-top: 3vh;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.video {
    height: 80vh;
    margin-bottom: 3vh;
    border-radius: 2rem;
    box-shadow: var(--box-shadow);
    transition: all 0.3s ease;
}

.video:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.lecture-sum {
    width: 100%;
    border-radius: 2rem;
    height: 80vh;
    margin-bottom: 3vh;
    padding: 3vh 3vw;
    background-color: rgb(173, 216, 230, 0.5);
    padding-top: 35vh;
    text-align: center;
    font-size: 1.5rem;
    font-family: var(--accent-font);
    color: #333;
}

.footer {
    margin-top: 5vh;
    padding: 3vh 3vw;
    background-color: #f9f9f9;
    border-radius: 2rem 2rem 0 0;
    box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.05);
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
}

.footer-logo {
    font-size: 2vw;
    font-weight: 100;
    letter-spacing: 0.5vh;
    font-family: var(--accent-font);
}

.footer-links {
    display: flex;
    gap: 2vw;
}

.footer-links a {
    text-decoration: none;
    color: #666;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: var(--classi2);
}

.social-links {
    display: flex;
    gap: 1vw;
}

.social-links a {
    color: #666;
    font-size: 1.5vw;
    transition: all 0.3s ease;
}

.social-links a:hover {
    color: var(--classi2);
    transform: translateY(-3px);
}

.copyright {
    text-align: center;
    color: #888;
    font-size: 0.9rem;
}

.feature-description {
    margin-top: 2vh;
    text-align: center;
    font-size: 1.2vw;
    color: #666;
    max-width: 70vw;
    line-height: 1.6;
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Tablet Styles */
@media screen and (max-width: 1024px) {
    .main-container {
        margin: 1vw;
    }

    .logo {
        font-size: 4vw;
    }

    .header-buttons {
        gap: 2vw;
    }

    .top-btn {
        padding: 1vh 2vw;
        font-size: 2vw;
    }

    .section1-body {
        flex-direction: column;
        align-items: center;
    }

    .tagline {
        font-size: 5vw;
        text-align: center;
        margin-bottom: 3vh;
    }

    .dashboard-img {
        width: 90vw;
        margin-top: 2vh;
    }

    .byline {
        font-size: 2.5vw;
        padding: 0 5vw;
    }

    .feature-pills-container {
        width: 90vw;
    }

    .feature-pill {
        font-size: 2.5vw;
        padding: 1.2vh 1vw;
    }

    .video {
        height: 50vh;
        width: 90vw;
    }

    .lecture-sum {
        height: 50vh;
        padding-top: 20vh;
    }
    
    .footer-logo {
        font-size: 3vw;
    }
    
    .social-links a {
        font-size: 2.5vw;
    }

    .feature-description {
        font-size: 2vw;
        max-width: 80vw;
    }
}

/* Mobile Styles */
@media screen and (max-width: 600px) {
    .main-container {
        margin: 1vh 2vw;
    }

    .logo {
        font-size: 6vw;
        letter-spacing: 0.5vh;
    }

    .header-buttons {
        width: 100%;
        justify-content: flex-end;
    }

    .top-btn {
        padding: 1vh 3vw;
        font-size: 3.5vw;
    }

    .tagline {
        font-size: 6vw;
        margin-bottom: 0;
    }

    .dashboard-img {
        width: 85vw;
        border-radius: 1rem;
    }

    .byline {
        font-size: 4vw;
        padding: 0 3vw;
    }

    .feature-pills-container {
        width: 95vw;
    }

    .feature-pill {
        font-size: 3.5vw;
        padding: 1.5vh 1vw;
        white-space: normal;
    }

    .video {
        height: 40vh;
        width: 95vw;
    }

    .lecture-sum {
        height: 40vh;
        padding-top: 15vh;
        font-size: 1.5rem;
    }
    
    .footer {
        padding: 3vh 5vw;
    }
    
    .footer-content {
        flex-direction: column;
        gap: 3vh;
    }
    
    .footer-logo {
        font-size: 5vw;
    }
    
    .footer-links {
        gap: 4vw;
    }
    
    .social-links a {
        font-size: 6vw;
    }
    
    .copyright {
        margin-top: 2vh;
    }

    .feature-description {
        font-size: 3.5vw;
        max-width: 90vw;
    }
}
</style>