<template>
  <div :class="['main-container', { collapsed: isCollapsed }]">
    <Sidebar @toggle="handleToggle" :collapsed="isCollapsed" />
    <div :class="['content-container', { collapsed: isCollapsed }]">
      <StudentHeader title="Assignments" />
      <LoadingSpinner class="spinner" v-if="loading" />
      <div v-if="!loading && isMainContent">
        <div class="top-bar">
          <FilterMenu
            :filters="filters"
            :subjects="subjects"
            @clearFilters="clearFilters"
          />
        </div>
        
        <!-- Tabs Navigation -->
        <div class="tabs-container">
          <div 
            :class="['tab', { active: activeTab === 'upcoming' }]" 
            @click="activeTab = 'upcoming'"
          >
            Upcoming
          </div>
          <div 
            :class="['tab', { active: activeTab === 'past' }]" 
            @click="activeTab = 'past'"
          >
            Past
          </div>
        </div>
        
        <!-- Tab Content -->
        <div class="tab-content">
          <TaskList
            v-if="activeTab === 'upcoming'"
            :tasks="upcomingTasks"
            :filters="filters"
            @assignmentActivated="goToAssignment"
          />
          
          <TaskList
            v-if="activeTab === 'past'"
            :tasks="pastTasks"
            :filters="filters"
            @assignmentActivated="goToAssignment"
          />
        </div>
      </div>
      <router-view v-if="!loading && !isMainContent"></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import StudentHeader from "@/components/StudentHeader.vue";
import StudentAssignment from "@/components/StudentAssignment.vue";
import TaskList from "@/components/TaskList.vue";
import FilterMenu from "@/components/FilterMenu.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useAssignmentStore } from "@/store/assignmentData";

export default {
  name: "StudentAssignments",
  components: {
    Sidebar,
    StudentHeader,
    TaskList,
    FilterMenu,
    StudentAssignment,
    LoadingSpinner,
  },
  props: {
    selectedFilters: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isCollapsed: false,
      loading: false,
      activeTab: "upcoming",
      filters: {
        completion: {
          graded: false,
          submitted: false,
          inProgress: false,
          incomplete: false,
        },
        subjects: [],
        dueDate: {
          today: false,
          thisWeek: false,
          thisMonth: false,
        },
      },
    };
  },
  computed: {
    assignmentStore() {
      return useAssignmentStore();
    },
    isMainContent() {
      return this.$route.path === "/student/assignments";
    },
    assignments() {
      return Object.values(this.assignmentStore.assignments);
    },
    upcomingTasks() {
      const now = new Date();
      return this.assignments.filter((task) => {
        // Handle both date formats (due vs due_date)
        const dueDate = task.due || task.due_date;
        return new Date(dueDate) >= now;
      });
    },
    pastTasks() {
      const now = new Date();
      return this.assignments.filter((task) => {
        // Handle both date formats (due vs due_date)
        const dueDate = task.due || task.due_date;
        return new Date(dueDate) < now;
      });
    },
    subjects() {
      return [...new Set(this.assignments.map((task) => task.course))];
    },
  },
  methods: {
    goToCalendar() {
      this.$router.push("/calendar");
    },
    handleToggle(collapsed) {
      this.isCollapsed = !collapsed;
    },
    clearFilters() {
      this.filters = {
        completion: {
          graded: false,
          submitted: false,
          inProgress: false,
          incomplete: false,
        },
        subjects: [],
        dueDate: { today: false, thisWeek: false, thisMonth: false },
      };
    },
    goToAssignment(assignment) {
      this.$router.push({
        name: "StudentAssignment",
        params: { assignmentId: assignment.assignment_id },
      });
    },
    goBackToMain() {
      this.activeAssignment = null;
    },
  },
  mounted() {
    // Check if filters need to be applied on mount
    if (this.selectedFilters !== "") {
      this.filters["completion"][this.selectedFilters] = true;
    }

    this.activeAssignment = null;
    
    // Load assignments data when component mounts
    this.loading = true;
    this.assignmentStore.getAssignments().finally(() => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  transition: all 0.3s ease-in-out;
  font-family: var(--main-font);
  margin: 2vh 2vw;
}

.main-container.collapsed .content-container {
  margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
  margin-left: 18vw;
}

.content-container {
  flex: 1;
  transition: margin-left 0.3s ease-in-out;
  color: var(--primary);
  font-size: 1.2rem;
}

.spinner {
  margin: 20vh auto;
}

.top-bar {
  margin-top: 2vh;
  margin-bottom: 3vh;
}

.tabs-container {
  display: flex;
  margin-bottom: 2vh;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tab {
  padding: 1vh 2vw;
  font-size: 1.1vw;
  cursor: pointer;
  position: relative;
  color: var(--accent4);
  transition: all 0.2s ease;
}

.tab.active {
  color: var(--classi2); 
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--classi2);
}

.tab:hover:not(.active) {
  color: var(--primary);
}

.tab-content {
  min-height: 50vh;
}
</style>
