<template>
  <div class="creation-page-container">
    <div class="header">
      <div class="back-button" @click="goBack">
        <span class="material-symbols-outlined">arrow_back</span>
      </div>
      <div class="title-container">
        <div class="navigation-info">Assignments > New Assignment</div>
        <h1 class="page-title">{{ assignmentData.title }}</h1>
      </div>
    </div>

    <!-- Assignment Creation Method Selection -->
    <div v-if="currentStep === 'select-type'" class="creation-options-container">
      <h2 class="section-title">Choose Creation Method</h2>
      
      <div class="creation-options">
        <div 
          class="creation-option" 
          @click="selectCreationType('document')"
        >
          <div class="option-icon">
            <span class="material-symbols-outlined">description</span>
          </div>
          <div class="option-content">
            <h3>From Document</h3>
            <p>Upload an existing document and convert it to an assignment</p>
          </div>
        </div>
        
        <div 
          class="creation-option" 
          @click="selectCreationType('scratch')"
        >
          <div class="option-icon">
            <span class="material-symbols-outlined">edit_note</span>
          </div>
          <div class="option-content">
            <h3>From Scratch</h3>
            <p>Create a new assignment from scratch with your own questions</p>
          </div>
        </div>
        
        <div 
          class="creation-option" 
          @click="selectCreationType('ai')"
        >
          <div class="option-icon">
            <span class="material-symbols-outlined">auto_awesome</span>
          </div>
          <div class="option-content">
            <h3>With AI</h3>
            <p>Let AI help generate a new assignment based on your requirements</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Assignment Creation Forms -->
    <div v-if="currentStep !== 'select-type'" class="creation-content-container">
      <!-- Create from Document -->
      <AssignmentFromDocument 
        v-if="assignmentType === 'document'"
        :marking-scheme-url="markingSchemeUrl"
        :is-marking-scheme-pdf="isMarkingSchemePdf"
        :marking-scheme-title="assignmentData.title"
        :due-date="assignmentData.due_date"
        :express-processing="expressProcessing"
        :marking-scheme-loading="markingSchemeLoading"
        :marking-scheme-success="markingSchemeSuccess"
        :processing-progress="processingProgress"
        :view-questions="viewQuestions"
        :parsed-questions="parsedQuestions"
        :edit-mode="editMode"
        :current-files="currentFiles"
        @update:marking-scheme-title="updateAssignmentTitle"
        @update:due-date="updateDueDate"
        @update:express-processing="expressProcessing = $event"
        @update:view-questions="viewQuestions = $event"
        @update:edit-mode="editMode = $event"
        @update:parsed-questions="parsedQuestions = $event"
        @load-marking-scheme="loadMarkingScheme"
        @remove-file="removeFile"
        @go-to-question-viewer="goToQuestionViewer"
        @go-back="viewQuestions = false"
        @save-edits="saveEdits"
        @create-assignment="createAssignment('document')"
      />

      <!-- Create from Scratch -->
      <AssignmentFromScratch 
        v-if="assignmentType === 'scratch'"
        :title="assignmentData.title"
        :description="assignmentData.description"
        :due-date="assignmentData.due_date"
        :current-step="scratchStep"
        :questions="questions"
        @update:title="updateAssignmentTitle"
        @update:description="updateDescription"
        @update:due-date="updateDueDate"
        @update:questions="questions = $event"
        @update:current-step="scratchStep = $event"
        @next-step="nextScratchStep"
        @prev-step="prevScratchStep"
        @add-question="addQuestion"
        @remove-question="removeQuestion"
        @create-assignment="createAssignment('scratch')"
      />

      <!-- Create with AI -->
      <AssignmentWithAI
        v-if="assignmentType === 'ai'"
        :title="assignmentData.title"
        :due-date="assignmentData.due_date"
        @update:title="updateAssignmentTitle"
        @update:due-date="updateDueDate"
        @edit-question="handleEditQuestion"
        @create-assignment="createAssignment"
      />
    </div>
  </div>
</template>

<script>
import { useInstructorStore } from "@/store/instructorData";
import AssignmentFromDocument from "@/components/grader/AssignmentFromDocument.vue";
import AssignmentFromScratch from "@/components/grader/AssignmentFromScratch.vue";
import AssignmentWithAI from "@/components/grader/AssignmentWithAI.vue";
import axiosInstance from "@/utils/axiosInstance";
import { getTenant } from "@/utils/tenantStore";

export default {
  name: 'AssignmentCreationPage',
  components: {
    AssignmentFromDocument,
    AssignmentFromScratch,
    AssignmentWithAI
  },
  data() {
    return {
      assignmentData: {},
      assignmentType: '',
      currentStep: 'select-type',
      
      // From scratch
      scratchStep: 0,
      questions: [{ content: "", marks: 1 }],
      
      // From document
      expressProcessing: 'normal',
      markingSchemeUrl: null,
      isMarkingSchemePdf: false,
      markingSchemeLoading: false,
      markingSchemeSuccess: false,
      markingSchemeId: -1,
      processingProgress: null,
      viewQuestions: false,
      parsedQuestions: {},
      editMode: -1,
      currentFiles: {
        markingScheme: null,
      },
      gsutil_url: null
    };
  },
  computed: {
    instructorStore() {
      return useInstructorStore();
    }
  },
  methods: {
    goBack() {
      if (this.currentStep !== 'select-type') {
        this.currentStep = 'select-type';
      } else {
        this.$router.push({ name: 'InstructorAssignments' });
      }
    },
    selectCreationType(type) {
      this.assignmentType = type;
      this.currentStep = type;
    },
    // Utility methods for updating assignment data
    updateAssignmentTitle(title) {
      this.assignmentData.title = title;
    },
    updateDescription(desc) {
      this.assignmentData.description = desc;
    },
    updateDueDate(date) {
      this.assignmentData.due_date = date;
    },
    // Methods for from scratch creation
    nextScratchStep() {
      if (this.scratchStep < 2) {
        this.scratchStep++;
      }
    },
    prevScratchStep() {
      if (this.scratchStep > 0) {
        this.scratchStep--;
      }
    },
    addQuestion() {
      this.questions.push({
        content: "",
        marks: 1,
      });
    },
    removeQuestion(index) {
      this.questions.splice(index, 1);
    },
    // Methods for document creation
    async loadMarkingScheme(files) {
      const file = files[0];
      const url = URL.createObjectURL(file);
      this.markingSchemeUrl = url;
      this.isMarkingSchemePdf = file.type === "application/pdf";
      this.currentFiles.markingScheme = file;    
    },
    removeFile(fileType) {
      URL.revokeObjectURL(this.markingSchemeUrl);
      this.markingSchemeUrl = null;
      this.currentFiles.markingScheme = null;
      this.isMarkingSchemePdf = false;
    },
    async goToQuestionViewer() {
      if (!this.assignmentData.title || !this.assignmentData.due_date || this.expressProcessing === null) {
        alert("Please add a title, due date, and express processing selection to continue");
      } else {
        this.markingSchemeLoading = true;
        this.processingProgress = null; // Reset progress
        try {
          const formData = new FormData();
          const dueDate = new Date(this.assignmentData.due_date);
          dueDate.setUTCHours(23, 59, 59, 999);

          formData.append("file", this.currentFiles.markingScheme);
          formData.append("title", this.assignmentData.title);
          formData.append("due", dueDate.toISOString());
          formData.append("expressMode", this.expressProcessing === 'express');
          formData.append("created_by", this.instructorStore.email);
          formData.append("marking_scheme_id", -1); // Using -1 as default for new

          const tenant = getTenant();

          // Extract CSRF token from cookies
          const csrfToken = document.cookie
            .split(";")
            .find(item => item.trim().startsWith("csrf_access_token="))
            ?.split("=")[1];

          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/grader/process-marking-scheme`, {
            method: 'POST',
            credentials: 'include', // ensures cookies are sent
            headers: {
              'X-Tenant': tenant || 'classi',
              ...(csrfToken && { 'X-CSRF-TOKEN': csrfToken })
            },
            body: formData
          });

          const reader = response.body.getReader();
          const decoder = new TextDecoder();

          while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            const lines = decoder.decode(value).split('\n');
            for (const line of lines) {
              if (!line) continue;

              try {
                const data = JSON.parse(line);
                this.processingProgress = data;

                console.log(this.processingProgress);

                if (data.status === 'complete') {
                  // Store the gsutil_url if it exists in the response
                  if (data.gsutil_url) {
                    this.gsutil_url = data.gsutil_url;
                  } else {
                    // For backward compatibility with existing assignments
                    this.markingSchemeId = data.marking_scheme_id;
                  }
                  
                  this.parsedQuestions = {};
                  for (const question of data.questions) {
                    this.parsedQuestions[question.question_number] = question;
                  }
                  this.markingSchemeLoading = false;
                  this.markingSchemeSuccess = true;
                  setTimeout(() => {
                    this.markingSchemeSuccess = false;
                    this.viewQuestions = true;
                  }, 1000);
                }
              } catch (e) {
                console.error('Error parsing line:', e);
              }
            }
          }
        } catch (error) {
          console.error("Error processing marking scheme:", error);
          this.markingSchemeLoading = false;
        }
      }
    },
    saveEdits(question_number) {
      const question = this.parsedQuestions[question_number];

      // No need to call the API since the questions haven't been written to the database yet
      // Just update the local state and exit edit mode
      console.log(`Saving local edits for question ${question_number}`);
      this.editMode = -1;
    },
    // Methods for AI creation
    handleEditQuestion(question, index) {
      console.log('Edit question:', question, 'at index:', index);
    },
    // Create assignment
    async createAssignment(type, aiGeneratedQuestions = null) {
      try {
        // Get the title based on assignment type
        let titleValue = this.assignmentData.title;
        
        let payload = {
          title: titleValue,
          description: this.assignmentData.description || "",
          due_date: this.assignmentData.due_date,
          email: this.instructorStore.email,
          expressMode: this.expressProcessing === 'express'
        };

        // Add questions data if creating from scratch
        if (type === 'scratch') {
          payload.questions = this.questions.map((q, index) => ({
            question_number: index + 1,
            question: q.content,
            marks_possible: Number(q.marks),
            answer: q.answer || "",
            grading_instructions: q.gradingInstructions || ""
          }));
        } 
        // Add questions data if creating from AI
        else if (type === 'ai') {
          payload.questions = aiGeneratedQuestions;
        }
        // Add questions data and gsutil_url if creating from document
        else if (type === 'document') {
          // Convert parsedQuestions object to array
          const questionsArray = Object.values(this.parsedQuestions);
          payload.questions = questionsArray;
          
          // Include the gsutil_url if it exists
          if (this.gsutil_url) {
            payload.gsutil_url = this.gsutil_url;
          }
        }

        const response = await axiosInstance.post("/grader/add-assignment", payload);
        
        if (response.status === 200) {
          // Navigate back to the assignments list
          this.$router.push({ 
            name: "InstructorAssignments",
          });
        } else {
          console.error("Failed to add assignment");
        }
      } catch (error) {
        console.error("Error adding assignment:", error);
      }
    }
  },
  mounted() {
    // Retrieve assignment data from localStorage
    const storedData = localStorage.getItem('pendingAssignment');
    if (storedData) {
      this.assignmentData = JSON.parse(storedData);
    } else {
      // If no data is found, redirect back to assignments page
      this.$router.push({ name: 'InstructorAssignments' });
    }
  }
};
</script>

<style scoped>
.creation-page-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: var(--accent3);
  cursor: pointer;
  margin-right: 1rem;
  transition: all 0.2s ease;
}

.back-button:hover {
  background-color: rgba(var(--primary-rgb), 0.1);
}

.navigation-info {
  font-size: 0.9rem;
  color: var(--accent4);
  margin-bottom: 0.5rem;
}

.page-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
  color: var(--primary);
}

.creation-options-container,
.creation-content-container {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 2rem;
}

.section-title {
  font-size: 1.4rem;
  color: var(--primary);
  margin-top: 0;
  margin-bottom: 2rem;
  text-align: center;
}

.creation-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.creation-option {
  display: flex;
  padding: 1.8rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: white;
}

.creation-option:hover {
  border-color: var(--classi2);
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.08);
}

.option-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
  margin-right: 1.8rem;
  border-radius: 0.8rem;
  background-color: rgba(var(--classi2-rgb), 0.1);
  transition: all 0.3s ease;
}

.creation-option:hover .option-icon {
  background-color: rgba(var(--classi2-rgb), 0.18);
}

.option-icon .material-symbols-outlined {
  font-size: 2.2rem;
  color: var(--classi2);
}

.option-content h3 {
  margin: 0 0 0.6rem 0;
  font-size: 1.3rem;
  color: var(--primary);
}

.option-content p {
  margin: 0;
  color: var(--accent4);
  font-size: 1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .creation-options {
    grid-template-columns: 1fr;
  }
}
</style> 