<template>
    <div :class="['main-container', { collapsed: isCollapsed }]">
        <Sidebar @toggle="handleToggle" :collapsed="isCollapsed" />
        <div :class="['content-container', { collapsed: isCollapsed }]">
            <StudentHeader title="Calendar" />
            <LoadingSpinner class="spinner" v-if="loading" />
            <div v-else class="content">
                <div class="calendar-header">
                    <div class="calendar-title">
                        <div class="view-toggle">
                            <button 
                                :class="['view-btn', { active: viewMode === 'month' }]"
                                @click="viewMode = 'month'"
                            >
                                Month
                            </button>
                            <button 
                                :class="['view-btn', { active: viewMode === 'week' }]"
                                @click="viewMode = 'week'"
                            >
                                Week
                            </button>
                        </div>
                        <div class="navigation">
                            <button @click="changeMonth(-1)" class="icon-btn">
                                <span class="material-symbols-outlined">chevron_left</span>
                            </button>
                            <h2>{{ currentMonthYear }}</h2>
                            <button @click="changeMonth(1)" class="icon-btn">
                                <span class="material-symbols-outlined">chevron_right</span>
                            </button>
                        </div>
                    </div>
                    <div class="calendar-actions">
                        <button @click="goToCurrentMonth" class="action-btn">Today</button>
                        <button @click="addPersonalEvent" class="action-btn">
                            <span class="material-symbols-outlined">add</span>
                            Add Event
                        </button>
                    </div>
                </div>
                <div v-if="error" class="error-message">{{ error }}</div>
                <div v-else class="calendar">
                    <div class="weekdays">
                        <div v-for="day in weekDays" :key="day" class="weekday">{{ day }}</div>
                    </div>
                    <div class="calendar-grid" :class="{ 'week-view': viewMode === 'week' }">
                        <div
                            v-for="day in filteredDays"
                            :key="day.date"
                            class="calendar-day"
                            :class="{
                                'today': isToday(day.date),
                                'other-month': !isCurrentMonth(day.date),
                                'has-events': day.tasks.length > 0 || day.events.length > 0
                            }"
                            @click="selectDate(day.date)"
                        >
                            <div class="day-header">
                                <span class="day-number">{{ formatDate(day.date, "d") }}</span>
                                <span v-if="day.tasks.length > 0 || day.events.length > 0" class="event-indicator"></span>
                            </div>
                            <div class="events-container">
                                <div
                                    v-for="task in day.tasks"
                                    :key="task.title"
                                    class="task-card assignment"
                                    :class="getTaskClass(task)"
                                    @click.stop="goToAssignment(task)"
                                >
                                    <div class="task-time">{{ formatTime(task.due) }}</div>
                                    <div class="task-title">{{ task.title }}</div>
                                    <div class="task-course">{{ task.course }}</div>
                                </div>
                                <div
                                    v-for="event in day.events"
                                    :key="event.title"
                                    class="task-card personal-event"
                                    @click.stop="editEvent(event)"
                                >
                                    <div class="task-time">{{ formatTime(event.time) }}</div>
                                    <div class="task-title">{{ event.title }}</div>
                                    <div class="task-description">{{ event.description }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Event Modal -->
        <div v-if="showEventModal" class="modal-overlay" @click="showEventModal = false">
            <div class="modal-content" @click.stop>
                <div class="modal-header">
                    <h3>{{ editingEvent ? 'Edit Event' : 'Add New Event' }}</h3>
                    <button class="close-btn" @click="showEventModal = false">
                        <span class="material-symbols-outlined">close</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Title</label>
                        <input 
                            type="text" 
                            v-model="newEvent.title" 
                            placeholder="Event title"
                            class="form-input"
                        >
                    </div>
                    <div class="form-group">
                        <label>Date</label>
                        <input 
                            type="date" 
                            v-model="newEvent.date" 
                            class="form-input"
                        >
                    </div>
                    <div class="form-group">
                        <label>Time</label>
                        <input 
                            type="time" 
                            v-model="newEvent.time" 
                            class="form-input"
                        >
                    </div>
                    <div class="form-group">
                        <label>Description</label>
                        <textarea 
                            v-model="newEvent.description" 
                            placeholder="Event description"
                            class="form-input"
                        ></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="cancel-btn" @click="showEventModal = false">Cancel</button>
                    <button class="save-btn" @click="saveEvent">Save Event</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useAssignmentStore } from "@/store/assignmentData";
import { useAlertStore } from "@/store/alertDialog";

export default {
    name: "StudentCalendar",
    components: {
        StudentHeader,
        Sidebar,
        LoadingSpinner,
    },
    data() {
        return {
            currentDate: new Date(),
            days: [],
            loading: false,
            error: null,
            isCollapsed: false,
            viewMode: 'month',
            weekDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            selectedDate: null,
            showEventModal: false,
            editingEvent: null,
            personalEvents: [],
            newEvent: {
                title: '',
                date: null,
                time: '',
                description: ''
            }
        };
    },
    computed: {
        assignmentsStore() {
            return useAssignmentStore();
        },
        alertStore() {
            return useAlertStore();
        },
        assignments() {
            return Object.values(this.assignmentsStore.assignments);
        },
        currentMonthYear() {
            return this.formatDate(this.currentDate, "MMMM yyyy");
        },
        filteredDays() {
            if (this.viewMode === 'week') {
                const startOfWeek = new Date(this.currentDate);
                startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
                return this.days.filter(day => {
                    const dayDate = new Date(day.date);
                    return dayDate >= startOfWeek && dayDate < new Date(startOfWeek.setDate(startOfWeek.getDate() + 7));
                });
            }
            return this.days;
        }
    },
    watch: {
        viewMode() {
            this.generateCalendarDays();
        }
    },
    mounted() {
        // Only fetch assignments if they're not already loaded
        if (Object.keys(this.assignmentsStore.assignments).length === 0) {
            this.initializeCalendar();
        } else {
            this.generateCalendarDays();
        }
    },
    methods: {
        handleToggle(collapsed) {
            this.isCollapsed = !collapsed;
        },
        async initializeCalendar() {
            try {
                this.loading = true;
                await this.assignmentsStore.getAssignments();
                this.generateCalendarDays();
            } catch (error) {
                this.error = "Failed to load assignments";
            } finally {
                this.loading = false;
            }
        },
        generateCalendarDays() {
            const year = this.currentDate.getFullYear();
            const month = this.currentDate.getMonth();
            const daysInMonth = new Date(year, month + 1, 0).getDate();
            
            // Get the first day of the month and adjust for Monday start
            // 0 is Sunday, 1 is Monday, etc., so we need to shift: Sunday becomes 6, other days subtract 1
            let firstDayOfMonth = new Date(year, month, 1).getDay();
            firstDayOfMonth = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

            this.days = [];
            
            if (this.viewMode === 'month') {
                // Add days from previous month
                const prevMonthDays = new Date(year, month, 0).getDate();
                for (let i = firstDayOfMonth - 1; i >= 0; i--) {
                    this.days.push({
                        date: new Date(year, month - 1, prevMonthDays - i),
                        tasks: [],
                        events: []
                    });
                }

                // Add days from current month
                for (let i = 1; i <= daysInMonth; i++) {
                    const date = new Date(year, month, i);
                    const dayTasks = this.assignments.filter((task) => {
                        const taskDate = new Date(task.due);
                        return (
                            taskDate.getFullYear() === year &&
                            taskDate.getMonth() === month &&
                            taskDate.getDate() === i
                        );
                    });
                    const dayEvents = this.personalEvents.filter((event) => {
                        const eventDate = new Date(event.date);
                        return (
                            eventDate.getFullYear() === year &&
                            eventDate.getMonth() === month &&
                            eventDate.getDate() === i
                        );
                    });
                    this.days.push({ date, tasks: dayTasks, events: dayEvents });
                }

                // Add days from next month
                const remainingDays = 42 - this.days.length;
                for (let i = 1; i <= remainingDays; i++) {
                    this.days.push({
                        date: new Date(year, month + 1, i),
                        tasks: [],
                        events: []
                    });
                }
            } else {
                // Week view
                const startOfWeek = new Date(this.currentDate);
                // Get Monday of current week by adjusting date
                const day = startOfWeek.getDay();
                const diff = startOfWeek.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
                startOfWeek.setDate(diff);
                
                for (let i = 0; i < 7; i++) {
                    const date = new Date(startOfWeek);
                    date.setDate(date.getDate() + i);
                    const dayTasks = this.assignments.filter((task) => {
                        const taskDate = new Date(task.due);
                        return (
                            taskDate.getFullYear() === date.getFullYear() &&
                            taskDate.getMonth() === date.getMonth() &&
                            taskDate.getDate() === date.getDate()
                        );
                    });
                    const dayEvents = this.personalEvents.filter((event) => {
                        const eventDate = new Date(event.date);
                        return (
                            eventDate.getFullYear() === date.getFullYear() &&
                            eventDate.getMonth() === date.getMonth() &&
                            eventDate.getDate() === date.getDate()
                        );
                    });
                    this.days.push({ date, tasks: dayTasks, events: dayEvents });
                }
            }
        },
        changeMonth(delta) {
            this.currentDate = new Date(
                this.currentDate.getFullYear(),
                this.currentDate.getMonth() + delta,
                1
            );
            this.generateCalendarDays();
        },
        goToCurrentMonth() {
            this.currentDate = new Date();
            this.generateCalendarDays();
        },
        formatDate(date, format) {
            return new Intl.DateTimeFormat("en-US", {
                weekday: format.includes("EEE") ? "short" : undefined,
                day: format.includes("d") ? "numeric" : undefined,
                month: format.includes("MMMM") ? "long" : undefined,
                year: format.includes("yyyy") ? "numeric" : undefined,
            }).format(new Date(date));
        },
        formatTime(date) {
            return new Intl.DateTimeFormat("en-US", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            }).format(new Date(date));
        },
        isToday(date) {
            const today = new Date();
            return (
                date.getFullYear() === today.getFullYear() &&
                date.getMonth() === today.getMonth() &&
                date.getDate() === today.getDate()
            );
        },
        isCurrentMonth(date) {
            return date.getMonth() === this.currentDate.getMonth();
        },
        getTaskClass(task) {
            const dueDate = new Date(task.due);
            const today = new Date();
            if (dueDate < today) {
                return 'overdue';
            } else if (dueDate.getTime() - today.getTime() < 24 * 60 * 60 * 1000) {
                return 'due-soon';
            }
            return '';
        },
        selectDate(date) {
            // Handle date selection (can be used for adding events or showing details)
            console.log('Selected date:', date);
        },
        addPersonalEvent() {
            this.selectedDate = new Date();
            this.newEvent = {
                title: '',
                date: this.selectedDate,
                time: '',
                description: ''
            };
            this.showEventModal = true;
        },
        editEvent(event) {
            this.editingEvent = event;
            this.newEvent = { ...event };
            this.showEventModal = true;
        },
        async saveEvent() {
            if (!this.newEvent.title || !this.newEvent.date || !this.newEvent.time) {
                this.alertStore.showAlert(
                    "Please fill in all required fields",
                    "OK"
                );
                return;
            }

            if (this.editingEvent) {
                // Update existing event
                const index = this.personalEvents.findIndex(e => e === this.editingEvent);
                if (index !== -1) {
                    this.personalEvents[index] = { ...this.newEvent };
                }
            } else {
                // Add new event
                this.personalEvents.push({ ...this.newEvent });
            }

            this.alertStore.showAlert(
                "Event saved successfully!",
                "OK"
            );
            this.showEventModal = false;
            this.editingEvent = null;
            this.generateCalendarDays();
        },
        refreshCalendar() {
            this.initializeCalendar();
        },
        goToAssignment(assignment) {
            this.$router.push({
                name: "StudentAssignment",
                params: { assignmentId: assignment.assignment_id },
                query: {
                    title: assignment.title,
                    course: assignment.course,
                },
            });
        },
    },
};
</script>

<style scoped>
.main-container {
    display: flex;
    transition: all 0.3s ease-in-out;
    font-family: var(--main-font);
    color: var(--primary);
    margin: 2vh 2vw;
}

.main-container.collapsed .content-container {
    margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
    margin-left: 18vw;
}

.content-container {
    flex: 1;
    transition: margin-left 0.3s ease-in-out;
}

.spinner {
    margin: 20vh auto;
}

.content {
    padding: 1rem;
    background-color: var(--background-color);
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
}

.calendar-title {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.calendar-title h2 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
}

.calendar-actions {
    display: flex;
    gap: 1rem;
}

.icon-btn {
    background: none;
    border: none;
    color: var(--primary);
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.icon-btn:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.action-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: var(--classi2);
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.action-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.error-message {
    color: #dc3545;
    text-align: center;
    padding: 1rem;
    background-color: rgba(220, 53, 69, 0.1);
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.calendar {
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    background-color: var(--classi2);
    color: white;
    padding: 1rem 0;
}

.weekday {
    text-align: center;
    font-weight: 500;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1px;
    background-color: #f0f0f0;
}

.calendar-grid.week-view {
    grid-template-columns: repeat(7, 1fr);
}

.calendar-day {
    background-color: white;
    min-height: 120px;
    padding: 0.5rem;
    position: relative;
    transition: all 0.3s ease;
}

.calendar-day:hover {
    background-color: #f8f9fa;
}

.calendar-day.other-month {
    background-color: #f8f9fa;
    color: #adb5bd;
}

.calendar-day.today {
    background-color: #e8f5e9;
}

.day-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.day-number {
    font-weight: 500;
}

.event-indicator {
    width: 6px;
    height: 6px;
    background-color: var(--classi2);
    border-radius: 50%;
}

.events-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
    max-height: calc(100% - 2rem);
}

.task-card {
    background-color: var(--classi2);
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.8rem;
}

.task-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.task-card.overdue {
    background-color: #dc3545;
}

.task-card.due-soon {
    background-color: #ffc107;
}

.task-time {
    font-size: 0.7rem;
    opacity: 0.8;
    margin-bottom: 0.25rem;
}

.task-title {
    font-weight: 500;
    margin-bottom: 0.25rem;
}

.task-course {
    font-size: 0.7rem;
    opacity: 0.8;
    font-style: italic;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    border-radius: 1rem;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid #eee;
}

.modal-header h3 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 500;
}

.close-btn {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.close-btn:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.modal-body {
    padding: 1.5rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #666;
    font-size: 0.9rem;
}

.form-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.form-input:focus {
    outline: none;
    border-color: var(--classi2);
    box-shadow: 0 0 0 2px rgba(var(--classi2-rgb), 0.1);
}

textarea.form-input {
    min-height: 100px;
    resize: vertical;
}

.modal-footer {
    padding: 1.5rem;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.cancel-btn {
    padding: 0.75rem 1.5rem;
    background-color: #f5f5f5;
    color: #666;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.save-btn {
    padding: 0.75rem 1.5rem;
    background-color: var(--classi2);
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cancel-btn:hover, .save-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Week View Styles */
.calendar-grid.week-view {
    grid-template-columns: repeat(7, 1fr);
}

.calendar-grid.week-view .calendar-day {
    min-height: 200px;
}

@media (max-width: 768px) {
    .calendar-header {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }

    .calendar-actions {
        width: 100%;
        justify-content: space-between;
    }

    .action-btn {
        flex: 1;
        justify-content: center;
    }

    .calendar-grid.week-view .calendar-day {
        min-height: 150px;
    }
}

.view-toggle {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.view-btn {
    padding: 0.5rem 1rem;
    border: 1px solid var(--classi2);
    background: transparent;
    color: var(--classi2);
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.view-btn.active {
    background-color: var(--classi2);
    color: white;
}

.navigation {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.task-card.assignment {
    background-color: var(--classi2);
}

.task-card.personal-event {
    background-color: #6c757d;
}

.task-description {
    font-size: 0.7rem;
    opacity: 0.8;
    font-style: italic;
}
</style>