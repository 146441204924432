// A centralized store for tenant information
let currentTenant = null;

// Try to restore tenant from localStorage if available
const initTenant = () => {
  try {

    // Try instructor data first
    const instructorData = localStorage.getItem('instructorData');
    if (instructorData) {
      const parsed = JSON.parse(instructorData);
      if (parsed.email && parsed.email.includes('@')) {
        currentTenant = parsed.email.split('@')[1].split('.')[0];
        return;
      }
    }

    // Try student data next
    const studentData = localStorage.getItem('studentData');
    if (studentData) {
      const parsed = JSON.parse(studentData);
      if (parsed.email && parsed.email.includes('@')) {
        currentTenant = parsed.email.split('@')[1].split('.')[0];
        return;
      }
    }
  
  } catch (e) {
    console.error("Error initializing tenant", e);
  }
};

// Initialize on module load
initTenant();

export const setTenantFromEmail = (email) => {
  if (email && email.includes('@')) {
    currentTenant = email.split('@')[1].split('.')[0];
  }
};

export const getTenant = () => currentTenant;

export const clearTenant = () => {
  currentTenant = null;
}; 