import { defineStore } from "pinia";
import axiosInstance from "@/utils/axiosInstance";
import { clearTenant } from "@/utils/tenantStore";

export const useInstructorStore = defineStore("instructorData", {
  state: () => ({
    instructorId: -1,
    title: "",
    email: "",
    firstName: "",
    lastName: "",
    isAuthenticated: false,    
  }),
  actions: {
    async checkAuth() {
      try {
        const response = await axiosInstance.get("/auth/validate-token");
        if (response.status === 200 && response.data.role === "instructor") {
          this.isAuthenticated = true;
          // If we have a valid token but no instructor data, fetch it
          if (this.instructorId === -1) {
            await this.getInstructorData();
          }
          return true;
        } else {
          // We got a response but not for an instructor role
          this.isAuthenticated = false;
          return false;
        }
      } catch (error) {
        console.error("Authentication check failed:", error);
        this.isAuthenticated = false;
        
        // Clear state on auth failure
        this.email = "";
        this.firstName = "";
        this.title = "";
        
        // Clear localStorage
        localStorage.removeItem("instructorData");
        
        // Clear the Pinia store state
        this.$reset();
        
        return false;
      }
    },
    async logout() {
      try {
        await axiosInstance.post("/auth/logout");
      } catch (error) {
        console.error("Error during logout:", error);
      } finally {
        // Clear tenant along with other cleanup
        clearTenant();
        
        // Always clear local state and storage, even if server request fails
        this.isAuthenticated = false;
        this.email = "";
        this.firstName = "";
        this.title = "";
        
        // Clear localStorage
        localStorage.removeItem("instructorData");
        
        // Clear the Pinia store state
        this.$reset();
      }
    },
    async getInstructorData() {
      try {
        const studentResponse = await axiosInstance.get(
          "/instructors/get-instructor"
        );
        const data = studentResponse.data;

        this.instructorId = data.instructorId;
        this.title = data.title;
        this.email = data.email;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
      } catch (error) {
        console.log("User not found", error);
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [{ storage: localStorage }],
  },
});
