<template>
    <div class="assignment-container">
        <!-- Show loading spinner while data is loading -->
        <div v-if="loading" class="loading-container">
            <LoadingSpinner />
        </div>

        <!-- Show assignment content once loaded -->
        <div v-else>
            <div class="title-row">
                <div class="back-button-container">
                    <div class="back-button" @click="goBack">
                        <span class="material-symbols-outlined">
                            arrow_back
                        </span>
                    </div>
                </div>
                <!-- <div class="title-content">
                    <p class="title">
                        {{ assignment.title }}
                    </p>
                    <p @click="goToCourse" class="course">
                        <i>{{ assignment.course }}</i>
                    </p>
                </div> -->

                <div class="progress-container">
                    <div class="progress-bar">
                        <div v-for="question in assignmentQuestions" :key="question.question_number" :class="[
                            completedQuestions[question.question_number]
                                ? 'complete'
                                : 'incomplete',
                            'progress-segment',
                        ]">
                        </div>
                    </div>
                    <div class="progress-text">
                        Progress ({{
                            Object.values(this.completedQuestions).filter(
                                (value) => value === true
                            ).length
                        }}/{{ assignmentQuestions.length }})
                    </div>
                </div>
                <div class="submit-div">
                    <Tooltip text="This assignment is past due or has been graded" position="left"
                        customStyles="padding: 1vh 1vw; width: 12vw; text-align:center; font-size: 0.8vw;"
                        :show="pastDueOrGraded">
                        <button @click="submitAssignment" @mouseover="submitHover" :class="[
                            'submit-button',
                            { 'no-submit': pastDueOrGraded },
                        ]">
                            Submit
                        </button>
                    </Tooltip>
                </div>
            </div>

            <div class="title-grade">
                <div class="due-date-container">
                    <div class="grade-value">
                        {{ formatDate(assignment.due || assignment.due_date) }}
                    </div>
                    <p class="due-date">
                        <i>Due</i>
                    </p>
                </div>
                <div class="title-content">
                    <p class="title">
                        {{ assignment.title }}
                    </p>
                    <p @click="goToCourse" class="course">
                        <i>{{ assignment.course }}</i>
                    </p>
                </div>
                <div class="grade-container">
                    <div class="grade-value">
                        <span v-if="totalGrades.totalObtained">
                            {{ totalGrades.totalObtained }}
                        </span>
                        /
                        {{ totalGrades.totalPossible }}
                    </div>
                    <div class="grade-label"><i>Grade</i></div>
                </div>
            </div>

            <div class="questions-scroll-container">
                <div v-for="question in assignmentQuestions" :key="question.questionId" class="question-container"
                    ref="questionRef">
                    <div class="question-header">
                        <div class="section-break"></div>
                        <div class="question-header-content">
                            <p class="question-number">
                                Question {{ question.question_number }}
                            </p>
                            <div class="question-grade">
                                <span v-if="question.graded" class="grade-display graded">
                                    {{ formatFloat(question.grade) }}/{{ question.marks_possible }}
                                </span>
                                <span v-else class="grade-display">
                                    {{ question.marks_possible }} {{ question.marks_possible === 1 ? 'mark' : 'marks' }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="question-content">
                        {{ question.question_content }}
                    </div>

                    <div class="answer-header">
                        <div class="section-break"></div>
                        <p class="answer-title">Answer</p>
                    </div>
                    <Tiptap 
                        :editable="!question.graded" 
                        :content="question" 
                        class="answer-editor"
                        :ref="'tiptap-' + question.question_number"
                        @click="scrollToEditor" 
                        @update:content="
                            (content) =>
                                handleContentUpdate(
                                    content,
                                    question.question_number,
                                    question.question_id
                                )
                        " 
                    />

                    <div v-if="question.graded && question.feedback" class="feedback-section">
                        <div class="feedback-content">
                            <p class="feedback-title">Feedback <span v-if="question.graded"
                                    class="material-symbols-outlined aura-button" @click="askAura()">
                                    psychology
                                </span></p>
                            {{ question.feedback }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CustomAlert @ok="handleOk" @cancel="handleCancel" />
    </div>
</template>
<script>
import Tiptap from "@/components/Tiptap.vue";
import axiosInstance from "@/utils/axiosInstance";
import CustomAlert from "@/components/CustomAlert.vue";
import { useAlertStore } from "@/store/alertDialog";
import { useAssignmentStore } from "@/store/assignmentData";
import Tooltip from "@/components/Tooltip.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { generateHTML } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'


export default {
    data() {
        return {
            assignment: {},
            questionsResponse: {},
            completedQuestions: {},
            isAlertVisible: false,
            loading: true, // Add loading state
        };
    },
    components: {
        Tiptap,
        CustomAlert,
        Tooltip,
        LoadingSpinner,
    },
    computed: {
        alertStore() {
            return useAlertStore();
        },
        assignmentStore() {
            return useAssignmentStore();
        },
        pastDueOrGraded() {
            // Handle both date formats (due vs due_date)
            const dueDate = this.assignment.due || this.assignment.due_date;
            return (
                new Date(dueDate) < new Date() ||
                this.assignment.status === "Graded"
            );
        },
        assignmentQuestions() {
            return Object.values(this.questionsResponse);
        },
        totalGrades() {
            let possible = 0;
            let obtained = 0;
            for (const question of this.assignmentQuestions) {
                possible += parseFloat(question.marks_possible);
                obtained += parseFloat(question.grade || 0);
            }

            if (!obtained) {
                obtained = "--";
            } else {
                obtained = obtained.toFixed(2);
            }

            return {
                totalPossible: possible.toFixed(2),
                totalObtained: obtained,
            };
        },
    },
    props: ["assignmentId"],
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        handleCancel() {
            console.log("Cancel");
        },
        handleOk() {
            console.log("Ok");
        },
        askAura() {
            // Get the current question number from the feedback section
            const currentQuestion = this.assignmentQuestions.find(q => q.graded && q.feedback);
            if (!currentQuestion) {
                this.alertStore.showAlert("No graded questions found", "Close", "");
                return;
            }

            // Get the Tiptap component reference
            const tiptapRef = this.$refs[`tiptap-${currentQuestion.question_number}`];
            const responseHtml = tiptapRef[0].editor.getHTML();

            // Format the message for Aura
            const message = `Hi AURA. I received feedback on my ${this.assignment.course} assignment titled ${this.assignment.title}.

Question: ${currentQuestion.question_content}

My answer: ${responseHtml}

Grade received: ${currentQuestion.grade}/${currentQuestion.marks_possible}

Teacher's feedback: ${currentQuestion.feedback}

Can you help me understand this feedback and provide me with some practice questions to improve?`;

            // Navigate to IsolatedChat with the pre-formatted message and create new chat
            this.$router.push({
                name: 'IsolatedChat',
                query: {
                    message: encodeURIComponent(message),
                    subject: this.assignment.course,
                }
            });
        },
        scrollToEditor(refName) {
            const element = this.$refs.questionRef.$el;
            if (element) {
                const elementRect = element.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.scrollY;
                const offsetPosition = absoluteElementTop - 2000;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        },
        formatFloat(value) {
            value = parseFloat(value);

            // Check if the value is an integer
            if (Number.isInteger(value)) {
                return value.toString();
            }

            // Convert to a fixed number of decimals, up to 2
            let formattedValue = value.toFixed(2);

            // Remove unnecessary trailing zeros
            if (formattedValue.endsWith("0")) {
                formattedValue = parseFloat(formattedValue).toFixed(1);
            }

            return formattedValue;
        },
        async getAssignmentQuestions() {
            const assignmentQuestionsResponse = await axiosInstance.post(
                "/students/get-assignment-questions",
                {
                    assignment_id: this.assignmentId,
                }
            );
            this.assignmentQuestions = assignmentQuestionsResponse.data;

            this.assignmentQuestions.forEach((question, index) => {
                if (question.response_content !== null) {
                    this.completedQuestions[question.question_number] = true;
                }
            });
        },
        async handleContentUpdate(jsonContent, questionNumber, questionId) {
            // If past due or graded no need to update responses
            if (this.pastDueOrGraded) {
                return;
            }

            // Update completion status locally for the progress bar
            const isCompleted = jsonContent &&
                (typeof jsonContent === 'object' || jsonContent.trim() !== '');

            // Only update if the completion status has changed
            if (this.completedQuestions[questionNumber] !== isCompleted) {
                this.completedQuestions[questionNumber] = isCompleted;
            }

            // Send update to server
            this.assignmentStore.updateResponseContent(
                this.assignmentId,
                jsonContent,
                questionNumber,
                questionId
            );
        },
        goToCourse() {
            console.log(this.assignment);
            this.$router.push({
                name: "StudentCourse",
                params: { courseCode: this.assignment.course_code },
            });
        },
        formatDate(dateString) {
            if (!dateString) return '';
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        },
        async submitAssignment() {
            // If already past due or graded, don't allow submission
            if (this.pastDueOrGraded) {
                return;
            }

            const complete = Object.values(this.completedQuestions).filter(
                (value) => value === true
            ).length;
            const incomplete = this.assignmentQuestions.length - complete;

            if (incomplete !== 0) {
                this.alertStore.showAlert(
                    `You have ${incomplete} incomplete questions. Are you sure you want to submit?`,
                    "Yes",
                    "No",
                    this.confirmSubmission
                );
            } else {
                this.confirmSubmission();
            }
        },
        async confirmSubmission() {
            try {
                // Show loading state
                this.alertStore.showAlert(
                    "Submitting your assignment...",
                    "",
                    ""
                );

                // Submit all responses as final
                const response = await axiosInstance.post(
                    "/students/submit-assignment",
                    {
                        assignment_id: this.assignmentId,
                    }
                );

                // Show success message
                this.alertStore.showAlert(
                    "Assignment submitted successfully!",
                    "Close",
                    "",
                    () => {
                        // Refresh assignment data
                        this.assignmentStore.getAssignments();
                        // Redirect to assignments page
                        this.$router.push({ name: "StudentAssignments" });
                    }
                );
            } catch (error) {
                console.error("Error submitting assignment:", error);
                this.alertStore.showAlert(
                    "There was an error submitting your assignment. Please try again.",
                    "Close",
                    ""
                );
            }
        },
        submitHover() {
            console.log(this.pastDue);
        },
    },
    async mounted() {
        try {
            this.loading = true;

            // First get the assignment data
            this.assignment = this.assignmentStore.assignments[this.assignmentId];
            if (!this.assignment) {
                throw new Error("Assignment not found");
            }

            // Then fetch the questions - this is what takes time
            this.questionsResponse = await this.assignmentStore.getAssignmentQuestions(
                this.assignmentId
            );

            // Initialize the completedQuestions object
            this.assignmentQuestions.forEach((question) => {
                this.completedQuestions[question.question_number] =
                    !!question.response_content; // Convert to boolean
            });

        } catch (error) {
            console.error("Error loading assignment:", error);
            this.alertStore.showAlert(
                "There was an error loading this assignment. Please try again.",
                "Close",
                "",
                this.goBack
            );
        } finally {
            // Ensure loading state is cleared even if there was an error
            this.loading = false;
        }
    },
};
</script>
<style scoped>
.assignment-container {
    padding-bottom: 5vh;
    max-width: 100%;
}

.title-row {
    display: flex;
    justify-content: space-between;
    height: 6vh;
    margin-top: 5vh;
    align-items: center;
}

.material-symbols-outlined {
    font-size: 1.3vw;
}

.back-button-container {
    flex: 2;
}

.back-button {
    display: flex;
    height: 6vh;
    width: 6vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: var(--classi2);
    border: 0.2rem solid var(--classi2);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
}

.back-button:hover {
    background-color: var(--classi2);
    color: white;
}

.course {
    cursor: pointer;
    color: var(--accent4);
    font-size: 0.9vw;
}

.course:hover {
    text-decoration: underline;
}

.progress-container {
    flex: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 3rem;
}

.progress-bar {
    display: flex;
    margin: 0 1vw;
    height: 0.6vh;
    width: 80%;
    border-radius: 2rem;
    overflow: hidden;
    background-color: var(--secondary);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.progress-segment {
    flex: 1;
    margin: 0 2px;
    transition: all 0.3s ease;
}

.complete {
    background-color: var(--classi2);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) inset;
}

.incomplete {
    background-color: rgba(0, 0, 0, 0.1);
}

.progress-text {
    font-size: 0.9vw;
    width: fit-content;
    margin-top: 0.5vh;
    color: var(--primary);
}

.submit-div {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    height: inherit;
}

.submit-button {
    cursor: pointer;
    width: 7vw;
    height: 5vh;
    border: none;
    border-radius: 1rem;
    background-color: var(--classi2);
    color: white;
    transition: all 0.3s ease;
    font-size: 0.9vw;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-submit {
    cursor: default;
    opacity: 0.5;
    background-color: var(--secondary);
    color: var(--primary);
    border: 0.1rem solid var(--primary);
}

.submit-button:hover:not(.no-submit) {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.no-submit:hover {
    pointer-events: none;
    transform: none;
    box-shadow: var(--box-shadow);
}

.title {
    font-size: 1.5vw;
}

.title-grade {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 0.1rem solid var(--primary);
    margin-top: 5vh;
    padding-bottom: 1vh;
}

.due-date-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.due-date {
    color: var(--accent4);
    font-size: 0.9vw;
    margin: 0;
}

.title-content {
    flex: 2;
    text-align: center;
}

.grade-container {
    flex: 1;
    text-align: right;
}

.grade-value {
    font-size: 1.3vw;
}

.grade-label {
    text-align: right;
    color: var(--accent4);
    font-size: 0.9vw;
}

.questions-scroll-container {
    margin-top: 2vh;
    padding: 0 0.5vw;
    width: 100%;
}

.question-container {
    background-color: var(--secondary);
    border-radius: 2rem;
    box-shadow: var(--box-shadow);
    margin-bottom: 3vh;
    padding: 2vh 2vw;
    overflow: hidden;
    transition: all 0.3s ease;
}

.question-header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1.5vh;
}

.section-break {
    width: 5%;
    height: 0.2vh;
    background-color: var(--classi2);
    margin-right: 1vw;
}

.question-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--classi2);
}

.question-number {
    font-size: 1.3vw;
    color: var(--classi2);
    margin: 0;
}

.question-grade {
    display: flex;
    align-items: center;
    gap: 0.5vw;
}

.grade-display {
    padding: 0.5vh 1vw;
    background-color: var(--secondary);
    color: var(--classi2);
    border-radius: 1rem;
    font-size: 1.2vw;
    text-align: center;
}

.graded {
    background-color: var(--classi2);
    color: white;
}

.question-content {
    font-size: 1.1vw;
    color: var(--primary);
    padding: 1.5vh 0;
    display: flex;
    align-items: center;
}

.aura-button {
    text-align: center;
    margin-left: 0.5vw;
    padding: 1vh;
    border-radius: 50%;
    background-color: var(--classi2);
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1vw;
}

.aura-button:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.feedback-section {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 1rem;
    padding: 1.5vh 1vw;
    margin: 1vh 0 2vh 0;
}

.feedback-header {
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
}

.feedback-title {
    font-size: 1.1vw;
    color: var(--classi2);
    margin: 0;
}

.feedback-content {
    font-size: 1vw;
    color: var(--primary);
    line-height: 1.5;
    padding: 0.5vh 0 0.5vh 2vw;
    border-left: 3px solid var(--classi2);
}

.answer-header {
    display: flex;
    align-items: center;
    margin: 2vh 0 1vh 0;
}

.answer-title {
    font-size: 1.1vw;
    color: var(--classi2);
    margin: 0;
}

.answer-editor {
    background-color: white;
    border-radius: 1rem;
    padding: 1vh;
    margin-bottom: 1vh;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Add loading container styles */
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    width: 100%;
}
</style>
