<template>
  <div v-if="isVisible" class="modal-overlay">
    <div class="modal-content">
      <div class="step-container">
        <span
          class="material-symbols-outlined close-button"
          @click="closeModal"
        >
          close
        </span>        
        <div v-if="!viewQuestions" class="uploader">
          <p v-if="!markingSchemeUrl" class="step-heading">
            Upload a marking scheme
          </p>
          <p v-if="!markingSchemeUrl" class="step-description">
            Upload a marking scheme in PDF form that will be used to guide the
            autograder. This could be typed or handwritten (scanned).
          </p>
          <p v-if="markingSchemeUrl" class="step-heading">Add a title</p>
          <input
            v-if="markingSchemeUrl"
            v-model="markingSchemeTitle"
            type="text"
            placeholder="Add assignment title here..."
            class="title-input"
          />
          <FileUploader
            v-if="!markingSchemeUrl"
            @files-selected="(files) => loadMarkingScheme(files)"
          />
          <FileDisplay
            v-else
            :fileUrl="markingSchemeUrl"
            :isPDF="isMarkingSchemePDF"
            :loading="markingSchemeLoading"
            :success="markingSchemeSuccess"
            fileType="Marking Scheme"
            @remove="removeFile()"
            @continue="goToQuestionViewer()"
          />
        </div>
        <div v-else class="questions-viewer">
          <p class="step-heading">Marking Scheme Questions</p>
          <p class="step-description">
            The following questions were parsed from the marking scheme. Please
            review and make any corrections if needed.
          </p>
          <div class="viewer-header">
            <p class="total-marks">Total Marks: {{ totalMarks }}</p>
            <div
              class="continue-button"
              @click="
                $emit('nextStep', markingSchemeId, markingSchemeTitle);
                closeModal();
              "
            >
              <span class="material-symbols-outlined">arrow_forward</span>
            </div>
          </div>
          <div
            v-for="(question, index) in parsedQuestions"
            class="question-container"
          >
            <div class="question-info" v-if="index !== 'grading'">
              <div class="question-header">
                <div class="section-break"></div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    color: var(--classi2);
                  "
                >
                  <p class="graded-question-section">
                    Question {{ question.question_number }}
                  </p>
                  <div class="question-grade">
                    Marks:
                    <input
                      v-model.number="question.marks_possible"
                      type="number"
                      :min="0"
                      step="0.25"
                      @change="saveEdits(question.question_number)"
                    />
                  </div>
                </div>
              </div>
              <TeacherTiptap
                class="wysiwyg"
                v-model:content="question.question"
                :editable="editMode == question.question_number"
              />
              <div class="question-header">
                <div class="section-break"></div>
                <p class="graded-question-section">Correct Answer</p>
              </div>
              <TeacherTiptap
                class="wysiwyg"
                v-model:content="question.answer"
                :editable="editMode == question.question_number"
              />
              <div
                v-if="
                  question.grading_instructions &&
                  question.grading_instructions !== ''
                "
              >
                <div class="question-header">
                  <div class="section-break"></div>
                  <p class="graded-question-section">Grading Instructions</p>
                </div>
                <TeacherTiptap
                  class="wysiwyg"
                  v-model:content="question.grading_instructions"
                  :editable="editMode == question.question_number"
                />

                <div
                  v-if="editMode == question.question_number"
                  style="display: flex; justify-content: space-around"
                >
                  <div
                    class="edit-button"
                    @click="saveEdits(question.question_number)"
                  >
                    Save
                  </div>
                </div>
                <div
                  v-else
                  class="edit-button"
                  @click="editMode = question.question_number"
                >
                  Click here to edit
                  <span class="material-symbols-outlined edit-icon">
                    edit
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useInstructorStore } from "@/store/instructorData";
import axiosInstance from "@/utils/axiosInstance";
import FileUploader from "@/components/FileUploader.vue";
import FileDisplay from "@/components/FileDisplay.vue";
import TeacherTiptap from "@/components/TeacherTiptap.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "MarkingSchemeModal",
  components: {
    FileUploader,
    FileDisplay,
    TeacherTiptap,
    LoadingSpinner,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.isVisible) {
      this.getPreviousAssignments();
    }
  },
  data() {
    return {
      markingSchemeUrl: null,
      isMarkingSchemePDF: false,
      previousAssignments: [],
      markingSchemeLoading: false,
      markingSchemeSuccess: false,
      markingSchemeId: -1,
      markingSchemeTitle: "",
      currentFiles: {
        markingScheme: null,
      },
      parsedQuestions: {},
      viewQuestions: false,
      editMode: -1,
    };
  },
  computed: {
    instructorStore() {
      return useInstructorStore();
    },
    totalMarks() {
      var total = 0;
      for (const question in this.parsedQuestions) {
        total += this.parsedQuestions[question]["marks_possible"];
      }
      return total;
    },
  },
  methods: {
    async getPreviousAssignments() {
      try {
        const response = await axiosInstance.post(
          "/grader/previous-assignments",
          {
            email: this.instructorStore.email,
          }
        );
        this.previousAssignments = response.data;
      } catch (error) {
        console.error("Error updating response:", error);
      }
    },
    loadMarkingScheme(files) {
      const file = files[0];
      const isPDF = file.type === "application/pdf";
      const url = URL.createObjectURL(file);
      this.markingSchemeUrl = url;
      this.isMarkingSchemePDF = isPDF;
      this.currentFiles.markingScheme = file;
    },
    removeFile() {
      URL.revokeObjectURL(this.markingSchemeUrl);
      this.markingSchemeUrl = null;
      this.currentFiles.markingScheme = null;
      this.isMarkingSchemePDF = false;
    },
    async goToQuestionViewer() {
      if (!this.markingSchemeTitle) {
        alert("Please add a title to continue");
      } else {
        await this.uploadMarkingScheme(this.currentFiles.markingScheme);
      }
    },
    async uploadMarkingScheme(file) {
      this.markingSchemeLoading = true;
      try {
        const formData = new FormData();

        formData.append("file", file);
        formData.append("title", this.markingSchemeTitle);
        formData.append("created_by", this.instructorStore.email);
        formData.append("marking_scheme_id", this.markingSchemeId);

        const response = await axiosInstance.post(
          "/grader/process-marking-scheme",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        const data = response.data;
        this.markingSchemeId = data["marking_scheme_id"];

        for (const question of data["questions"]) {
          this.parsedQuestions[question.question_number] = question;
        }

        console.log(this.parsedQuestions);

        this.markingSchemeLoading = false;
        this.markingSchemeSuccess = true;

        setTimeout(() => {
          this.markingSchemeSuccess = false;
        }, 1000);

        this.viewQuestions = true;
      } catch (error) {
        console.error(`Error processing marking scheme:`, error);
        this.markingSchemeLoading = false;
      }
    },
    async saveEdits(question_number) {
      const question = this.parsedQuestions[question_number];

      try {
        const response = await axiosInstance.post("/grader/update-question", {
          question_id: question.question_id,
          question_number: question.question_number,
          question: question.question,
          answer: question.answer,
          grading_instructions: question.grading_instructions,
          marks_possible: question.marks_possible,
        });
        console.log(response.data);
        this.editMode = -1;
      } catch (error) {
        console.error("Error updating question:", error);
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: var(--accent3);
  width: 60vw;
  padding: 2vw;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  margin-bottom: 2vh;
}

.close-button {
  float: right;
  margin-right: 0;
  cursor: pointer;
}

.close-button:hover {
  color: var(--classi2);
  transition: ease all 0.3s;
}

.step-container {
  overflow-y: auto;
  width: 100%;
  height: 80vh; /* Adjust height according to need */
}

.uploader,
.questions-viewer {
  flex: 1;
  max-width: 50vw;
  margin: auto;
  margin-top: 2vh;
}

.step-heading {
  font-size: 1.5vw;
  margin-bottom: 1vw;
}

.step-description {
  font-size: 1.1vw;
  margin-bottom: 2vw;
}

.title-input {
  font-size: 1.1vw;
  margin-bottom: 2vw;
  width: 100%;
  padding: 0.5vw;
  border: 0.1rem solid var(--primary);
  border-radius: 0.5rem;
}

.submissions-list {
  width: 15vw;
  height: 80vh;
  border-right: 0.1rem solid var(--classi2);
  margin-right: 2vw;
  overflow-y: auto;
}

.submissions-list-heading {
  font-size: 1.4vw;
  padding: 1vw;
  border-bottom: 0.1rem solid var(--classi2);
}

.submission-item {
  word-break: break-all;
  padding: 1vw;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submission-item:hover {
  background-color: var(--classi2);
  color: white;
  transition: ease all 0.3s;
}

.submission-item.active {
  background-color: var(--classi2);
  color: white;
}

.viewer-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
}

.total-marks {
  font-size: 1.3vw;
  color: var(--classi2);
}

.continue-button {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  color: var(--classi2);
  border: 0.1rem solid var(--classi2);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.continue-button:hover {
  background-color: var(--classi2);
  color: white;
}

.question-container {
  margin-bottom: 2vw;
  background-color: var(--secondary);
  /* border: 0.1rem solid var(--classi2); */
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

.question-info {
  flex: 3;
  padding: 1.5vw 1vw;
  font-size: 1vw;
}

.question-feedback {
  flex: 1;
  background-color: var(--classi2);
  color: white;
  padding: 1.5vw 1vw;
  font-size: 1vw;
}

.question-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}

.section-break {
  width: 5%;
  height: 0.2vh;
  background-color: var(--classi2);
  margin-right: 1vw;
}

.graded-question-section {
  font-size: 1.3vw;
  color: var(--classi2);
}

.wysiwyg {
  margin-bottom: 2vh;
}

input[type="number"] {
  width: 5vw;
  font-size: 1.3vw;
  text-align: center;
  background-color: transparent;
  color: var(--classi2);
  border: 1px solid var(--classi2);
  border-radius: 0.5rem;
}

.edit-button {
  float: right;
  padding: 1vh 1vw;
  margin-bottom: 1vw;
  display: flex;
  align-items: center;
  font-size: 1.1vw;
  background-color: var(--classi2);
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
}

.edit-button:hover {
  opacity: 0.6;
  transition: ease all 0.3s;
}

.edit-icon {
  padding-left: 0.5vw;
}
</style>
