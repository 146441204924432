<template>
  <div class="scratch-form">
    <div class="step-indicators">
      <div v-for="step in 3" :key="step" :class="['step-indicator', { active: currentStep === step - 1 }]"></div>
    </div>
    <div class="sliding-menu-container">
      <div class="sliding-menu" :style="{ transform: `translateX(-${currentStep * 33.33}%)` }">
        <!-- Step 1: Assignment Details -->
        <div class="step">
          <div class="step-content">
            <p class="step-heading">Assignment Details</p>
            <div class="form-group">
              <p class="step-field">Title</p>
              <input 
                type="text" 
                id="title" 
                :value="localTitle" 
                @input="updateTitle($event.target.value)"
                placeholder="E.g. Physics Homework #3"
                class="input-field"
                required
              />
            </div>
            
            <div class="form-group">
              <p class="step-field">Due Date</p>
              <input 
                type="date" 
                id="due-date" 
                :value="localDueDate" 
                @input="updateDueDate($event.target.value)"
                class="date-input input-field"
                required
              />
            </div>

            <div class="form-group">
              <p class="step-field">Subject & Textbooks</p>
              <div class="subject-picker-container">
                <SubjectTextbookPicker
                  :textbooks="availableTextbooks"
                  :initial-subject="subject"
                  :initial-checked-ids="selectedTextbookIds"
                  @subject-textbooks-changed="handleSubjectTextbooksChanged"
                />
              </div>
            </div>

            <div class="nav-buttons" style="justify-content: flex-end">
              <button @click="nextStep" class="next-btn" :disabled="!canProceed">
                Next
                <span class="material-symbols-outlined">arrow_forward</span>
              </button>
            </div>
          </div>
        </div>

        <!-- Step 2: Describe Assignment -->
        <div class="step">
          <div class="step-content">
            <p class="step-heading">Describe Your Assignment</p>
            
            <div class="form-group">
              <p class="step-field">Assignment Description (Optional)</p>
              <textarea 
                id="description" 
                v-model="description" 
                placeholder="E.g. Syllabus: chapters 1-3, Extra credit for question 5"
                class="input-field description-area"
              ></textarea>
            </div>

            <div class="form-group">
              <p class="step-field">Tell the AI what to create</p>
              <textarea 
                id="ai-prompt" 
                v-model="aiPrompt" 
                placeholder="Describe the assignment you want to create (e.g., '5 questions on quadratic equations, 10 marks each' or 'Create a mix of multiple choice and short answer questions about cellular respiration')"
                class="input-field ai-prompt-area"
              ></textarea>
            </div>

            <div class="nav-buttons">
              <button @click="prevStep" class="prev-btn">
                <span class="material-symbols-outlined">arrow_back</span>
                Previous
              </button>
              <button @click="generateAssignment" class="next-btn" :disabled="!aiPrompt.trim()">
                Generate Assignment
                <span class="material-symbols-outlined">auto_awesome</span>
              </button>
            </div>
          </div>
        </div>

        <!-- Step 3: Review Generated Assignment -->
        <div class="step">
          <div class="step-content">
            <p class="step-heading">Review Generated Assignment</p>
            
            <div v-if="isGenerating" class="loading-container">
              <div class="loading-animation">
                <div class="loading-circle"></div>
                <div class="loading-circle"></div>
                <div class="loading-circle"></div>
              </div>
              <p class="loading-text">Generating your assignment...</p>
              <p class="loading-subtext">This may take a minute. We're crafting high-quality questions based on your requirements.</p>
            </div>
            
            <div v-else-if="generationError" class="error-container">
              <div class="error-icon">
                <span class="material-symbols-outlined">error</span>
              </div>
              <p class="error-text">{{ generationError }}</p>
              <button @click="generateAssignment" class="retry-btn">
                <span class="material-symbols-outlined">refresh</span>
                Try Again
              </button>
            </div>
            
            <div v-else class="preview-container">
              <AssignmentPreview
                :title="localTitle"
                :dueDate="localDueDate"
                :questions="formattedQuestions"
                :isFromDocument="false"
                :isEditable="false"
                :showNavSidebar="true"
                :showHeader="true"
                @update:questions="updateQuestions"
              />
            </div>
            
            <div class="nav-buttons">
              <button @click="prevStep" class="prev-btn">
                <span class="material-symbols-outlined">arrow_back</span>
                Previous
              </button>
              <button @click="createAssignment" class="create-btn" :disabled="generatedQuestions.length === 0">
                Create Assignment
                <span class="material-symbols-outlined">check</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Full-screen loading overlay -->
    <div v-if="isGenerating && currentStep === 1" class="loading-overlay">
      <div class="loading-content">
        <div class="loading-spinner">
          <div class="spinner-circle"></div>
          <div class="spinner-circle-inner"></div>
        </div>
        <h3 class="loading-title">Generating Assignment</h3>
        <p class="loading-message">Our AI is crafting high-quality questions based on your requirements...</p>
      </div>
    </div>
  </div>
</template>

<script>
import SubjectTextbookPicker from '@/components/SubjectTextbookPicker.vue';
import AssignmentPreview from '@/components/grader/AssignmentPreview.vue';
import axiosInstance from '@/utils/axiosInstance';

export default {
  name: 'AssignmentWithAI',
  components: {
    SubjectTextbookPicker,
    AssignmentPreview
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    dueDate: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      localTitle: this.title,
      localDueDate: this.dueDate,
      currentStep: 0,
      subject: '',
      selectedTextbookIds: [],
      description: '',
      aiPrompt: '',
      availableTextbooks: [],
      isGenerating: false,
      generationError: null,
      generatedQuestions: []
    };
  },
  computed: {
    canProceed() {
      if (this.currentStep === 0) {
        return this.localTitle && this.localDueDate && this.subject && this.selectedTextbookIds.length > 0;
      }
      return true;
    },
    formattedQuestions() {
      // Format questions for AssignmentPreview component
      return this.generatedQuestions.map(q => ({
        content: q.question,
        marks: q.marks_possible,
        answer: q.answer,
        gradingInstructions: q.grading_instructions
      }));
    }
  },
  watch: {
    // Watch for prop changes and update local data
    title(newVal) {
      this.localTitle = newVal;
    },
    dueDate(newVal) {
      this.localDueDate = newVal;
    }
  },
  methods: {
    updateTitle(value) {
      this.localTitle = value;
      this.$emit('update:title', value);
    },
    updateDueDate(value) {
      this.localDueDate = value;
      this.$emit('update:due-date', value);
    },
    nextStep() {
      if (this.currentStep < 2 && this.canProceed) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },
    handleSubjectTextbooksChanged(data) {
      this.subject = data.subject;
      this.selectedTextbookIds = data.textbookIds;
    },
    updateQuestions(updatedQuestions) {
      // Map back from AssignmentPreview format to our format
      this.generatedQuestions = this.generatedQuestions.map((q, i) => ({
        ...q,
        question: updatedQuestions[i].content,
        marks_possible: updatedQuestions[i].marks,
        answer: updatedQuestions[i].answer,
        grading_instructions: updatedQuestions[i].gradingInstructions
      }));
    },
    async generateAssignment() {
      if (!this.aiPrompt.trim()) {
        return;
      }
      
      this.isGenerating = true;
      this.generationError = null;
      
      try {
        const response = await axiosInstance.post('/grader/generate-assignment', {
          subject: this.subject,
          textbookIds: this.selectedTextbookIds,
          prompt: this.aiPrompt,
          title: this.localTitle,
          description: this.description
        });
        
        // Process the questions
        this.generatedQuestions = response.data.questions.map((q, index) => ({
          question_number: index + 1,
          question: q.question,
          marks_possible: q.marks_possible,
          answer: q.answer || '',
          grading_instructions: q.grading_instructions || ''
        }));
        
        // Move to the next step
        this.nextStep();
      } catch (error) {
        console.error('Error generating assignment:', error);
        this.generationError = 'Failed to generate assignment. Please try again.';
      } finally {
        this.isGenerating = false;
      }
    },
    removeQuestion(index) {
      this.generatedQuestions.splice(index, 1);
      
      // Update question numbers
      this.generatedQuestions = this.generatedQuestions.map((q, i) => ({
        ...q,
        question_number: i + 1
      }));
    },
    createAssignment() {
      if (this.generatedQuestions.length === 0) {
        alert('Please generate at least one question.');
        return;
      }
      
      // Format questions for submission
      const questionsForSubmission = this.generatedQuestions.map(q => ({
        question_number: q.question_number,
        question: q.question,
        marks_possible: q.marks_possible,
        answer: q.answer,
        grading_instructions: q.grading_instructions
      }));
      
      this.$emit('create-assignment', 'ai', questionsForSubmission);
    },
    async fetchTextbooks() {
      try {
        // Use the correct endpoint for fetching textbooks
        const response = await axiosInstance.get('/chatbot/list-textbooks');
        this.availableTextbooks = response.data;
      } catch (error) {
        console.error('Error fetching textbooks:', error);
        this.availableTextbooks = [];
      }
    }
  },
  mounted() {
    this.fetchTextbooks();
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

.scratch-form {
  width: 100%;
  margin: auto;
  position: relative;
}

.step-indicators {
  display: flex;
  justify-content: center;
}

.step-indicator {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  background-color: lightgrey;
  margin: 0 0.5vw;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.step-indicator.active {
  background-color: var(--classi2);
  transform: scale(1.2);
}

.sliding-menu-container {
  overflow-x: hidden;
  position: relative;
  height: 100%;
}

.sliding-menu {
  display: flex;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  width: 300%;
}

.step {
  width: 33.33%;
  height: 100%;
  padding-bottom: 0;
  overflow: auto;
}

.step-content {
  width: 85%;
  margin: 0 auto;
  padding: 1vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.5s ease-out;
}

.step-heading {
  font-size: 1.7vw;
  margin-bottom: 1vh;
}

.step-field {
  padding-bottom: 0.5vh;
  font-size: 1.1vw;
}

.form-group {
  margin-bottom: 2vw;
}

.input-field {
  width: 100%;
  padding: 1vw;
  border-radius: 1rem;
  border: none;
  font-size: 1.1vw;
  background-color: var(--secondary);
  color: var(--primary);
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease;
}

.input-field:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--classi2);
}

#title {
  text-transform: capitalize;
}

#description {
  text-transform: capitalize;
}

.description-area {
  min-height: 15vh;
  resize: vertical;
}

.ai-prompt-area {
  min-height: 20vh;
  resize: vertical;
}

.nav-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 2vh;
}

.next-btn,
.prev-btn,
.create-btn,
.retry-btn {
  padding: 1vw 2vw;
  border: none;
  border-radius: 1rem;
  font-size: 1vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  transition: all 0.3s ease;
  box-shadow: var(--box-shadow);
}

.next-btn,
.create-btn {
  background-color: var(--classi2);
  color: white;
}

.next-btn:hover,
.create-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.next-btn:disabled,
.create-btn:disabled {
  background-color: var(--secondary);
  color: var(--primary);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.prev-btn {
  background-color: var(--secondary);
  color: var(--primary);
}

.prev-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.retry-btn {
  background-color: var(--classi2);
  color: white;
  margin: 2vh auto;
  display: flex;
}

.retry-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Subject picker container to fix positioning */
.subject-picker-container {
  position: relative;
  z-index: 10;
}

/* Preview container */
.preview-container {
  height: 52vh;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
  margin-bottom: 2vh;
}

/* Error container */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3vh 0;
  text-align: center;
}

.error-icon {
  font-size: 3vw;
  color: #d9534f;
  margin-bottom: 1vh;
}

.error-text {
  color: #d9534f;
  font-size: 1.1vw;
  margin-bottom: 2vh;
}

/* Loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-content {
  text-align: center;
  padding: 2vw;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 30vw;
}

.loading-spinner {
  position: relative;
  width: 8vw;
  height: 8vw;
  margin: 0 auto 2vh;
}

.spinner-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0.5vw solid transparent;
  border-top-color: var(--classi2);
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

.spinner-circle-inner {
  position: absolute;
  top: 15%;
  left: 15%;
  width: 70%;
  height: 70%;
  border: 0.5vw solid transparent;
  border-top-color: var(--classi1);
  border-radius: 50%;
  animation: spin 1s linear infinite reverse;
}

.loading-title {
  font-size: 1.5vw;
  color: var(--classi2);
  margin-bottom: 1vh;
}

.loading-message {
  font-size: 1vw;
  color: var(--primary);
  line-height: 1.5;
}

/* Loading container in step 3 */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vh 0;
}

.loading-animation {
  display: flex;
  justify-content: center;
  gap: 1vw;
  margin-bottom: 2vh;
}

.loading-circle {
  width: 1.5vw;
  height: 1.5vw;
  background-color: var(--classi2);
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out both;
}

.loading-circle:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-circle:nth-child(2) {
  animation-delay: -0.16s;
}

.loading-text {
  font-size: 1.2vw;
  color: var(--classi2);
  margin-bottom: 1vh;
}

.loading-subtext {
  font-size: 0.9vw;
  color: var(--primary);
  text-align: center;
  max-width: 30vw;
  margin: 0 auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style> 