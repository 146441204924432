<template>
  <div :class="['main-container', { collapsed: isCollapsed }]">
    <Sidebar @themeChange="handleThemeChange" @toggle="handleToggle" :collapsed="isCollapsed" />
    <div style="
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    " v-if="loading">
      <LoadingSpinner />
    </div>
    <div :class="['content-container', { collapsed: isCollapsed }]">
      <div v-if="isMainContent">
        <InstructorHeader pageTitle="Autograder" />
        
        <div class="grading-actions">
          <div class="new-assignment-button" @click="showModal = true">
            New assignment
            <span class="material-symbols-outlined" style="padding-left: 1vh">
              add
            </span>
          </div>
        </div>
        
        <div class="grading-stats">
          <div class="stat-tile">
            <p class="stat-value">{{ gradingStats.graded }}</p>
            <p class="stat-title">Assignments Graded</p>
          </div>
          <div class="stat-tile">
            <p class="stat-value">{{ gradingStats.students }}</p>
            <p class="stat-title">Total Submissions</p>
          </div>
          <div class="stat-tile">
            <p class="stat-value">
              {{ gradingStats.average }}<span style="font-size: 1.5vw">%</span>
            </p>
            <p class="stat-title">Average Score</p>
          </div>
        </div>
        <div class="recent-assignments-header">
          <p>My Assignments</p>
          <div class="tabs-container">
            <div 
              :class="['tab', { active: activeTab === 'active' }]" 
              @click="switchTab('active')"
            >
              Active
            </div>
            <div 
              :class="['tab', { active: activeTab === 'past' }]" 
              @click="switchTab('past')"
            >
              Past
            </div>
          </div>
        </div>
        <div class="previous-assignments-container">
          <div v-if="filteredAssignments.length === 0" class="no-assignments-message"
            @click="showModal = true">
            <span v-if="activeTab === 'active'">
              You have no active assignments. Click to create a new assignment.
            </span>
            <span v-else>
              You have no past assignments. All your assignments are still active or you haven't created any yet.
            </span>
          </div>
          <div v-for="(assignment, index) in filteredAssignments" :key="assignment.assignment_id" class="assignment-card"
            @click="goToSubmissionStep(assignment.assignment_id)">
            <div class="card-section">
              <div class="card-section-heading">Title</div>
              <div class="card-section-content">
                {{ assignment.title }}
              </div>
            </div>
            <div class="card-section">
              <div class="card-section-heading">Created</div>
              <div class="card-section-content">
                {{ assignment.createdFormatted }}
              </div>
            </div>
            <div class="card-section">
              <div class="card-section-heading">Due</div>
              <div class="card-section-content">
                {{ assignment.dueFormatted }}
              </div>
            </div>
            <div class="card-section">
              <div class="card-section-heading">Submissions</div>
              <div class="card-section-content">
                {{ assignment.submissions }}
              </div>
            </div>
            <div class="card-section">
              <div class="card-section-heading">Average</div>
              <div class="card-section-content">
                {{ assignment.average }}%
              </div>
            </div>
          </div>
          <CreateAssignmentPopup v-if="showModal" @add-assignment="addAssignment" @close="showModal = false" />
        </div>
      </div>
      <router-view v-if="!isMainContent"></router-view>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/utils/axiosInstance";
import { convertGMTtoLocal } from "@/utils/generalUtils";
import { useInstructorStore } from "@/store/instructorData";
import CreateAssignmentPopup from "@/components/grader/CreateAssignmentPopup.vue";
import Sidebar from "@/components/InstructorSidebar.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { getTenant } from "@/utils/tenantStore";
import InstructorHeader from "@/components/InstructorHeader.vue";

export default {
  name: "InstructorGrading",
  components: {
    LoadingSpinner,
    Sidebar,
    CreateAssignmentPopup,
    InstructorHeader,
  },
  data() {
    return {
      loading: true,
      previousAssignments: {},
      showModal: false,
      submissions: {},
      assignmentId: -1,
      gradedQuestions: {},
      gradingComplete: false,
      isCollapsed: false,
      activeTab: 'active',
    };
  },
  created() {
    this.instructorStore = useInstructorStore();
  },
  mounted() {
    this.getPreviousAssignments();
    
    // Add route change listener
    this.$router.afterEach((to, from) => {
      // If we're returning to the main view
      if (to.path === "/instructor/grader/" || to.path === "/instructor/grader") {
        // Reset component state
        this.previousAssignments = {};
        this.loading = true;
        this.getPreviousAssignments();
      }
    });
  },
  watch: {
    '$route'() {
      if (this.isMainContent) {
        this.previousAssignments = {};
        this.loading = true;
      }
      this.getPreviousAssignments();
    },
    activeTab() {
      console.log("Tab changed to:", this.activeTab);
    }
  },
  computed: {
    gradingStats() {
      const assignments = Object.values(this.previousAssignments);
      const numAssignments = assignments.length;
      const totalSubmissions = assignments.reduce((sum, { submissions }) => sum + submissions, 0);

      const avg = numAssignments === 0
        ? 'N/A'
        : (
          assignments.reduce((total, item) => total + parseFloat(item.average), 0) / numAssignments
        ).toFixed(2);

      return {
        graded: numAssignments,
        students: totalSubmissions,
        average: avg,
      };
    },
    sortedAssignments() {
      return Object.entries(this.previousAssignments)
        .map(([id, assignment]) => ({ assignment_id: id, ...assignment }))
        .sort((a, b) => {
          const dateA = new Date(a.created);
          const dateB = new Date(b.created);
          return dateB - dateA;
        });
    },
    filteredAssignments() {
      const now = new Date();
      return this.sortedAssignments.filter(assignment => {
        const dueDate = new Date(assignment.due);
        if (this.activeTab === 'active') {
          return dueDate >= now;
        } else {
          return dueDate < now;
        }
      });
    },
    isMainContent() {
      return (
        this.$route.path === "/instructor/grader/" ||
        this.$route.path === "/instructor/grader"
      );
    },
  },
  methods: {
    handleToggle(collapsed) {
      this.isCollapsed = !collapsed;
    },
    handleThemeChange(currentTheme) {
      this.theme = currentTheme;
    },
    async getPreviousAssignments() {
      try {
        const response = await axiosInstance.post(
          "/grader/previous-assignments",
          {
            email: this.instructorStore.email,
          }
        );

        for (const assignment of response.data) {
          const createdDate = new Date(assignment.created_at);
          const dueDate = new Date(assignment.due_date);
          
          this.previousAssignments[assignment.assignment_id] = {
            title: assignment.title,
            due: dueDate,
            created: createdDate,
            dueFormatted: convertGMTtoLocal(assignment.due_date),
            createdFormatted: convertGMTtoLocal(assignment.created_at),
            lastUpdated: assignment.last_updated,
            submissions: assignment.submissions,
            average: (assignment.average || 0).toFixed(2),
          };
        }
      } catch (error) {
        console.error("Error updating response:", error);
      } finally {
        this.loading = false;
      }
    },
    addAssignment(id, created, title, dueDate) {
      const createdDate = new Date(created);
      const dueDateObj = new Date(dueDate);
      
      this.previousAssignments[id] = {
        title: title,
        created: createdDate,
        due: dueDateObj,
        createdFormatted: convertGMTtoLocal(created),
        dueFormatted: convertGMTtoLocal(dueDate),
        submissions: 0,
        average: 0,
      };
    },
    calculateTotalPoints(fileName) {
      this.gradedQuestions[fileName]["grading"] = {
        possible: 0,
        obtained: 0,
      };
      for (const question in this.gradedQuestions[fileName]) {
        if (question !== "grading") {
          this.gradedQuestions[fileName]["grading"].possible += parseFloat(
            this.gradedQuestions[fileName][question].points_possible
          );
          this.gradedQuestions[fileName]["grading"].obtained += parseFloat(
            this.gradedQuestions[fileName][question].points_obtained
          );
        }
      }
    },
    goToSubmissionStep(assignmentId) {
      this.assignmentId = assignmentId;
      this.$router.push({
        name: "SubmissionStep",
        params: { assignmentId: assignmentId },
      });
    },
    goToGradingStep() {
      this.gradeSubmissions();
      this.currentStep += 1;
    },
    switchTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style scoped>
.main-container {
  transition: all 0.3s ease-in-out;
  font-family: var(--main-font);
  overflow: hidden;
  margin-right: 2vw;
}

.main-container.collapsed {
  margin-left: 8vw;
}

.main-container:not(.collapsed) {
  margin-left: 22vw;
}

.content-container {
  flex: 1;
  transition: margin-left 0.3s ease-in-out;
  color: var(--primary);
  overflow: hidden;
}

.navigation-info {
  color: grey;
  margin-top: 4vh;
  font-size: 1.2vw;
}

.material-symbols-outlined {
  font-size: 1.5vw;
}

.grading-actions {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.new-assignment-button {
  display: flex;
  align-items: center;
  background-color: var(--classi2);
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.new-assignment-button:hover {
  background-color: var(--classi1);
}

.welcome-message {
  font-size: 3vw;
}

.grading-stats {
  width: 100%;
  height: 15vh;
  margin: 3vh 0 1vh 0;
  display: flex;
  justify-content: space-evenly;
}

.stat-tile {
  width: 18vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}

.stat-tile:hover {
  box-shadow: var(--box-shadow);
  transform: translateY(-5%);
  transition: ease all 0.5s;
}

.stat-value {
  font-size: 5vw;
}

.stat-title {
  font-size: 1vw;
  color: grey;
  margin-bottom: 2vh;
}

.recent-assignments-header {
  color: var(--primary);
  font-size: 1.5vw;
  padding: 1vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh;  
}

.tabs-container {
  display: flex;
  background-color: var(--classi2);
  border-radius: 4rem;
  padding: 0.3vh;
  width: fit-content;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  
}

.tab {
  padding: 0.7vh 1.5vw;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  font-weight: 500;
  color: white;
  position: relative;
  z-index: 1;
  font-size: 1vw;
  margin: 0 0.2vw;
}

.tab:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.tab.active {
  background-color: var(--secondary);
  color: var(--primary);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.previous-assignments-container {
  width: 100%;
  height: 55vh;
  background-color: rgb(0, 139, 139, 0.8);
  border-radius: 2rem;
  font-size: 1.2vw;
  padding: 2vh 2vw;
  overflow-y: auto;
}

.no-assignments-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.no-assignments-message:active {
  opacity: 0.2;
  transition: ease all 0.2s;
}

.assignment-card {
  width: 100%;
  background-color: var(--accent3);
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  padding: 1.5vh 1.5vw;
  margin-bottom: 1vh;
  display: flex;
}

.assignment-card:hover {
  cursor: pointer;
  transform: translateY(-5%);
  transition: ease all 0.3s;
}

.card-section {
  flex: 1;
}

.card-section-heading {
  color: var(--accent2);
  font-size: 0.9vw;
}
</style>
