import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";
import App from "./App.vue";
import router from "./router";
import "./styles.css";

// Import VCalendar and its styles
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPersist);

app.directive("click-outside", {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    },
});

// Use VCalendar with custom defaults
app.use(VCalendar, {
    componentPrefix: 'v',
    defaults: {
        color: 'darkcyan',
        transition: 'fade',
        masks: {
            input: 'YYYY-MM-DD',
        },
    }
});

app.use(pinia);
app.use(router);
app.mount("#app");
