<template>
  <div :class="['main-container', { collapsed: isCollapsed }]">
    <Sidebar
      @themeChange="handleThemeChange"
      @toggle="handleToggle"
      :collapsed="isCollapsed"
    />
    <div v-if="loading" class="loading-container">
      <LoadingSpinner />
    </div>
    <div v-else :class="['content-container', { collapsed: isCollapsed }]">
      <InstructorHeader pageTitle="Dashboard" />
      
      <div class="dashboard-content">
        <!-- Main Column -->
        <div class="main-column">
          <!-- Grading Stats -->
          <div class="grading-stats">
            <div class="stat-tile">
              <p class="stat-value">{{ gradingStats.pending }}</p>
              <p class="stat-title">Pending Assignments</p>
            </div>
            <div class="stat-tile">
              <p class="stat-value">{{ gradingStats.students }}</p>
              <p class="stat-title">Active Students</p>
            </div>
            <div class="stat-tile">
              <p class="stat-value">
                {{ gradingStats.average }}<span class="percent">%</span>
              </p>
              <p class="stat-title">Class Average</p>
            </div>
          </div>

          <!-- Quick Actions Card -->
          <div class="quick-actions-card">
            <div class="card-header">
              <span class="material-symbols-outlined">bolt</span>
              <h2>Quick Actions</h2>
            </div>
            <div class="actions-grid">
              <div class="action-item" @click="createAssignment">
                <span class="material-symbols-outlined">assignment_add</span>
                <div>Create Assignment</div>
              </div>
              <div class="action-item" @click="goToGrading">
                <span class="material-symbols-outlined">grade</span>
                <div>Start Grading</div>
              </div>
              <div class="action-item" @click="viewSubmissions">
                <span class="material-symbols-outlined">description</span>
                <div>View Submissions</div>
              </div>
              <div class="action-item" @click="viewStats">
                <span class="material-symbols-outlined">analytics</span>
                <div>View Statistics</div>
              </div>
            </div>
          </div>

          <!-- Next Deadline -->
          <div v-if="upcomingAssignments.length > 0" class="next-deadline" @click="viewAssignment(upcomingAssignments[0])">
            <div class="section-label">
              <span class="material-symbols-outlined section-icon">event_upcoming</span>
              Next Assignment Deadline
            </div>
            <div class="deadline-content">
              <div class="deadline-date">
                <div class="date-box">
                  <div class="month">{{ formatMonth(upcomingAssignments[0].due) }}</div>
                  <div class="day">{{ formatDay(upcomingAssignments[0].due) }}</div>
                </div>
              </div>
              <div class="deadline-details">
                <div class="deadline-title">{{ upcomingAssignments[0].title }}</div>
                <div class="deadline-time">{{ getTimeLeft(upcomingAssignments[0].due) }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Side Column -->
        <div class="side-column">
          <!-- Performance Overview -->
          <div class="performance-card">
            <div class="card-header">
              <span class="material-symbols-outlined">leaderboard</span>
              <h2>Class Performance</h2>
            </div>
            <div class="performance-content">
              <div class="performance-chart">
                <div class="chart-bar" v-for="(grade, index) in gradeDistribution" :key="index">
                  <div class="bar-fill" :style="{ height: `${grade.percentage}%`, backgroundColor: grade.color }"></div>
                  <div class="bar-label">{{ grade.label }}</div>
                </div>
              </div>
              <div class="performance-summary">
                <div class="summary-item">
                  <div class="summary-value">{{ gradingStats.average }}%</div>
                  <div class="summary-label">Class Average</div>
                </div>
                <div class="summary-item">
                  <div class="summary-value">{{ gradingStats.students }}</div>
                  <div class="summary-label">Active Students</div>
                </div>
              </div>
            </div>
          </div>

          <!-- Recent Submissions -->
          <div class="submissions-card">
            <div class="card-header">
              <span class="material-symbols-outlined">history</span>
              <h2>Recent Submissions</h2>
            </div>
            <div class="submissions-list">
              <div v-for="submission in recentSubmissions" :key="submission.id" 
                   class="submission-item" @click="viewSubmission(submission)">
                <div class="submission-info">
                  <div class="submission-student">{{ submission.student }}</div>
                  <div class="submission-title">{{ submission.assignment }}</div>
                </div>
                <div class="submission-time">{{ formatSubmissionTime(submission.time) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/InstructorSidebar.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useInstructorStore } from "@/store/instructorData";
import axiosInstance from "@/utils/axiosInstance";
import { convertGMTtoLocal } from "@/utils/generalUtils";
import InstructorHeader from "@/components/InstructorHeader.vue";

export default {
  name: "InstructorDashboard",
  components: {
    Sidebar,
    LoadingSpinner,
    InstructorHeader,
  },
  data() {
    return {
      loading: true,
      isCollapsed: false,
      assignments: {},
      gradingStats: {
        pending: 0,
        students: 0,
        average: 'N/A',
      },
      gradeDistribution: [],
      recentSubmissions: [],
      upcomingAssignments: [],
    };
  },
  created() {
    this.instructorStore = useInstructorStore();
  },
  async mounted() {
    await this.fetchDashboardData();
  },
  methods: {
    handleToggle(collapsed) {
      this.isCollapsed = !collapsed;
    },
    handleThemeChange(currentTheme) {
      this.theme = currentTheme;
    },
    async fetchDashboardData() {
      try {
        const response = await axiosInstance.post("/grader/previous-assignments", {
          email: this.instructorStore.email,
        });

        // Process assignments
        this.assignments = response.data.reduce((acc, assignment) => {
          const dueDate = new Date(assignment.due_date);
          acc[assignment.assignment_id] = {
            title: assignment.title,
            due: dueDate,
            created: new Date(assignment.created_at),
            submissions: assignment.submissions,
            average: (assignment.average || 0).toFixed(2),
          };
          return acc;
        }, {});

        // Calculate stats
        const now = new Date();
        let pendingCount = 0;
        let totalStudents = 0;
        let totalAverage = 0;
        let validAssignments = 0;

        Object.values(this.assignments).forEach(assignment => {
          if (assignment.due > now) {
            pendingCount++;
          }
          totalStudents = Math.max(totalStudents, assignment.submissions);
          if (assignment.average !== '0.00') {
            totalAverage += parseFloat(assignment.average);
            validAssignments++;
          }
        });

        this.gradingStats = {
          pending: pendingCount,
          students: totalStudents,
          average: validAssignments > 0 ? (totalAverage / validAssignments).toFixed(2) : 'N/A',
        };

        // Get upcoming assignments
        this.upcomingAssignments = Object.entries(this.assignments)
          .map(([id, assignment]) => ({ id, ...assignment }))
          .filter(assignment => assignment.due > now)
          .sort((a, b) => a.due - b.due);

        // Calculate grade distribution
        const grades = Object.values(this.assignments)
          .map(a => parseFloat(a.average))
          .filter(avg => !isNaN(avg) && avg > 0);

        const distribution = {
          A: { label: 'A', percentage: 0, color: '#4CAF50', count: 0 },
          B: { label: 'B', percentage: 0, color: '#8BC34A', count: 0 },
          C: { label: 'C', percentage: 0, color: '#FFC107', count: 0 },
          D: { label: 'D', percentage: 0, color: '#FF9800', count: 0 },
          F: { label: 'F', percentage: 0, color: '#F44336', count: 0 },
        };

        grades.forEach(grade => {
          if (grade >= 90) distribution.A.count++;
          else if (grade >= 80) distribution.B.count++;
          else if (grade >= 70) distribution.C.count++;
          else if (grade >= 60) distribution.D.count++;
          else distribution.F.count++;
        });

        const total = grades.length;
        Object.values(distribution).forEach(grade => {
          grade.percentage = total > 0 ? (grade.count / total) * 100 : 0;
        });

        this.gradeDistribution = Object.values(distribution);

        // Get recent submissions (mock data for now, will be replaced with real API endpoint)
        this.recentSubmissions = [
          { id: 1, student: 'Loading...', assignment: 'Loading...', time: new Date() }
        ];

      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        this.loading = false;
      }
    },
    createAssignment() {
      this.$router.push({ name: "InstructorGrading" });
    },
    goToGrading() {
      this.$router.push({ name: "InstructorGrading" });
    },
    viewSubmissions() {
      this.$router.push({ name: "InstructorGrading" });
    },
    viewStats() {
      // Will be implemented in future
      alert("Statistics view coming soon!");
    },
    viewAssignment(assignment) {
      this.$router.push({
        name: "InstructorGrading",
        params: { assignmentId: assignment.id }
      });
    },
    viewSubmission(submission) {
      // Will be implemented when submission endpoint is ready
      this.$router.push({ name: "InstructorGrading" });
    },
    formatMonth(date) {
      return date.toLocaleString('default', { month: 'short' });
    },
    formatDay(date) {
      return date.getDate();
    },
    getTimeLeft(dueDate) {
      const now = new Date();
      const diffTime = Math.abs(dueDate - now);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      
      if (diffDays === 0) {
        return "Due today";
      } else if (diffDays === 1) {
        return "Due tomorrow";
      } else {
        return `Due in ${diffDays} days`;
      }
    },
    formatSubmissionTime(time) {
      const now = new Date();
      const diffMs = now - time;
      const diffMins = Math.floor(diffMs / 60000);
      
      if (diffMins < 60) {
        return `${diffMins} min ago`;
      } else {
        const diffHours = Math.floor(diffMins / 60);
        return `${diffHours} hr ago`;
      }
    },
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  transition: all 0.3s ease-in-out;
  font-family: var(--main-font);
  margin: 2vh 2vw;
}

.main-container.collapsed .content-container {
  margin-left: 6vw;
}

.main-container:not(.collapsed) .content-container {
  margin-left: 18vw;
}

.content-container {
  flex: 1;
  transition: margin-left 0.3s ease;
  color: var(--primary);
}

.loading-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Header Styles */
.dashboard-header {
  margin-bottom: 3vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation-info {
  color: var(--classi2);
  font-size: 2.5vw;
  font-weight: 600;
  margin-bottom: 0.5vh;
}

.welcome-message {
  font-size: 1.2vw;
  color: var(--accent4);
  margin: 0;
}

.new-assignment-button {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 1.5vh 2vw;
  font-size: 1.2vw;
  background-color: var(--accent4);
  color: var(--secondary);
  border: none;
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  transition: ease all 0.3s;
}

.new-assignment-button:hover {
  background-color: var(--classi2);
  color: white;
  transform: translateY(-3px);
}

.new-assignment-button:active {
  transform: scale(0.95);
}

.new-assignment-button .material-symbols-outlined {
  font-size: 1.5vw;
  margin-right: 0.5vw;
}

/* Dashboard Layout */
.dashboard-content {
  display: flex;
  gap: 3vw;
  height: calc(85vh - 9rem);
}

.main-column {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.side-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

/* Grading Stats */
.grading-stats {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1vh;
}

.stat-tile {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 1rem;
  padding: 2vh 0;
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease;
}

.stat-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.stat-value {
  font-size: 3vw;
  color: var(--classi2);
  margin: 0;
  display: flex;
  align-items: baseline;
}

.stat-value .percent {
  font-size: 1.5vw;
  margin-left: 2px;
}

.stat-title {
  font-size: 1vw;
  color: var(--accent4);
  margin: 0.5vh 0 0 0;
}

/* Quick Actions */
.quick-actions-card {
  background-color: white;
  border-radius: 1rem;
  padding: 2vh 2vw;
  box-shadow: var(--box-shadow);
}

.card-header {
  display: flex;
  align-items: center;
  gap: 1vw;
  margin-bottom: 2vh;
}

.card-header h2 {
  font-size: 1.3vw;
  font-weight: 500;
  color: var(--primary);
  margin: 0;
}

.card-header .material-symbols-outlined {
  color: var(--classi2);
  font-size: 1.8vw;
}

.actions-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5vh 2vw;
}

.action-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2vh 1vw;
  background-color: var(--background);
  border-radius: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-item:hover {
  transform: translateY(-3px);
  background-color: rgba(0, 139, 139, 0.1);
}

.action-item .material-symbols-outlined {
  font-size: 2vw;
  color: var(--classi2);
  margin-bottom: 1vh;
}

.action-item div {
  font-size: 1vw;
  color: var(--primary);
}

/* Next Deadline */
.next-deadline {
  background-color: white;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  padding: 2vh 2vw;
  cursor: pointer;
  transition: all 0.3s ease;
  border-left: 4px solid #e67e22;
}

.next-deadline:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.section-label {
  font-size: 1.2vw;
  color: var(--primary);
  font-weight: 500;
  margin-bottom: 2vh;
  display: flex;
  align-items: center;
}

.section-icon {
  color: var(--classi2);
  margin-right: 0.5vw;
  font-size: 1.5vw;
}

.deadline-content {
  display: flex;
  align-items: center;
}

.deadline-date {
  margin-right: 1.5vw;
}

.date-box {
  background-color: #e67e22;
  color: white;
  border-radius: 0.8rem;
  padding: 0.8vh 0.5vw;
  width: 4.5vw;
  text-align: center;
}

.month {
  font-size: 0.9vw;
  font-weight: 500;
}

.day {
  font-size: 2vw;
  font-weight: 300;
}

.deadline-details {
  flex: 1;
}

.deadline-title {
  font-size: 1.2vw;
  font-weight: 500;
  margin-bottom: 0.5vh;
}

.deadline-time {
  color: #e67e22;
  font-size: 1vw;
}

/* Performance Card */
.performance-card {
  background-color: white;
  border-radius: 1rem;
  padding: 2vh 2vw;
  box-shadow: var(--box-shadow);
  flex: 1;
}

.performance-chart {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 180px;
  margin-bottom: 2vh;
  padding-bottom: 1vh;
  border-bottom: 1px solid var(--border-color);
}

.chart-bar {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5vh;
}

.bar-fill {
  width: 80%;
  border-radius: 4px 4px 0 0;
  transition: height 0.3s ease;
}

.bar-label {
  font-size: 0.9vw;
  color: var(--accent4);
}

.performance-summary {
  display: flex;
  justify-content: space-around;
  margin-top: 2vh;
}

.summary-item {
  text-align: center;
}

.summary-value {
  font-size: 1.5vw;
  font-weight: 600;
  color: var(--classi2);
  margin-bottom: 0.5vh;
}

.summary-label {
  font-size: 0.9vw;
  color: var(--accent4);
}

/* Submissions Card */
.submissions-card {
  background-color: white;
  border-radius: 1rem;
  padding: 2vh 2vw;
  box-shadow: var(--box-shadow);
}

.submissions-list {
  display: flex;
  flex-direction: column;
  gap: 1.5vh;
}

.submission-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5vh 1vw;
  background-color: var(--background);
  border-radius: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submission-item:hover {
  transform: translateX(5px);
  background-color: rgba(0, 139, 139, 0.1);
}

.submission-student {
  font-weight: 500;
  color: var(--primary);
  margin-bottom: 0.3vh;
  font-size: 1vw;
}

.submission-title {
  font-size: 0.9vw;
  color: var(--accent4);
}

.submission-time {
  font-size: 0.8vw;
  color: var(--secondary-text);
  padding: 0.3vh 0.8vw;
  background-color: rgba(0, 139, 139, 0.1);
  border-radius: 1rem;
}

/* Animations */
@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.dashboard-content {
  animation: slideIn 0.5s ease-out;
}
</style>
