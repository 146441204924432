<template lang="html">
  <div class="main-container">
    <Header />
    <LoadingSpinner v-if="loading" class="spinner" />

    <div v-if="!loading" class="loginContainer">
      <!-- Error message display -->
      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>
      
      <!-- Step 1: Email entry -->
      <form v-if="currentStep === 1" @submit.prevent="requestVerification">
        <h2>Instructor Signup</h2>
        <input type="email" v-model="email" placeholder="Email" required autocapitalize="off" />
        <p class="info-text">Only emails from authorized domains are allowed.</p>
        <button type="submit">Get Verification Code</button>
        <div class="login-link">
          Already have an account? <router-link to="/login">Login</router-link>
        </div>
      </form>

      <!-- Step 2: Verification code -->
      <form v-if="currentStep === 2" @submit.prevent="verifyCode">
        <h2>Verification</h2>
        <p>We've sent a verification code to {{ email }}</p>
        <p>Please enter the code below to verify your account.</p>
        
        <div class="timer-container">
          <p>Time remaining: <span class="timer">{{ formattedTimeRemaining }}</span></p>
        </div>
        
        <div class="verification-code-container">
          <input 
            v-for="(digit, index) in 6" 
            :key="index"
            type="text" 
            maxlength="1" 
            pattern="[0-9]*" 
            inputmode="numeric"
            v-model="verificationDigits[index]"
            @input="handleDigitInput($event, index)"
            @keydown="handleKeyDown($event, index)"
            ref="digitInputs"
            class="verification-digit"
            required
          />
        </div>
        
        <button type="submit">Verify</button>
        <div class="button-spacer"></div>
        <button type="button" class="secondary-button" @click="currentStep = 1">Back</button>
      </form>

      <!-- Step 3: Account creation -->
      <form v-if="currentStep === 3" @submit.prevent="createAccount">
        <h2>Create Account</h2>
        <input type="text" v-model="firstName" placeholder="First Name" required 
               pattern="^[A-Za-z\s\-']{2,50}$" 
               title="First name should contain 2-50 letters, spaces, hyphens or apostrophes" />
        <input type="text" v-model="lastName" placeholder="Last Name" required 
               pattern="^[A-Za-z\s\-']{2,50}$" 
               title="Last name should contain 2-50 letters, spaces, hyphens or apostrophes" />
        <input type="text" v-model="school" placeholder="School" disabled class="disabled-input" />
        
        <div class="preference-container">
          <label>Honorifics:</label>
          <div class="title-slider-container">
            <div class="title-slider">
              <div class="title-options">
                <div 
                  class="title-option" 
                  :class="{ active: preferredTitle === 'Ma\'am' }"
                  @click="selectTitle('ma\'am')"
                >
                  Ma'am
                </div>
                <div 
                  class="title-option" 
                  :class="{ active: preferredTitle === 'sir' }"
                  @click="selectTitle('Sir')"
                >
                  Sir
                </div>
                <div 
                  class="title-option" 
                  :class="{ active: preferredTitle === 'Other' }"
                  @click="selectTitle('Other')"
                >
                  Other
                </div>
              </div>
              <div 
                class="title-slider-indicator" 
                :style="{ transform: `translateX(${getTitleSliderPosition()}%)` }"
              ></div>
            </div>
          </div>
          <input 
            v-if="preferredTitle === 'Other'" 
            type="text" 
            v-model="customTitle" 
            placeholder="Please specify" 
            required
            class="custom-title-input"
          />
        </div>
        
        <input type="password" v-model="password" placeholder="Password" required 
               minlength="8"
               @input="validatePassword" />
        <div v-if="password" class="password-requirements" :class="{ 'all-valid': allPasswordRequirementsMet }">
          <p :class="{ valid: passwordLength }">✓ At least 8 characters</p>
          <p :class="{ valid: passwordUppercase }">✓ At least one uppercase letter</p>
          <p :class="{ valid: passwordLowercase }">✓ At least one lowercase letter</p>
          <p :class="{ valid: passwordNumber }">✓ At least one number</p>
          <p :class="{ valid: passwordSpecial }">✓ At least one special character</p>
        </div>
        <input type="password" v-model="confirmPassword" placeholder="Confirm Password" required />
        <div v-if="password && confirmPassword" class="password-match" :class="{ valid: passwordsMatch }">
          <p>{{ passwordsMatch ? '✓ Passwords match' : '✗ Passwords do not match' }}</p>
        </div>
        <button 
          type="submit" 
          :disabled="!isFormValid" 
          class="create-account-button"
          @mouseover="showValidationTooltip = true"
          @mouseleave="showValidationTooltip = false"
        >
          Create Account
          <div class="validation-tooltip" v-if="showValidationTooltip && !isFormValid">
            <h4>Please fix the following issues:</h4>
            <ul>
              <li v-if="!isNameValid">First and last name must contain only letters, spaces, hyphens or apostrophes (2-50 characters)</li>
              <li v-if="!isTitleSelected">Please select how you would like to be addressed</li>
              <li v-if="preferredTitle === 'Other' && !customTitle">Please specify your preferred title</li>
              <li v-if="!allPasswordRequirementsMet">Password must meet all requirements:
                <ul>
                  <li v-if="!passwordLength">At least 8 characters</li>
                  <li v-if="!passwordUppercase">At least one uppercase letter</li>
                  <li v-if="!passwordLowercase">At least one lowercase letter</li>
                  <li v-if="!passwordNumber">At least one number</li>
                  <li v-if="!passwordSpecial">At least one special character</li>
                </ul>
              </li>
              <li v-if="!passwordsMatch">Passwords do not match</li>
            </ul>
          </div>
        </button>
        <div class="button-spacer"></div>
        <button type="button" class="secondary-button" @click="currentStep = 2">Back</button>
      </form>

      <!-- Step 4: Success message -->
      <div v-if="currentStep === 4" class="success-container">
        <div class="success-message">
          <h2>Account Created Successfully!</h2>
          <div class="success-icon">✓</div>
          <p>Your instructor account has been created successfully.</p>
          <p class="countdown-text">You will be redirected in <span class="countdown">{{ redirectCountdown }}</span> seconds...</p>
          <button @click="goToDashboard" class="primary-button">Login Now</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import axiosInstance from "@/utils/axiosInstance";
import { useStudentStore } from "@/store/studentData";
import { useInstructorStore } from "@/store/instructorData";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "SignupPage",
  components: {
    Header,
    LoadingSpinner,
  },
  data() {
    return {
      currentStep: 1,
      email: "",
      verificationDigits: ['', '', '', '', '', ''],
      firstName: "",
      lastName: "",
      school: "",
      preferredTitle: "Ma'am",
      customTitle: "",
      password: "",
      confirmPassword: "",
      loading: false,
      errorMessage: "",
      verificationTimer: null,
      timeRemaining: 120, // 120 seconds = 2 minutes
      // Password validation flags
      passwordLength: false,
      passwordUppercase: false,
      passwordLowercase: false,
      passwordNumber: false,
      passwordSpecial: false,
      showValidationTooltip: false,
      redirectCountdown: 5, // Countdown for redirect after success
      redirectTimer: null, // Timer for redirect countdown
    };
  },
  computed: {
    verificationCode() {
      return this.verificationDigits.join('');
    },
    passwordsMatch() {
      return this.password === this.confirmPassword && this.password !== '';
    },
    allPasswordRequirementsMet() {
      return this.passwordLength && 
             this.passwordUppercase && 
             this.passwordLowercase && 
             this.passwordNumber && 
             this.passwordSpecial;
    },
    effectiveTitle() {
      return this.preferredTitle === 'Other' ? this.customTitle : this.preferredTitle;
    },
    isNameValid() {
      return this.firstName.match(/^[A-Za-z\s\-']{2,50}$/) && 
             this.lastName.match(/^[A-Za-z\s\-']{2,50}$/);
    },
    isTitleSelected() {
      return !!this.preferredTitle;
    },
    isFormValid() {
      // Check if all form fields are valid
      const titleValid = this.preferredTitle && 
                        (this.preferredTitle !== 'Other' || (this.preferredTitle === 'Other' && this.customTitle));
      
      return this.isNameValid && titleValid && this.allPasswordRequirementsMet && this.passwordsMatch;
    },
    formattedTimeRemaining() {
      const minutes = Math.floor(this.timeRemaining / 60);
      const seconds = this.timeRemaining % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
  },
  created() {
    this.studentStore = useStudentStore();
    this.instructorStore = useInstructorStore();
    
    // Get school from query parameter
    const schoolParam = this.$route.query.school;
    if (schoolParam) {
      this.school = decodeURIComponent(schoolParam);
    } else {
      // If no school parameter is provided, redirect to login page
      this.$router.push("/login");
      alert("Invalid signup link. Please contact your school administrator.");
    }
  },
  beforeUnmount() {
    // Clear any timers when component is destroyed
    if (this.verificationTimer) {
      clearInterval(this.verificationTimer);
    }
    if (this.redirectTimer) {
      clearInterval(this.redirectTimer);
    }
  },
  methods: {
    handleDigitInput(event, index) {
      const input = event.target;
      
      // Ensure only numbers are entered
      const value = input.value;
      if (!/^\d*$/.test(value)) {
        this.verificationDigits[index] = '';
        return;
      }
      
      // Auto-advance to next input
      if (value && index < 5) {
        this.$nextTick(() => {
          this.$refs.digitInputs[index + 1].focus();
        });
      }
    },
    
    handleKeyDown(event, index) {
      // Handle backspace to go to previous input
      if (event.key === 'Backspace' && !this.verificationDigits[index] && index > 0) {
        this.$nextTick(() => {
          this.$refs.digitInputs[index - 1].focus();
        });
      }
    },
    
    validatePassword() {
      // Check password length
      this.passwordLength = this.password.length >= 8;
      
      // Check for uppercase letter
      this.passwordUppercase = /[A-Z]/.test(this.password);
      
      // Check for lowercase letter
      this.passwordLowercase = /[a-z]/.test(this.password);
      
      // Check for number
      this.passwordNumber = /[0-9]/.test(this.password);
      
      // Check for special character
      this.passwordSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.password);
    },
    
    startVerificationTimer() {
      this.timeRemaining = 120; // Reset to 120 seconds
      
      // Clear any existing timer
      if (this.verificationTimer) {
        clearInterval(this.verificationTimer);
      }
      
      // Start a new timer
      this.verificationTimer = setInterval(() => {
        this.timeRemaining--;
        
        if (this.timeRemaining <= 0) {
          clearInterval(this.verificationTimer);
          // Show expired message and offer to resend
          this.errorMessage = "Verification code has expired. Please request a new one.";
          this.currentStep = 1; // Go back to email entry
        }
      }, 1000);
    },
    
    async requestVerification() {
      this.errorMessage = "";
      this.loading = true;
      
      try {
        const tenant = this.email.split('@')[1].split('.')[0];

        console.log(tenant);
        
        const response = await axiosInstance.post(
          "/auth/signup/request-verification",
          {
            email: this.email,
            school: this.school
          },
          {
            headers: {
              'X-Tenant': tenant
            }
          }
        );
        
        // Move to verification step
        this.currentStep = 2;
        
        // Start the verification timer
        this.startVerificationTimer();
        
        // If in development mode, auto-fill the verification code
        if (process.env.NODE_ENV === 'development' && response.data.code) {
          const codeDigits = response.data.code.split('');
          this.verificationDigits = codeDigits;
        }
        
        // Focus on first digit input
        this.$nextTick(() => {
          if (this.$refs.digitInputs && this.$refs.digitInputs.length > 0) {
            this.$refs.digitInputs[0].focus();
          }
        });
      } catch (error) {
        console.error("Error requesting verification", error);
        if (error.response) {
          if (error.response.status === 403) {
            this.errorMessage = "This email domain is not authorized for signup.";
          } else if (error.response.status === 409) {
            this.errorMessage = "This email is already registered.";
          } else {
            this.errorMessage = error.response.data.message || "Failed to send verification code.";
          }
        } else {
          this.errorMessage = "Network error. Please try again.";
        }
      } finally {
        this.loading = false;
      }
    },
    
    async verifyCode() {
      // Check if all digits are filled
      if (this.verificationDigits.some(digit => !digit)) {
        this.errorMessage = "Please enter all 6 digits of the verification code";
        return;
      }
      
      this.errorMessage = "";
      this.loading = true;
      
      try {
        const tenant = this.email.split('@')[1].split('.')[0];
        
        const response = await axiosInstance.post(
          "/auth/signup/verify-code",
          {
            email: this.email,
            code: this.verificationCode
          },
          {
            headers: {
              'X-Tenant': tenant
            }
          }
        );
        
        // Stop the timer
        if (this.verificationTimer) {
          clearInterval(this.verificationTimer);
        }
        
        // Move to account creation step
        this.currentStep = 3;
        
        // If school was returned from the backend, use it
        if (response.data.school) {
          this.school = response.data.school;
        }
      } catch (error) {
        console.error("Error verifying code", error);
        if (error.response) {
          if (error.response.status === 410) {
            this.errorMessage = "Verification code has expired. Please request a new one.";
            // Go back to email step after a short delay
            setTimeout(() => {
              this.currentStep = 1;
            }, 2000);
          } else if (error.response.status === 401) {
            this.errorMessage = "Invalid verification code. Please try again.";
          } else {
            this.errorMessage = error.response.data.message || "Failed to verify code.";
          }
        } else {
          this.errorMessage = "Network error. Please try again.";
        }
      } finally {
        this.loading = false;
      }
    },
    
    async createAccount() {
      if (!this.isFormValid) {
        if (!this.passwordsMatch) {
          this.errorMessage = "Passwords do not match";
        } else if (!this.allPasswordRequirementsMet) {
          this.errorMessage = "Please ensure your password meets all requirements";
        } else if (!this.preferredTitle) {
          this.errorMessage = "Please select how you would like to be addressed";
        } else {
          this.errorMessage = "Please fill out all fields correctly";
        }
        return;
      }
      
      this.errorMessage = "";
      this.loading = true;
      
      try {
        const tenant = this.email.split('@')[1].split('.')[0];
        
        const response = await axiosInstance.post(
          "/auth/signup/instructor",
          {
            email: this.email,
            password: this.password,
            firstName: this.firstName,
            lastName: this.lastName,
            school: this.school,
            preferredTitle: this.effectiveTitle
          },
          {
            headers: {
              'X-Tenant': tenant
            }
          }
        );
        
        // Show success message
        this.currentStep = 4;
        
        // Start countdown for automatic redirect
        this.startRedirectCountdown();
      } catch (error) {
        console.error("Error creating account", error);
        if (error.response) {
          if (error.response.status === 403) {
            this.errorMessage = "Email not verified. Please start over.";
            setTimeout(() => {
              this.currentStep = 1;
            }, 2000);
          } else if (error.response.status === 409) {
            this.errorMessage = "Email already registered.";
          } else {
            this.errorMessage = error.response.data.message || "Failed to create account.";
          }
        } else {
          this.errorMessage = "Network error. Please try again.";
        }
      } finally {
        this.loading = false;
      }
    },
    
    startRedirectCountdown() {
      // Clear any existing timer
      if (this.redirectTimer) {
        clearInterval(this.redirectTimer);
      }
      
      this.redirectCountdown = 5; // Reset to 5 seconds
      
      // Start a new timer
      this.redirectTimer = setInterval(() => {
        this.redirectCountdown--;
        
        if (this.redirectCountdown <= 0) {
          clearInterval(this.redirectTimer);
          this.goToDashboard();
        }
      }, 1000);
    },
    
    goToDashboard() {
      // Clear the timer if it's still running
      if (this.redirectTimer) {
        clearInterval(this.redirectTimer);
      }
      
      // Redirect to dashboard
      this.$router.push("/instructor/dashboard");
    },
    
    selectTitle(title) {
      this.preferredTitle = title;
      if (title !== 'Other') {
        this.customTitle = '';
      }
    },
    
    getTitleSliderPosition() {
      switch(this.preferredTitle) {
        case 'Ma\'am': return 0;
        case 'Sir': return 100;
        case 'Other': return 200;
        default: return 0;
      }
    },
  },
};
</script>

<style scoped>
.main-container {
  margin: 2vh 2vw;
  font-family: var(--main-font);
}

.spinner {
  margin: 20vh auto;
}

.loginContainer {
  margin: auto;
  display: flex;
  width: 50vw;
  height: 70vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: all ease 0.3s;
  font-size: 1vw;  
}

.sliding-button {
  position: relative;
  width: 25vw;
  height: 5vh;
  margin: 2vh auto;
  background-color: var(--primary);
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.options {
  display: flex;
  height: 5vh;
  color: var(--secondary);
}

.option {
  flex: 1;
  display: flex;
  height: 5vh;
  justify-content: center;
  align-items: center;
  transition: color 0.3s;
  z-index: 2;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: var(--classi2);
  border-radius: 2rem;
  transition: transform 0.3s;
  z-index: 1;
  width: 50%;
}

.moved {
  transform: translateX(100%);
}

input {
  width: 25vw;
  height: 5vh;
  margin: 1rem 0rem;
  padding: 1rem 3rem;
  background-color: var(--background-color);
  color: var(--primary);
  border: 0.15rem solid var(--primary);
  border-radius: 1rem;
  font-size: 1vw;
  outline: none;
  display: block;
}

input:focus {
  box-shadow: none;
  border: 0.15rem solid var(--classi2);
}

.disabled-input {
  background-color: #f0f0f0;
  color: #666;
  cursor: not-allowed;
}

.verification-code-container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.verification-digit {
  width: 3rem;
  height: 4rem;
  text-align: center;
  font-size: 1.5rem;
  padding: 0;
  border-radius: 0.5rem;
}

.preference-container {
  width: 25vw;
  margin: 1rem 0;
}

.preference-container label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--primary);
  text-align: center;
}

.title-slider-container {
  margin: 1rem 0;
}

.title-slider {
  position: relative;
  width: 100%;
  height: 5vh;
  background-color: var(--primary);
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: var(--box-shadow);
}

.title-options {
  display: flex;
  height: 100%;
  position: relative;
  z-index: 2;
}

.title-option {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary);
  cursor: pointer;
  transition: color 0.3s;
}

.title-option.active {
  color: white;
}

.title-slider-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 33.33%;
  height: 100%;
  background-color: var(--classi2);
  border-radius: 2rem;
  transition: transform 0.3s ease;
  z-index: 1;
}

.custom-title-input {
  margin-top: 0.5rem;
}

button {
  width: 25vw;
  height: 5vh;
  margin: 1vh 0vw;
  padding: 1vh 2vw;
  background-color: var(--classi2);
  color: white;
  border: none;
  border-radius: 1rem;
  font-size: 1vw;
  display: block;
}

button:hover {
  cursor: pointer;
  opacity: 0.6;
}

.secondary-button {
  background-color: var(--primary);
  margin-top: 0.5rem;
}

.button-spacer {
  height: 1rem;
}

h2 {  
  color: var(--primary);
  margin-bottom: 2vh;
  text-align: center;
}

p {
  color: var(--text-color);
  text-align: center;
  line-height: 1.5;
}

.login-link {
  margin-top: 1rem;
  color: var(--text-color);
  text-align: center;
}

.login-link a {
  color: var(--classi2);
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.password-requirements, .password-match {
  width: 25vw;
  margin: 0.5rem 0;
  font-size: 0.9vw;
  color: #666;
}

.password-requirements p, .password-match p {
  margin: 0.2rem 0;
  text-align: left;
}

.valid {
  color: var(--classi2);
}

.all-valid {
  color: var(--classi2);
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.create-account-button {
  position: relative;
}

.create-account-button:hover {
  opacity: 1 !important;
}

.validation-tooltip {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translate(-50%, -100%);
  background-color: var(--secondary);
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 0.5rem;
  padding: 1rem;
  width: 300px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: left;
  font-size: 0.8rem;
}

.validation-tooltip h4 {
  color: var(--primary);
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.validation-tooltip ul {
  margin: 0;
  padding-left: 1.2rem;
  list-style-type: disc;
}

.validation-tooltip ul ul {
  margin-top: 0.3rem;
  list-style-type: circle;
}

.validation-tooltip li {
  margin-bottom: 0.3rem;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
  border-left: 4px solid #c62828;
  width: 25vw;
}

.info-text {
  font-size: 0.8rem;
  color: #666;
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: center;
}

.timer-container {
  margin: 10px 0;
  text-align: center;
}

.timer {
  font-weight: bold;
  color: var(--classi2);
  font-size: 1.1rem;
}

.success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
}

.success-message {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.success-icon {
  font-size: 4rem;
  color: var(--classi2);
  margin: 1rem 0;
  background-color: rgba(0, 200, 83, 0.1);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
}

.primary-button {
  width: 10vw;
  background-color: var(--classi2);
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin: 1rem auto;
  transition: background-color 0.3s;
}

.primary-button:hover {
  background-color: var(--primary);
}

.success-message h2 {
  color: var(--classi2);
  margin-bottom: 1rem;
}

.countdown-text {
  margin: 1rem 0;
}

.countdown {
  font-weight: bold;
  color: var(--classi2);
  font-size: 1.2rem;
}
</style> 