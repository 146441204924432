<template>
  <div class="scratch-form">
    <div class="step-indicators">
      <div v-for="step in 3" :key="step" :class="['step-indicator', { active: currentStep === step - 1 }]"></div>
    </div>
    <div class="sliding-menu-container">
      <div class="sliding-menu" :style="{ transform: `translateX(-${currentStep * 33.33}%)` }">
        <!-- Step 1: Basic Information -->
        <div class="step">
          <div class="step-content">
            <p class="step-heading">Assignment Details</p>
            <div class="form-group">
              <p class="step-field">Title</p>
              <input type="text" :value="title" @input="$emit('update:title', $event.target.value)" id="title" required
                placeholder="E.g. Economics test #2" class="input-field" />
            </div>
            <div class="form-group">
              <p class="step-field">Due Date</p>
              <input type="date" :value="dueDate" @input="$emit('update:due-date', $event.target.value)" id="dueDate"
                class="date-input input-field" required />
            </div>
            <div class="form-group">
              <p class="step-field">Description/Extra Instructions (Optional)</p>
              <textarea :value="description" @input="$emit('update:description', $event.target.value)" id="description"
                placeholder="E.g. Syllabus: chapters 1-3, Extra credit for question 5"
                class="input-field description-area"></textarea>
            </div>
            <div class="nav-buttons" style="justify-content: flex-end">
              <button @click="$emit('next-step')" class="next-btn">
                Next
                <span class="material-symbols-outlined">arrow_forward</span>
              </button>
            </div>
          </div>
        </div>

        <!-- Step 2: Add Questions -->
        <div class="step step-overflow">
          <div class="step-content">
            <p class="step-heading">Add Questions</p>

            <div class="questions-container">
              <!-- Question navigation sidebar for step 2 -->
              <div v-if="currentStep === 1" class="question-nav-sidebar">
                <div v-for="(question, index) in questions" :key="index" class="question-nav-item"
                  :class="{ active: currentQuestionIndex === index }" @click="scrollToQuestion(index)">
                  {{ index + 1 }}
                </div>
              </div>
              
              <div v-for="(question, index) in questions" :key="index" class="question-item" :ref="'question-' + index">
                <div class="question-row">
                  <div class="question-number">Question {{ index + 1 }}</div>
                  <div class="question-actions">
                    <div class="marks-container">
                      Marks:
                      <input type="number" v-model="questions[index].marks" class="marks-picker" min="0" step="0.25"
                        @input="updateQuestions()" />
                    </div>
                    <span class="material-symbols-outlined remove-icon"
                      @click="$emit('remove-question', index)">delete</span>
                  </div>
                </div>
                <p class="section-label">Question</p>
                <TeacherTiptap controlBgColor="white" v-model:content="questions[index].content" :editable="true" class="question-editor"
                  @update:content="updateQuestions()" />
                
                <div class="expandable-section">
                  <div class="section-header" @click="toggleSection(index, 'showAnswer')">
                    <span class="material-symbols-outlined">
                      {{ questions[index].showAnswer ? 'expand_less' : 'expand_more' }}
                    </span>
                    <p class="section-label">Correct Answer (Optional)</p>
                  </div>
                  <div v-if="questions[index].showAnswer" class="section-content">
                    <TeacherTiptap controlBgColor="white"  v-model:content="questions[index].answer" :editable="true" class="answer-editor"
                      @update:content="updateQuestions()" placeholderText="Add correct answer here..." />
                  </div>
                </div>
                
                <div class="expandable-section">
                  <div class="section-header" @click="toggleSection(index, 'showInstructions')">
                    <span class="material-symbols-outlined">
                      {{ questions[index].showInstructions ? 'expand_less' : 'expand_more' }}
                    </span>
                    <p class="section-label">Grading Instructions (Optional)</p>
                  </div>
                  <div v-if="questions[index].showInstructions" class="section-content">
                    <TeacherTiptap controlBgColor="white"  v-model:content="questions[index].gradingInstructions" :editable="true" class="instructions-editor"
                      @update:content="updateQuestions()" placeholderText="Add grading instructions here..." />
                  </div>
                </div>
              </div>
              <button @click="$emit('add-question')" class="add-btn">
                <span class="material-symbols-outlined">add</span>
              </button>
            </div>

            <div class="nav-buttons">
              <button @click="$emit('prev-step')" class="prev-btn">
                <span class="material-symbols-outlined">arrow_back</span>
                Previous
              </button>
              <button @click="$emit('next-step')" class="next-btn">
                Preview
                <span class="material-symbols-outlined">arrow_forward</span>
              </button>
            </div>
          </div>
        </div>

        <!-- Step 3: Preview -->
        <div class="step">
          <div class="step-content">            
            
            <div class="preview-container">
              <AssignmentPreview
                :title="title"
                :dueDate="dueDate"
                :questions="questions"
                :isFromDocument="false"
                :isEditable="false"
                :showNavSidebar="true"
                :showHeader="true"
                @update:questions="$emit('update:questions', $event)"
                @create-assignment="$emit('create-assignment')"
              />
            </div>
            
            <div class="nav-buttons">
              <button @click="$emit('prev-step')" class="prev-btn">
                <span class="material-symbols-outlined">arrow_back</span>
                Previous
              </button>
              <button @click="$emit('create-assignment')" class="create-btn">
                Create Assignment
                <span class="material-symbols-outlined">check</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeacherTiptap from "../TeacherTiptap.vue";
import AssignmentPreview from "./AssignmentPreview.vue";

export default {
  name: 'ScratchAssignmentForm',
  components: {
    TeacherTiptap,
    AssignmentPreview
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    dueDate: {
      type: [String, Date, null],
      default: null
    },
    currentStep: {
      type: Number,
      default: 0
    },
    questions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentQuestionIndex: 0
    }
  },
  methods: {
    updateQuestions() {
      this.$emit('update:questions', [...this.questions]);
    },
    scrollToQuestion(index) {
      this.currentQuestionIndex = index;
      const element = this.$refs[`question-${index}`]?.[0];
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    toggleSection(index, section) {
      const updatedQuestion = { ...this.questions[index] };
      
      updatedQuestion[section] = !updatedQuestion[section];
      
      const updatedQuestions = [...this.questions];
      updatedQuestions[index] = updatedQuestion;
      
      this.$emit('update:questions', updatedQuestions);
    },
    hasContent(content) {
      if (!content) return false;
      if (content.content && content.content.length > 0) {
        return content.content.some(node => 
          (node.content && node.content.some(child => child.text && child.text.trim().length > 0)) || 
          (node.text && node.text.trim().length > 0)
        );
      }
      return false;
    }
  }
}
</script>

<style scoped>
.scratch-form {
  width: 100%;
  margin: auto;
  position: relative;
}

.step-indicators {
  display: flex;
  justify-content: center;
}

.step-indicator {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  background-color: lightgrey;
  margin: 0 0.5vw;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.step-indicator.active {
  background-color: var(--classi2);
  transform: scale(1.2);
}

.sliding-menu-container {
  overflow-x: hidden;
  position: relative;
  height: 100%;
}

.sliding-menu {
  display: flex;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  width: 300%;
}

.step {
  width: 33.33%;
  height: 100%;
  padding-bottom: 0;
  overflow: auto;
}

.step-content {
  width: 85%;
  margin: 0 auto;
  padding: 1vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.5s ease-out;
}

.step-overflow {
  overflow-y: auto;
}

.step-heading {
  font-size: 1.7vw;
  margin-bottom: 1vh;
}

.step-field {
  padding-bottom: 0.5vh;
  font-size: 1.1vw;
}

.form-group {
  margin-bottom: 2vw;
}

.input-field {
  width: 100%;
  padding: 1vw;
  border-radius: 1rem;
  border: none;
  font-size: 1.1vw;
  background-color: var(--secondary);
  color: var(--primary);
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease;
}

.input-field:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--classi2);
}

/* Add text-transform to title input */
#title {
  text-transform: capitalize;
}

/* Add text-transform to description textarea */
#description {
  text-transform: capitalize;
}

.description-area {
  min-height: 15vh;
  resize: vertical;
}

.nav-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 2vh;
}

.next-btn,
.prev-btn,
.create-btn {
  padding: 1vw 2vw;
  border: none;
  border-radius: 1rem;
  font-size: 1vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  transition: all 0.3s ease;
  box-shadow: var(--box-shadow);
}

.next-btn,
.create-btn {
  background-color: var(--classi2);
  color: white;
}

.next-btn:hover,
.create-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.prev-btn {
  background-color: var(--secondary);
  color: var(--primary);
}

.questions-container {
  height: 53vh;
  overflow-y: auto;
  margin-bottom: 2vh;
  position: relative;
  padding-left: 0;
  width: 100%;
}

.preview-container {
  height:62vh;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
}

.question-item {
  margin-bottom: 3vh;
  padding: 1vw;
  border-radius: 1rem;
  background-color: var(--secondary);
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease;
  animation: fadeIn 0.4s ease-out;
  margin-left: 0;
  width: 100%;
}

.question-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.question-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5vh;
}

.question-number {
  font-size: 1.2vw;
  color: var(--classi2);
}

.question-actions {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.marks-container {
  display: flex;
  align-items: center;
  gap: 0.5vw;
  font-size: 1vw;
  color: var(--classi2);
}

.marks-picker {
  width: 5vw;
  padding: 0.5vh;
  background-color: var(--secondary);
  color: var(--classi2);
  border: 1px solid var(--classi2);
  border-radius: 0.5rem;
  font-size: 1vw;
  text-align: center;
}

.question-editor {
  min-height: 15vh;
  max-height: 32vh;
  border-radius: 0.5rem;
  font-size: 1vw;
  overflow: hidden;
}

.remove-icon {
  cursor: pointer;
  padding: 0.8vw;
  font-size: 1vw;
  border: 0.1rem solid var(--classi2);
  border-radius: 50%;
  color: var(--classi2);
  background-color: var(--secondary);
  transition: all 0.3s ease;
}

.remove-icon:hover {
  background-color: var(--classi2);
  color: white;
}

.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2vh auto;
  width: 3vw;
  height: 3vw;
  border: none;
  border-radius: 50%;
  background-color: var(--classi2);
  color: white;
  font-size: 1.3vw;
  cursor: pointer;
  box-shadow: var(--box-shadow);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.add-btn:hover {
  transform: scale(1.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.section-label {
  font-size: 1.1vw;
  color: var(--classi2);
  margin-bottom: 0.5vh;
  font-weight: 500;
}

.expandable-section {
  margin-top: 1.5vh;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1vh;
}

.section-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5vh 0;
  transition: all 0.3s ease;
}

.section-header:hover {
  color: var(--classi2);
}

.section-header .material-symbols-outlined {
  margin-right: 0.5vw;
  font-size: 1.2vw;
  color: var(--classi2);
}

.section-content {
  animation: fadeIn 0.3s ease-out;
  margin-top: 0.5vh;
}

.answer-editor, .instructions-editor {
  min-height: 10vh;
  max-height: 20vh;
  border-radius: 0.5rem;
  font-size: 1vw;
  overflow: hidden;
  background-color: rgba(var(--classi2-rgb), 0.05);
}

/* Navigation sidebar for step 2 */
.question-nav-sidebar {
  position: fixed;
  left: max(20px, calc(12.5% - 60px));
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 1vh;
  z-index: 100;
  max-height: 50vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 1vh;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.question-nav-sidebar::-webkit-scrollbar {
  display: none;
}

.question-nav-item {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--secondary);
  color: var(--classi2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  border: 2px solid var(--classi2);
  margin: 0.5vh 0;
  flex-shrink: 0;
}

.question-nav-item:hover {
  transform: scale(1.1);
  background-color: var(--classi2);
  color: white;
}

.question-nav-item.active {
  background-color: var(--classi2);
  color: white;
  transform: scale(1.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>