<template>
  <div class="chart-wrapper">    
    <Radar :data="radarData" :options="radarOptions" />
  </div>
</template>

<script>
import { Radar } from 'vue-chartjs';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export default {
  name: "SubjectStrengthsRadar",
  components: {
    Radar
  },
  props: {
    assignments: {
      type: Array,
      required: true
    },
    classAverages: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    radarData() {
      // Process assignments to get average scores by course
      const courseAverages = {};
      const courseColors = {};
      
      this.assignments.forEach(assignment => {
        const percentage = (parseFloat(assignment.marks_obtained) / assignment.marks_total) * 100;
        const course = assignment.course;
        
        // Get color for this course
        if (!courseColors[course]) {
          courseColors[course] = this.getCourseColor(course);
        }
        
        if (!courseAverages[course]) {
          courseAverages[course] = {
            total: percentage,
            count: 1
          };
        } else {
          courseAverages[course].total += percentage;
          courseAverages[course].count++;
        }
      });
      
      const labels = Object.keys(courseAverages);
      const data = labels.map(course => 
        courseAverages[course].total / courseAverages[course].count
      );
      
      // Store subject averages for tooltip access
      this.studentAverages = {};
      labels.forEach((course, i) => {
        this.studentAverages[course] = data[i];
      });
      
      // Create a single dataset with your current performance
      const currentDataset = {
        label: 'Your Performance',
        data,
        fill: true,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgb(54, 162, 235)',
        pointBackgroundColor: labels.map(course => courseColors[course]),
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(54, 162, 235)'
      };

      // Get class averages for each subject
      const classAverageData = labels.map(course => 
        this.classAverages[course] || 75 // Default to 75% if no data available
      );
      
      const classAverageDataset = {
        label: 'Class Average',
        data: classAverageData,
        fill: true,
        backgroundColor: 'rgba(255, 99, 132, 0.1)',
        borderColor: 'rgba(255, 99, 132, 0.5)',
        borderDash: [5, 5],
        pointRadius: 0
      };
      
      return {
        labels,
        datasets: [currentDataset, classAverageDataset]
      };
    },
    radarOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            borderWidth: 3
          }
        },
        scales: {
          r: {
            angleLines: {
              display: true,
              color: 'rgba(0,0,0,0.1)'
            },
            suggestedMin: 0,
            suggestedMax: 100,
            ticks: {
              stepSize: 20,
              callback: (value) => value + '%',
              backdropColor: 'transparent'
            },
            grid: {
              circular: true
            },
            pointLabels: {
              font: {
                size: 12,
                weight: 'bold'
              }
            }
          }
        },
        plugins: {
          legend: {
            position: 'right'
          },
          tooltip: {
            callbacks: {
              title: (tooltipItems) => {
                return tooltipItems[0].label;
              },
              label: (context) => {
                const subject = context.label;
                const value = context.raw.toFixed(1);
                
                // If this is Your Performance dataset
                if (context.datasetIndex === 0) {
                  const classAvg = this.classAverages[subject] ? this.classAverages[subject].toFixed(1) + '%' : 'N/A';
                  
                  return [
                    `Your Performance: ${value}%`,
                    `Class Average: ${classAvg}`
                  ];
                }
                
                return `Class Average: ${value}%`;
              }
            }
          }
        }
      };
    }
  },
  data() {
    return {
      studentAverages: {}
    };
  },
  methods: {
    getCourseColor(course) {
      // Reuse existing color scheme
      const colors = {
        Physics: "#3F51B5",
        History: "#673AB7",
        English: "#009688",
        Science: "#00BCD4",
        Mathematics: "#4CAF50",
      };
      
      return colors[course] || '#888';
    }
  }
};
</script>

<style scoped>
.chart-wrapper {
  height: 100%;
  width: 100%;
}

h3 {  
  font-weight: normal;
  font-size: 1.1rem;
}
</style> 