import { createRouter, createWebHistory } from "vue-router";
import { useStudentStore } from "@/store/studentData";
import { useInstructorStore } from "@/store/instructorData";
import AuraChatbot from "@/views/AuraChatbot.vue";
import Home from "@/views/Home.vue";
import TextbookChat from "@/views/Student/TextbookChat.vue";
import InstructorGrading from "@/views/Instructor/InstructorGrading.vue";
import StudentDashboard from "@/views/Student/StudentDashboard.vue";
import StudentClasses from "@/views/Student/StudentClasses.vue";
import StudentSchedule from "@/views/Student/StudentSchedule";
import StudentGrades from "@/views/Student/StudentGrades";
import InstructorAITools from "@/views/Instructor/InstructorAITools";
import LoginPage from "@/views/LoginPage.vue";
import StudentProfile from "@/views/Student/StudentProfile.vue";
import StudentAssignments from "@/views/Student/StudentAssignments.vue";
import StudentCalendar from "@/views/Student/StudentCalendar.vue";
import StudentTimetable from "@/views/Student/StudentTimetable.vue";
import StudentCourse from "@/components/StudentCourse.vue";
import StudentAssignment from "@/components/StudentAssignment.vue";
import InstructorDashboard from "@/views/Instructor/InstructorDashboard.vue";
import InstructorAssignments from "@/views/Instructor/InstructorAssignments.vue";
import MarkingSchemeStep from "@/components/MarkingSchemeStep.vue";
import SubmissionStep from "@/components/SubmissionStep.vue";
import CorporateWebsite from "@/views/CorporateWebsite.vue"
import IsolatedChat from "@/views/Student/IsolatedChat.vue";
import InstructorChat from "@/views/Instructor/InstructorChat.vue";
import SignupPage from "@/views/SignupPage.vue";
import InstructorCourses from "@/views/Instructor/InstructorCourses.vue";
import AssignmentCreationPage from "@/components/assignment/AssignmentCreationPage.vue";
// import GradingStep from "@/components/GradingStep.vue";

const studentRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: StudentDashboard,
    meta: { requiresStudentAuth: true },
  },
  {
    path: "/classes",
    name: "StudentClasses",
    component: StudentClasses,
    meta: { requiresStudentAuth: true },
    children: [
      {
        path: ":courseCode",
        name: "StudentCourse",
        component: StudentCourse,
        props: (route) => ({
          courseCode: route.params.courseCode,
        }),
      },
    ],
  },
  {
    path: "/assignments",
    name: "StudentAssignments",
    component: StudentAssignments,
    meta: { requiresStudentAuth: true },
    props: (route) => ({
      selectedFilters: route.query.selectedFilters,
    }),
    children: [
      {
        path: ":assignmentId",
        name: "StudentAssignment",
        component: StudentAssignment,
        props: (route) => ({
          assignmentId: route.params.assignmentId,
        }),
      },
    ],
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: StudentSchedule,
    meta: { requiresStudentAuth: true },
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: StudentCalendar,
    meta: { requiresStudentAuth: true },
  },
  {
    path: "/grades",
    name: "Grades",
    component: StudentGrades,
    meta: { requiresStudentAuth: true },
  },
  {
    path: "/timetable",
    name: "Timetable",
    component: StudentTimetable,
    meta: { requiresStudentAuth: true },
  },
  {
    path: "/profile",
    name: "StudentProfile",
    component: StudentProfile,
    meta: { requiresStudentAuth: true },
  },
  {
    path: "/chat",
    name: "IsolatedChat",
    component: IsolatedChat,
    meta: { requiresStudentAuth: true },
  },
  {
    path: "/chatbot",
    name: "AURA Chatbot",
    component: TextbookChat,
    meta: { requiresStudentAuth: true },
  },
];

const instructorRoutes = [
  {
    path: "/grader",
    name: "InstructorGrading",
    component: InstructorGrading,
    meta: { requiresInstructorAuth: true },
    children: [
      {
        path: ":assignmentId",
        name: "SubmissionStep",
        component: SubmissionStep,
        props: (route) => ({
          assignmentId: route.params.assignmentId,
        }),
      },
    ],
  },
  {
    path: "/assignments",
    name: "InstructorAssignments",
    component: InstructorAssignments,
    meta: { requiresInstructorAuth: true },
    children: [
      {
        path: "create",
        name: "AssignmentCreation",
        component: AssignmentCreationPage,
      },
      {
        path: ":assignmentId",
        name: "InstructorAssignment",
        component: SubmissionStep,
        props: (route) => ({
          assignmentId: route.params.assignmentId,
        }),
      },
    ],
  },
  {
    path: "/chat",
    name: "InstructorChat",
    component: InstructorChat,
    meta: { requiresInstructorAuth: true },
  },
  {
    path: "/dashboard",
    name: "InstructorDashboard",
    component: InstructorDashboard,
    meta: { requiresInstructorAuth: true },
  },
  {
    path: "/courses",
    name: "InstructorCourses",
    component: InstructorCourses,
    meta: { requiresInstructorAuth: true },
  },
  {
    path: "/calendar",
    name: "InstructorCalendar",
    component: () => import("@/views/Instructor/InstructorCalendar.vue"),
    meta: { requiresInstructorAuth: true },
  },
  {
    path: "/analytics",
    name: "InstructorAnalytics",
    component: () => import("@/views/Instructor/InstructorAnalytics.vue"),
    meta: { requiresInstructorAuth: true },
  },
  // {
  //   path: "/AITools",
  //   name: "AITools",
  //   component: InstructorAITools,
  // },
];

// Function to add prefix to routes
function addPrefixToRoutes(routes, prefix) {
  return routes.map((route) => ({
    ...route,
    path: `${prefix}${route.path}`,
  }));
}

// Create the router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      // redirect: "/login",      
      component: CorporateWebsite,
    },
    {
      path: "/login",
      name: "Login Page",
      component: LoginPage,
    },
    {
      path: "/instructor/signup",
      name: "Signup Page",
      component: SignupPage,
    },
    // {
    //   path: "/assignments",
    //   name: "StudentAssignments",
    //   component: StudentAssignments,
    //   meta: { requiresStudentAuth: true },
    //   props: (route) => ({
    //     selectedFilters: route.query.selectedFilters,
    //   }),
    //   children: [
    //     {
    //       path: ":assignmentId",
    //       name: "StudentAssignment",
    //       component: StudentAssignment,
    //       props: (route) => ({
    //         assignmentId: route.params.assignmentId,
    //       }),
    //     },
    //   ],
    // },

    ...addPrefixToRoutes(studentRoutes, "/student"),
    ...addPrefixToRoutes(instructorRoutes, "/instructor"),

    // Catch-all route
    {
      path: "/:pathMatch(.*)*",
      name: "Login Page",
      component: LoginPage,
    },
  ],
});

// Track initial page load
let isInitialPageLoad = true;

// Route guard to check authentication
router.beforeEach(async (to, from, next) => {
  const studentStore = useStudentStore();
  const instructorStore = useInstructorStore();

  const requiresStudentAuth = to.matched.some(
    (record) => record.meta.requiresStudentAuth
  );
  const requiresInstructorAuth = to.matched.some(
    (record) => record.meta.requiresInstructorAuth
  );

  if (requiresStudentAuth) {
    // Only validate on initial page load or if not authenticated
    if (isInitialPageLoad || !studentStore.isAuthenticated) {
      try {
        const isAuthenticated = await studentStore.checkAuth();
        if (isAuthenticated) {
          next();
        } else {
          next("/login");
        }
      } catch (error) {
        console.error("Auth check failed:", error);
        next("/login");
      }
    } else {
      // Skip validation on subsequent navigations if already authenticated
      next();
    }
  } else if (requiresInstructorAuth) {
    // Only validate on initial page load or if not authenticated
    if (isInitialPageLoad || !instructorStore.isAuthenticated) {
      try {
        const isAuthenticated = await instructorStore.checkAuth();
        if (isAuthenticated) {
          next();
        } else {
          next("/login");
        }
      } catch (error) {
        console.error("Auth check failed:", error);
        next("/login");
      }
    } else {
      // Skip validation on subsequent navigations if already authenticated
      next();
    }
  } else {
    // For non-protected routes
    next();
  }
  
  // After first navigation, set initial load to false
  isInitialPageLoad = false;
});

export default router;
