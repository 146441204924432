<template>
  <div class="document-form">
    <div v-if="!viewQuestions" class="uploader">
      <p v-if="!markingSchemeUrl" class="step-description">
        Upload a marking scheme in PDF form that will be used to guide the
        autograder.<br/>This could be typed or handwritten (scanned).
      </p>
      <div v-if="markingSchemeUrl" style="display: flex; justify-content: space-around; flex-wrap: wrap;">
        <div>
          <p class="small-label">Title</p>
          <input 
            v-model="localMarkingSchemeTitle" 
            type="text" 
            placeholder="E.g. Economics test #2"
            class="title-input" 
            @input="$emit('update:marking-scheme-title', localMarkingSchemeTitle)"
          />
        </div>
        <div>
          <p class="small-label">Due Date</p>
          <input 
            type="date" 
            v-model="localDueDate" 
            id="dueDate" 
            placeholder="Due Date" 
            required 
            class="date-input" 
            @input="$emit('update:due-date', localDueDate)"
          />
        </div>
        <div>
          <p class="small-label">
            Processing
            <Tooltip
              text="Express processing mode is much faster, but only supports documents where each question is fully contained on a single page."
              custom-styles="width: 20vw; padding: 1vh">
              <span class="material-symbols-outlined info-icon">
                info
              </span>
            </Tooltip>
          </p>
          <div class="processing-slider">
            <div class="processing-options">
              <div 
                class="processing-option" 
                :class="{ active: localExpressProcessing === 'normal' }"
                @click="updateProcessingMode('normal')"
              >
                Normal
              </div>
              <div 
                class="processing-option" 
                :class="{ active: localExpressProcessing === 'express' }"
                @click="updateProcessingMode('express')"
              >
                Express
              </div>
            </div>
            <div class="processing-slider-indicator" :class="{ express: localExpressProcessing === 'express' }"></div>
          </div>
        </div>
      </div>
      <FileUploader v-if="!markingSchemeUrl" @files-selected="handleFilesSelected" />
      <FileDisplay 
        v-else 
        :fileUrl="markingSchemeUrl" 
        :isPdf="isMarkingSchemePdf" 
        :loading="markingSchemeLoading"
        :success="markingSchemeSuccess" 
        :processingProgress="processingProgress" 
        fileType="Marking Scheme"
        @remove="$emit('remove-file')" 
        @continue="$emit('go-to-question-viewer')" 
      />
    </div>
    <div v-else class="step-content">      
      
      <div class="preview-container">
        <AssignmentPreview
          :title="markingSchemeTitle"
          :dueDate="dueDate"
          :parsedQuestions="parsedQuestions"
          :editMode="editMode"
          :isFromDocument="true"
          :isEditable="true"
          :showNavSidebar="true"
          :showHeader="true"
          @update:parsed-questions="$emit('update:parsed-questions', $event)"
          @update:edit-mode="$emit('update:edit-mode', $event)"
          @save-edits="handleQuestionEdit"
          @create-assignment="$emit('create-assignment')"
        />
      </div>
      
      <div class="nav-buttons">
        <button @click="$emit('go-back')" class="prev-btn">
          <span class="material-symbols-outlined">arrow_back</span>
          Previous
        </button>
        <button @click="$emit('create-assignment')" class="create-btn">
          Create Assignment
          <span class="material-symbols-outlined">check</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TeacherTiptap from "../TeacherTiptap.vue";
import FileUploader from "../FileUploader.vue";
import FileDisplay from "../FileDisplay.vue";
import Tooltip from '../Tooltip.vue';
import AssignmentPreview from './AssignmentPreview.vue';

export default {
  name: 'AssignmentFromDocument',
  components: {
    TeacherTiptap,
    FileUploader,
    FileDisplay,
    Tooltip,
    AssignmentPreview
  },
  props: {
    markingSchemeUrl: {
      type: String,
      default: null
    },
    isMarkingSchemePdf: {
      type: Boolean,
      default: false
    },
    markingSchemeTitle: {
      type: String,
      default: ''
    },
    dueDate: {
      type: [String, Date, null],
      default: null
    },
    expressProcessing: {
      type: String,
      default: 'normal'
    },
    markingSchemeLoading: {
      type: Boolean,
      default: false
    },
    markingSchemeSuccess: {
      type: Boolean,
      default: false
    },
    processingProgress: {
      type: Object,
      default: null
    },
    viewQuestions: {
      type: Boolean,
      default: false
    },
    parsedQuestions: {
      type: Object,
      default: () => ({})
    },
    editMode: {
      type: [Number, String],
      default: -1
    },
    currentFiles: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      localMarkingSchemeTitle: this.markingSchemeTitle,
      localDueDate: this.dueDate,
      localExpressProcessing: this.expressProcessing
    };
  },
  computed: {
    totalMarks() {
      let total = 0;
      for (const question in this.parsedQuestions) {
        if (question !== 'grading') {
          total += this.parsedQuestions[question].marks_possible;
        }
      }
      return total;
    }
  },
  watch: {
    markingSchemeTitle(newVal) {
      this.localMarkingSchemeTitle = newVal;
    },
    dueDate(newVal) {
      this.localDueDate = newVal;
    },
    expressProcessing(newVal) {
      this.localExpressProcessing = newVal;
    }
  },
  methods: {
    handleFilesSelected(files) {
      this.$emit('load-marking-scheme', files);
      console.log(this.isMarkingSchemePdf);
    },
    updateProcessingMode(mode) {
      this.localExpressProcessing = mode;
      this.$emit('update:express-processing', mode);
    },
    handleQuestionEdit(questionNumber) {
      this.$emit('save-edits', questionNumber);
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  }
}
</script>

<style scoped>
.document-form {
  width: 100%;
  margin: auto;
  position: relative;
}

.uploader {
  width: 75%;
  margin: 0 auto;
  margin-top: 1vh;
}

.step-content {
  width: 85%;
  margin: 0 auto;
  padding: 1vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.5s ease-out;
}

.step-heading {
  font-size: 1.7vw;
  margin-bottom: 1vh;
}

.preview-container {
  height: 62vh;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  overflow: visible; 
}

.nav-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 2vh;
}

.prev-btn,
.create-btn {
  padding: 1vw 2vw;
  border: none;
  border-radius: 1rem;
  font-size: 1vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  transition: all 0.3s ease;
  box-shadow: var(--box-shadow);
}

.create-btn {
  background-color: var(--classi2);
  color: white;
}

.create-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.prev-btn {
  background-color: var(--secondary);
  color: var(--primary);
}

/* Uploader styles */
.document-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60vh;
}

.file-wrapper {
  position: relative;
  height: 55vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.pdf-display {
  margin: auto;
  height: 55vh;
  width: 40vw;
  border: none;
  overflow: auto;
}

.image-display {
  max-height: 55vh;
  max-width: 100%;
  object-fit: contain;
}

.display-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2vh;
}

.step-description {
  font-size: 1vw;
  color: var(--primary);
  text-align: center;
  margin-bottom: 4vh;
}

.small-label {
  margin: 0.5vh;
  font-size: 0.9vw;
  color: grey;
}

.title-input {
  font-size: 1.1vw;
  margin-bottom: 2vw;
  width: 15vw;
  padding: 0 1vw;
  height: 5vh;
  outline: none;
  border: none;
  border-radius: 1rem;
  text-transform: capitalize;
}

.date-input {
  font-size: 1.1vw;
  width: 15vw;
  padding: 0 1vw;
  height: 5vh;
  outline: none;
  border: none;
  border-radius: 1rem;
}

.processing-slider {
  position: relative;
  width: 100%;
  height: 5vh;
  background-color: var(--background-color);
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.processing-options {
  display: flex;
  height: 5vh;
}

.processing-option {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  font-size: 1vw;
  transition: color 0.3s;
  z-index: 2;
  padding: 0 1vw;
}

.processing-option.active {
  color: #fff;
}

.processing-slider-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: var(--classi2);
  border-radius: 1rem;
  transition: transform 0.3s;
  z-index: 1;
  width: 50%;
  transform: translateX(0%);
}

.processing-slider-indicator.express {
  transform: translateX(100%);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style> 