<template>
  <div class="assignment-preview">
    <div class="content-wrapper">
      <!-- Preview header -->
      <div class="preview-header" v-if="showHeader">
        <!-- <p class="step-heading">Preview <span class="step-description" v-if="isEditable">(Make any corrections if needed)</span></p> -->
        <div class="viewer-header">          
          <div class="title-date">
            <p class="preview-title">{{ title }}</p>
            <p class="preview-date">Due: {{ formatDate(dueDate) }}</p>
          </div>
          <p class="total-marks">Total Marks: {{ calculateTotalMarks() }}</p>
          <!-- <div class="continue-button" @click="$emit('create-assignment')">
            <span class="material-symbols-outlined">check</span>
          </div> -->
        </div>
      </div>
      
      <!-- Questions display section -->
      <div class="questions-scroll-container" ref="questionsContainer" @scroll="handleScroll">
        <div 
          v-for="(question, index) in questionsList" 
          class="question-container" 
          :key="index"
          :ref="'question-' + index"          
        >
          <div class="question-info">
            <div class="question-header">
              <div class="section-break"></div>
              <div class="question-header-content">
                <p class="graded-question-section">
                  Question {{ getQuestionNumber(question, index) }}
                </p>
                <div class="question-grade">
                  Marks:
                  <input 
                    v-if="isFromDocument"
                    v-model.number="question.marks_possible" 
                    type="number" 
                    :min="0" 
                    step="0.25"
                    @change="handleQuestionEdit(question)" 
                  />
                  <span v-else class="marks-viewer">{{ question.marks }}</span>
                </div>
              </div>
            </div>
            
            <!-- Question content -->
            <TeacherTiptap 
              class="wysiwyg" 
              :content="isFromDocument ? question.question : question.content"
              @update:content="updateQuestionContent(question, index, $event, 'question')"
              :editable="isEditable && (isFromDocument ? editMode == question.question_number : false)" 
            />
            
            <!-- Answer section -->
            <div class="question-header">
              <div class="section-break"></div>
              <p class="graded-question-section">Correct Answer</p>
            </div>
            <TeacherTiptap 
              class="wysiwyg" 
              :content="question.answer"
              @update:content="updateQuestionContent(question, index, $event, 'answer')"
              :editable="isEditable && (isFromDocument ? editMode == question.question_number : false)" 
            />
            
            <!-- Grading instructions section (if available) -->
            <div v-if="hasGradingInstructions(question)">
              <div class="question-header">
                <div class="section-break"></div>
                <p class="graded-question-section">Grading Instructions</p>
              </div>
              <TeacherTiptap 
                class="wysiwyg" 
                :content="isFromDocument ? question.grading_instructions : question.gradingInstructions"
                @update:content="updateQuestionContent(question, index, $event, 'instructions')"
                :editable="isEditable && (isFromDocument ? editMode == question.question_number : false)" 
              />
            </div>

            <!-- Edit buttons for document-based assignments -->
            <template v-if="isFromDocument && isEditable">
              <div v-if="editMode === question.question_number" style="display: flex; justify-content: space-around">
                <div class="edit-button" @click="$emit('save-edits', question.question_number)">
                  Save
                </div>
              </div>
              <div class="edit-button" @click="$emit('update:edit-mode', question.question_number)">
                Click here to edit
                <span class="material-symbols-outlined edit-icon">edit</span>
              </div>
            </template>
          </div>
        </div>        
      </div>
    </div>
    
    <!-- Question navigation sidebar -->
    <div class="question-nav-sidebar" v-if="showNavSidebar">
      <div 
        v-for="(question, index) in questionsList" 
        :key="index" 
        class="question-nav-item"
        :class="{ active: currentQuestionIndex === index }" 
        @click="scrollToQuestion(index)"
      >
        {{ getQuestionNumber(question, index) }}
      </div>
    </div>
  </div>
</template>

<script>
import TeacherTiptap from "../TeacherTiptap.vue";

export default {
  name: 'AssignmentPreview',
  components: {
    TeacherTiptap
  },
  props: {
    // Common props
    title: {
      type: String,
      required: true
    },
    dueDate: {
      type: [String, Date, null],
      default: null
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    
    // For document-based assignments
    parsedQuestions: {
      type: Object,
      default: () => ({})
    },
    editMode: {
      type: [Number, String],
      default: -1
    },
    isFromDocument: {
      type: Boolean,
      default: false
    },
    
    // For scratch-based assignments
    questions: {
      type: Array,
      default: () => []
    },
    showNavSidebar: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentQuestionIndex: 0,
      scrollTimeout: null,
      resizeTimeout: null
    };
  },
  computed: {
    questionsList() {
      if (this.isFromDocument) {
        return Object.values(this.parsedQuestions)
          .filter(question => question.question_number !== undefined)
          .sort((a, b) => a.question_number - b.question_number);
      } else {
        return this.questions;
      }
    }
  },
  methods: {
    scrollToQuestion(index) {
      this.currentQuestionIndex = index;
      const questionElement = this.$refs['question-' + index]?.[0];
      const container = this.$refs.questionsContainer;
      
      if (questionElement && container) {
        // Get the current scroll position
        const currentScrollTop = container.scrollTop;
        
        // Calculate the target scroll position
        const questionTop = questionElement.offsetTop;
        const containerTop = container.offsetTop;
        const targetScrollTop = questionTop - containerTop;
        
        // Add a small offset to prevent the question from being right at the top edge
        const scrollOffset = 20;
        const adjustedTargetScrollTop = Math.max(0, targetScrollTop - scrollOffset);
        
        // Implement smooth scrolling with the adjusted target
        this.smoothScrollTo(container, adjustedTargetScrollTop);
      }
    },
    smoothScrollTo(element, targetScrollTop, duration = 300) {
      const startScrollTop = element.scrollTop;
      const distance = targetScrollTop - startScrollTop;
      const startTime = performance.now();
      
      const animateScroll = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        
        if (elapsedTime < duration) {
          // Use easeInOutQuad easing function for smooth animation
          const progress = this.easeInOutQuad(elapsedTime / duration);
          element.scrollTop = startScrollTop + distance * progress;
          requestAnimationFrame(animateScroll);
        } else {
          element.scrollTop = targetScrollTop;
        }
      };
      
      requestAnimationFrame(animateScroll);
    },
    easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    handleScroll() {
      // Debounce the scroll event to improve performance
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout);
      }
      
      this.scrollTimeout = setTimeout(() => {
        this.updateActiveQuestionOnScroll();
      }, 50);
    },
    updateActiveQuestionOnScroll() {
      const container = this.$refs.questionsContainer;
      if (!container || this.questionsList.length === 0) return;
      
      const containerTop = container.scrollTop;
      const containerHeight = container.clientHeight;
      
      // Find which question is most visible in the viewport
      let maxVisibleQuestion = 0;
      let maxVisibleAmount = 0;
      
      for (let i = 0; i < this.questionsList.length; i++) {
        const questionElement = this.$refs['question-' + i]?.[0];
        if (!questionElement) continue;
        
        const questionTop = questionElement.offsetTop - container.offsetTop;
        const questionBottom = questionTop + questionElement.offsetHeight;
        
        // Calculate how much of the question is visible
        const visibleTop = Math.max(containerTop, questionTop);
        const visibleBottom = Math.min(containerTop + containerHeight, questionBottom);
        const visibleAmount = Math.max(0, visibleBottom - visibleTop);
        
        // If this question is more visible than any previous one, mark it as active
        if (visibleAmount > maxVisibleAmount) {
          maxVisibleAmount = visibleAmount;
          maxVisibleQuestion = i;
        }
        
        // Special case: if we're at the very top or bottom of the container
        if (i === 0 && containerTop <= questionTop) {
          maxVisibleQuestion = 0;
          break;
        }
        
        if (i === this.questionsList.length - 1 && containerTop + containerHeight >= questionBottom) {
          maxVisibleQuestion = this.questionsList.length - 1;
          break;
        }
      }
      
      // Update the active question if it changed
      if (this.currentQuestionIndex !== maxVisibleQuestion) {
        this.currentQuestionIndex = maxVisibleQuestion;
      }
    },
    getQuestionNumber(question, index) {
      return this.isFromDocument ? question.question_number : index + 1;
    },
    calculateTotalMarks() {
      if (this.isFromDocument) {
        let total = 0;
        for (const question in this.parsedQuestions) {
          if (question !== 'grading') {
            total += this.parsedQuestions[question].marks_possible;
          }
        }
        return total;
      } else {
        return this.questions.reduce((sum, q) => sum + Number(q.marks), 0);
      }
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    updateQuestionContent(question, index, content, field) {
      if (this.isFromDocument) {
        const updatedQuestions = { ...this.parsedQuestions };
        const questionNumber = question.question_number;
        
        // Map field names for document-based assignments
        const fieldMap = {
          'question': 'question',
          'answer': 'answer',
          'instructions': 'grading_instructions'
        };
        
        updatedQuestions[questionNumber][fieldMap[field]] = content;
        this.$emit('update:parsed-questions', updatedQuestions);
      } else {
        const updatedQuestions = [...this.questions];
        
        // Map field names for scratch-based assignments
        const fieldMap = {
          'question': 'content',
          'answer': 'answer',
          'instructions': 'gradingInstructions'
        };
        
        updatedQuestions[index][fieldMap[field]] = content;
        this.$emit('update:questions', updatedQuestions);
      }
    },
    handleQuestionEdit(question) {
      if (this.isFromDocument) {
        this.$emit('save-edits', question.question_number);
      }
    },
    hasGradingInstructions(question) {
      if (this.isFromDocument) {
        return question.grading_instructions && question.grading_instructions !== '';
      } else {
        return question.gradingInstructions && this.hasContent(question.gradingInstructions);
      }
    },
    hasContent(content) {
      if (!content) return false;
      if (content.content && content.content.length > 0) {
        return content.content.some(node => 
          (node.content && node.content.some(child => child.text && child.text.trim().length > 0)) || 
          (node.text && node.text.trim().length > 0)
        );
      }
      return false;
    },
    handleResize() {
      // Debounce resize events
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      
      this.resizeTimeout = setTimeout(() => {
        this.updateActiveQuestionOnScroll();
      }, 100);
    }
  },
  mounted() {
    // Initialize the active question based on scroll position
    this.$nextTick(() => {
      this.updateActiveQuestionOnScroll();
      
      // Add a window resize listener to update active question when window size changes
      window.addEventListener('resize', this.handleResize);
    });
  },
  beforeDestroy() {
    // Clean up event listeners
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    // Watch for changes in the questions list and update the active question
    questionsList: {
      handler() {
        this.$nextTick(() => {
          this.updateActiveQuestionOnScroll();
        });
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.assignment-preview {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: visible; 
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 1vw; 
  overflow: hidden; 
  height: 100%;
}

.preview-header {
  margin-bottom: 1vh;
  width: 100%;
  position: relative;
  z-index: 10; /* Ensure header stays above other elements */
}

.step-heading {
  font-size: 1.7vw;
  margin-bottom: 1vh;
}

.step-description {
  font-size: 1vw;
  color: var(--primary);
}

.viewer-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh;
}

.total-marks {
  font-size: 1.3vw;
  color: var(--classi2);
}

.title-date {
  /* text-align: center; */
  color: var(--classi2);
}

.preview-title {
  font-size: 1.4vw;
  font-weight: bold;
  margin-bottom: 0.5vh;
}

.preview-date {
  font-size: 1vw;
}

.continue-button {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  color: var(--classi2);
  border: 0.1rem solid var(--classi2);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 10;
}

.continue-button:hover {
  background-color: var(--classi2);
  color: white;
  transform: scale(1.1);
}

.questions-scroll-container {
  flex: 1;
  overflow-y: auto;
  padding: 0 0.5vw 10vh 0.5vw; /* Add significant bottom padding */
  height: 100%;
  width: 100%;
  margin-left: 0;
  position: relative;
}

.questions-scroll-container::-webkit-scrollbar {
  width: 0.5vw;
}

.questions-scroll-container::-webkit-scrollbar-track {
  background: var(--secondary);
  border-radius: 1rem;
}

.questions-scroll-container::-webkit-scrollbar-thumb {
  background: var(--classi2);
  border-radius: 1rem;
}

.questions-scroll-container::-webkit-scrollbar-thumb:hover {
  background: var(--classi2-dark);
}

.question-container {
  margin-bottom: 2vh;
  background-color: var(--secondary);
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  width: 100%; /* Ensure full width within the container */
}

.question-info {
  flex: 3;
  padding: 1.5vw 1vw;
  font-size: 1vw;
}

.question-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}

.section-break {
  width: 5%;
  height: 0.2vh;
  background-color: var(--classi2);
  margin-right: 1vw;
}

.question-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--classi2);
}

.graded-question-section {
  font-size: 1.3vw;
  color: var(--classi2);
}

.question-grade {
  font-size: 1.3vw;
  color: var(--classi2);
  display: flex;
  align-items: center;
  gap: 0.5vw;
}

input[type="number"] {
  width: 5vw;
  font-size: 1.3vw;
  text-align: center;
  background-color: transparent;
  color: var(--classi2);
  border: 1px solid var(--classi2);
  border-radius: 0.5rem;
}

.marks-viewer {
  padding: 0.5vh 1vw;
  background-color: var(--secondary);
  color: var(--classi2);
  border: 1px solid var(--classi2);
  border-radius: 0.5rem;
  font-size: 1vw;
  text-align: center;
}

.wysiwyg {
  margin-bottom: 2vh;
}

.edit-button {
  float: right;
  padding: 1vh 1vw;
  margin-bottom: 1vw;
  display: flex;
  align-items: center;
  font-size: 1.1vw;
  background-color: var(--classi2);
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
}

.edit-button:hover {
  opacity: 0.6;
  transition: ease all 0.3s;
}

.edit-icon {
  padding-left: 0.5vw;
}

.question-nav-sidebar {
  position: absolute;
  left: -50px; /* Position it outside the content area */
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 1vh;
  z-index: 15; /* Increased z-index to ensure it's above other elements */
  max-height: 40vh;
  overflow-y: scroll;
  /* -ms-overflow-style: none; */
  scrollbar-width: none;
  padding: 1vh;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* .question-nav-sidebar::-webkit-scrollbar {
  display: none;
} */

.question-nav-item {
  width: 40px; /* Fixed width in pixels instead of vw */
  height: 40px; /* Fixed height in pixels instead of vw */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Fixed font size */
  cursor: pointer;
  background-color: var(--secondary);
  color: var(--classi2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  border: 2px solid var(--classi2);
  margin: 0.5vh 0;
  flex-shrink: 0; /* Prevent shrinking */
}

.question-nav-item:hover {
  transform: scale(1.1);
  background-color: var(--classi2);
  color: white;
}

.question-nav-item.active {
  background-color: var(--classi2);
  color: white;
  transform: scale(1.1);
}
</style> 