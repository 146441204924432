import { defineStore } from "pinia";
import axiosInstance from "@/utils/axiosInstance";
import { clearTenant } from "@/utils/tenantStore";

export const useStudentStore = defineStore("studentData", {
  state: () => ({
    studentId: -1,
    email: "",
    firstName: "",
    lastName: "",
    class: "",
    section: "",
    enrollmentDate: "",
    isAuthenticated: false,
    courses: [],
    timetable: [],
    sessions: 0,
  }),
  actions: {
    async login() {
      this.isAuthenticated = true;
    },
    async checkAuth() {
      try {
        const response = await axiosInstance.get("/auth/validate-token");
        if (response.status === 200 && response.data.role === "student") {
          this.isAuthenticated = true;
          // If we have a valid token but no student data, fetch it
          if (this.studentId === -1) {
            await this.getStudentData();
          }
          return true;
        } else {
          // We got a response but not for a student role
          this.isAuthenticated = false;
          return false;
        }
      } catch (error) {
        console.error("Authentication check failed:", error);
        this.isAuthenticated = false;
        
        // Clear state on auth failure
        this.email = "";
        this.firstName = "";
        // Reset other student properties
        
        // Clear localStorage and sessionStorage
        localStorage.removeItem("studentData");
        sessionStorage.removeItem("welcomed");
        
        // Clear the Pinia store state
        this.$reset();
        
        return false;
      }
    },
    async logout() {
      try {
        await axiosInstance.post("/auth/logout");
      } catch (error) {
        console.error("Error during logout:", error);
      } finally {
        // Clear tenant along with other cleanup
        clearTenant();
        // Always clear local state and storage, even if server request fails
        this.isAuthenticated = false;
        this.email = "";
        this.firstName = "";
        // Reset other student properties
        
        // Clear localStorage and sessionStorage
        localStorage.removeItem("studentData");
        sessionStorage.removeItem("welcomed");
        
        // Clear the Pinia store state
        this.$reset();
      }
    },
    setStudentData(
      studentId,
      email,
      firstName,
      lastName,
      _class,
      section,
      enrollmentDate,
      sessions
    ) {
      this.studentId = studentId;
      this.email = email;
      this.firstName = firstName;
      this.lastName = lastName;
      this.class = _class;
      this.section = section;
      this.enrollmentDate = enrollmentDate;
      this.sessions = sessions;
    },

    getCourse(courseCode) {
      return this.courses.filter((course) => course.course_code === courseCode);
    },

    async getStudentData() {
      try {
        const studentResponse = await axiosInstance.get(
          "/students/get-student"
        );
        const data = studentResponse.data;
        this.setStudentData(
          data.studentId,
          data.email,
          data.firstName,
          data.lastName,
          data.class,
          data.section,
          data.enrollmentDate,
          data.sessions
        );
      } catch (error) {
        console.log("User not found", error);
      }

      // Get student's courses
      try {
        const coursesResponse = await axiosInstance.get(
          "/students/get-courses"
        );
        this.courses = coursesResponse.data;
      } catch (error) {
        console.log("Courses not found", error);
      }

      // Get student's timetable
      try {
        const timetableResponse = await axiosInstance.get(
          "/students/get-timetable"
        );
        this.timetable = timetableResponse.data;
      } catch (error) {
        console.log("Timetable not found", error);
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [{ storage: localStorage }],
  },
});
