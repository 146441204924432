<template>
  <div class="container">
    <div class="header">
      <div title="emptySpace" style="flex: 1"></div>
      <div class="title">
        <span class="classi1">cl</span>
        <span class="classi2">a</span>
        <span class="classi1">ss</span>
        <span class="classi2">
          i
          <div class="tittle-fun"></div>
        </span>
      </div>
      <div class="themeSwitcher">
        <ThemeSwitcher />
      </div>
    </div>
    <p class="byline">The next-gen AI classroom</p>
    <button class="teachers" @click="goToTeachersPage">Teachers</button>

    <button class="students" @click="goToStudentsPage">Students</button>
    <CustomAlert />
  </div>
</template>

<script>
import CustomAlert from "@/components/CustomAlert.vue";
import ThemeSwitcher from "@/components/ThemeSwitcher.vue";
import { useAlertStore } from "@/store/alertDialog";
import axiosInstance from "@/utils/axiosInstance";

export default {
  name: "Home",
  components: {
    ThemeSwitcher,
    CustomAlert,
  },
  computed: {
    alertStore() {
      return useAlertStore();
    },
  },
  methods: {
    goToStudentsPage() {
      this.$router.push("/login");
    },
    goToTeachersPage() {
      // this.alertStore.showAlert(`Coming Soon`, "Ok");
      this.$router.push("/instructor/AITools");
    },
    pingServer() {
      axiosInstance.get("/auth/ping");
    },
  },
  mounted() {
    this.pingServer();
  },
};
</script>

<style scoped>
.container {
  text-align: center;
  font-family: var(--accent-font);
}

.header {
  display: flex;
  justify-content: space-around;
  margin-bottom: 4vh;
}

.title {
  flex: 1;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 8vh;
  font-weight: 100;
  letter-spacing: 1.5vh;
  padding: 4vh;
}

.tittle-fun {
  position: absolute;
  top: 9.7vh;
  margin-left: 0.18vw;
  width: 1.1vh;
  height: 1.1vh;
  padding: none;
  letter-spacing: normal;
  background-color: var(--classi2);
  animation: squareToCircle 4s linear infinite;
  -webkit-animation: squareToCircle 3s 2s infinite alternate;
}

.themeSwitcher {
  flex: 1;
}

.classi1 {
  color: var(--classi1);
}

.classi2 {
  color: var(--classi2);
}

.byline {
  font-size: 4vh;
  color: var(--primary);
}

button {
  margin-top: 10vh;
  margin-left: 2vw;
  margin-right: 2vw;
  height: 40vh;
  width: 35vw;
  border: 0.1rem solid var(--primary);
  /* border-radius: 1rem; */
  font-size: 1.7vh;
  font-weight: 300;
  letter-spacing: 5px;
}

.teachers {
  background-color: transparent;
  color: var(--primary);
}

.teachers:hover {
  background-color: var(--primary);
  border: none;
  color: var(--secondary);
  transition: background-color 0.5s ease;
  cursor: pointer;
}

.students {
  /* background-color: #ccc; */
  background-color: transparent;
  color: var(--primary);
}

.students:hover {
  background-color: var(--primary);
  border: none;
  color: var(--secondary);
  transition: background-color 0.5s ease;
  cursor: pointer;
}

@keyframes squareToCircle {
  0% {
    border-radius: 0;
    background: var(--classi2);
    transform: rotate(0deg);
  }
  25% {
    border-radius: 50% 0 0 0;
    background: var(--classi2);
    transform: rotate(90deg);
  }
  50% {
    border-radius: 50% 50% 0 0;
    background: var(--classi2);
    transform: rotate(180deg);
  }
  75% {
    border-radius: 50% 50% 50% 0;
    background: var(--classi2);
    transform: rotate(270deg);
  }
  100% {
    border-radius: 50%;
    background: var(--classi2);
    transform: rotate(360deg);
  }
}

/* Webkit-prefixed keyframes for older browsers */
@-webkit-keyframes squareToCircle {
  0% {
    border-radius: 0;
    background: var(--classi2);
    transform: rotate(0deg);
  }
  25% {
    border-radius: 50% 0 0 0;
    background: var(--classi2);
    transform: rotate(90deg);
  }
  50% {
    border-radius: 50% 50% 0 0;
    background: var(--classi2);
    transform: rotate(180deg);
  }
  75% {
    border-radius: 50% 50% 50% 0;
    background: var(--classi2);
    transform: rotate(270deg);
  }
  100% {
    border-radius: 50%;
    background: var(--classi2);
    transform: rotate(360deg);
  }
}
</style>
