<template>
  <div>
    <div class="popup-overlay" @click="closePopup"></div>
    <div class="popup-window">
      <p class="popup-heading">New Assignment</p>

      <!-- Assignment Type Selector -->
      <AssignmentTypeSelector 
        :assignment-type="assignmentType" 
        @select-option="selectOption" 
      />

      <!-- Create from scratch -->
      <AssignmentFromScratch 
        v-if="assignmentType === 'scratch'"
        :title="title"
        :description="description"
        :due-date="dueDate"
        :current-step="currentStep"
        :questions="questions"
        @update:title="title = $event"
        @update:description="description = $event"
        @update:due-date="dueDate = $event"
        @update:questions="questions = $event"
        @update:current-step="currentStep = $event"
        @next-step="nextStep"
        @prev-step="prevStep"
        @add-question="addQuestion"
        @remove-question="removeQuestion"
        @create-assignment="createAssignment('scratch')"
      />

      <!-- Create from document -->
      <AssignmentFromDocument 
        v-else-if="assignmentType === 'document'"
        :marking-scheme-url="markingSchemeUrl"
        :is-marking-scheme-pdf="isMarkingSchemePdf"
        :marking-scheme-title="markingSchemeTitle"
        :due-date="dueDate"
        :express-processing="expressProcessing"
        :marking-scheme-loading="markingSchemeLoading"
        :marking-scheme-success="markingSchemeSuccess"
        :processing-progress="processingProgress"
        :view-questions="viewQuestions"
        :parsed-questions="parsedQuestions"
        :edit-mode="editMode"
        :current-files="currentFiles"
        @update:marking-scheme-title="markingSchemeTitle = $event"
        @update:due-date="dueDate = $event"
        @update:express-processing="expressProcessing = $event"
        @update:view-questions="viewQuestions = $event"
        @update:edit-mode="editMode = $event"
        @update:parsed-questions="parsedQuestions = $event"
        @load-marking-scheme="loadMarkingScheme"
        @remove-file="removeFile"
        @go-to-question-viewer="goToQuestionViewer"
        @go-back="viewQuestions = false"
        @save-edits="saveEdits"
        @create-assignment="createAssignment('document')"
      />

      <!-- Create with AI -->
      <AssignmentWithAI
        v-else
        :title="title"
        :due-date="dueDate"
        @update:title="title = $event"
        @update:due-date="dueDate = $event"
        @edit-question="handleEditQuestion"
        @create-assignment="createAssignment"
      />
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/utils/axiosInstance";
import { useInstructorStore } from "@/store/instructorData";
import TeacherTiptap from "@/components/TeacherTiptap.vue";
import TiptapPreview from "@/components/TiptapPreview.vue";
import FileUploader from "@/components/FileUploader.vue";
import FileDisplay from "@/components/FileDisplay.vue";
import Tooltip from '@/components/Tooltip.vue';
import { getTenant } from "@/utils/tenantStore";

// Import new components
import AssignmentTypeSelector from "@/components/grader/AssignmentTypeSelector.vue";
import AssignmentFromScratch from "@/components/grader/AssignmentFromScratch.vue";
import AssignmentFromDocument from "@/components/grader/AssignmentFromDocument.vue";
import AssignmentWithAI from "@/components/grader/AssignmentWithAI.vue";

export default {
  components: {
    TeacherTiptap,
    TiptapPreview,
    FileUploader,
    FileDisplay,
    Tooltip,
    AssignmentTypeSelector,
    AssignmentFromScratch,
    AssignmentFromDocument,
    AssignmentWithAI,
  },
  data() {
    return {
      assignmentType: "document",
      title: "",
      description: "",
      dueDate: null,
      loading: false,
      fileUrl: null,
      currentStep: 0,
      questions: [
        {
          content: "",
          marks: 1,
        },
      ],
      markingSchemeUrl: null,
      isMarkingSchemePdf: false,
      previousAssignments: [],
      markingSchemeLoading: false,
      markingSchemeSuccess: false,
      markingSchemeId: -1,
      markingSchemeTitle: "",
      currentFiles: {
        markingScheme: null,
      },
      parsedQuestions: {},
      viewQuestions: false,
      editMode: -1,
      expressProcessing: 'normal',
      processingProgress: null,
      gsutil_url: null,
    };
  },
  computed: {
    instructorStore() {
      return useInstructorStore();
    },
    sliderStyle() {
      const positions = {
        'document': "translateX(0%)",
        'scratch': "translateX(100%)",
        'ai': "translateX(200%)"
      };
      
      return {
        transform: positions[this.assignmentType] || "translateX(0%)",
        width: "33.33%",
      };
    },
    totalMarks() {
      if (this.assignmentType === 'document') {
        let total = 0;
        for (const question in this.parsedQuestions) {
          total += this.parsedQuestions[question]["marks_possible"];
        }
        return total;
      } else {
        return this.calculateTotalMarks();
      }
    },
  },
  methods: {
    closePopup() {
      this.$emit("close");
      this.resetForm();
    },
    selectOption(option) {
      this.assignmentType = option;
      this.resetForm();
    },
    nextStep() {
      // Validate before advancing to the next step
      if (this.currentStep === 0) {
        if (!this.title || !this.dueDate) {
          alert("Title and due date are required.");
          return;
        }
      }

      if (this.currentStep === 1) {
        // Validate questions before proceeding to preview
        if (this.questions.length === 0) {
          alert("Please add at least one question.");
          return;
        }

        for (let i = 0; i < this.questions.length; i++) {
          if (!this.questions[i].content) {
            alert(`Question ${i + 1} content is empty.`);
            return;
          }
        }
      }

      if (this.currentStep < 2) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },
    addQuestion() {
      this.questions.push({
        content: "",
        marks: 1,
      });
    },
    removeQuestion(index) {
      this.questions.splice(index, 1);
    },
    previewAssignment() {
      // Validate questions before proceeding to preview
      if (this.questions.length === 0) {
        alert("Please add at least one question.");
        return;
      }

      for (let i = 0; i < this.questions.length; i++) {
        if (!this.questions[i].content) {
          alert(`Question ${i + 1} content is empty.`);
          return;
        }
      }

      // Navigate to preview step
      this.currentStep = 2;
    },
    handleEditQuestion(question, index) {
      // Handle editing a question from the AI-generated assignment
      // This could be implemented later if needed
      console.log('Edit question:', question, 'at index:', index);
    },
    async createAssignment(type, aiGeneratedQuestions = null) {
      this.loading = true;
      try {
        // Get the title based on assignment type
        let titleValue = type === 'scratch' ? this.title : (type === 'ai' ? this.title : this.markingSchemeTitle);
        
        // Capitalize the title properly
        if (titleValue) {
          // Split the title by spaces and capitalize the first letter of each word
          titleValue = titleValue.split(' ')
            .map(word => {
              // Skip empty strings (which can happen with multiple spaces)
              if (word.length === 0) return '';
              // Capitalize the first letter and leave the rest unchanged
              return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
        }
        
        let payload = {
          title: titleValue,
          description: this.description,
          due_date: this.dueDate,
          email: this.instructorStore.email,
          expressMode: this.expressProcessing === 'express'
        };

        // Add questions data if creating from scratch
        if (type === 'scratch') {
          payload.questions = this.questions.map((q, index) => ({
            question_number: index + 1,
            question: q.content,
            marks_possible: Number(q.marks),
            answer: q.answer || "",
            grading_instructions: q.gradingInstructions || ""
          }));
          payload.description = this.description || "";
        } 
        // Add questions data if creating from AI
        else if (type === 'ai') {
          payload.questions = aiGeneratedQuestions;
          payload.description = this.description || "";
        }
        // Add questions data and gsutil_url if creating from document
        else if (type === 'document') {
          // Convert parsedQuestions object to array
          const questionsArray = Object.values(this.parsedQuestions);
          payload.questions = questionsArray;
          
          // Include the gsutil_url if it exists
          if (this.gsutil_url) {
            payload.gsutil_url = this.gsutil_url;
          }
        }

        const response = await axiosInstance.post("/grader/add-assignment", payload);
        const data = response.data;
        if (response.status === 200) {
          this.$emit(
            "add-assignment",
            data.assignment_id,
            data.created,
            data.title,
            data.due
          );
          console.log("Assignment added successfully");
        } else {
          console.error("Failed to add assignment");
        }
      } catch (error) {
        console.error("Error adding assignment:", error);
      }

      this.closePopup();
    },
    resetForm() {
      this.title = "";
      this.description = "";
      this.dueDate = "";
      this.fileUrl = null;
      this.isMarkingSchemePdf = false;
      this.currentStep = 0;
      this.questions = [{
        content: "",
        marks: 1,
      }];
      this.expressProcessing = 'normal';
      this.markingSchemeUrl = null;
      this.markingSchemeLoading = false;
      this.markingSchemeSuccess = false;
      this.markingSchemeId = -1;
      this.markingSchemeTitle = "";
      this.currentFiles = {
        markingScheme: null,
      };
      this.parsedQuestions = {};
      this.viewQuestions = false;
      this.editMode = -1;
      this.processingProgress = null;
      this.gsutil_url = null;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.loadFile(file);
    },
    handleDragOver(event) {
      document.getElementById("drop-zone").classList.add("drag-over");
    },
    handleDragLeave(event) {
      document.getElementById("drop-zone").classList.remove("drag-over");
    },
    handleDrop(event) {
      document.getElementById("drop-zone").classList.remove("drag-over");
      const file = event.dataTransfer.files[0];
      if (file) {
        this.loadMarkingScheme([file]);
      }
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    loadMarkingScheme(files) {
      const file = files[0];
      const url = URL.createObjectURL(file);
      this.markingSchemeUrl = url;
      this.isMarkingSchemePdf = file.type === "application/pdf";
      this.currentFiles.markingScheme = file;    
    },

    removeFile() {
      URL.revokeObjectURL(this.markingSchemeUrl);
      this.markingSchemeUrl = null;
      this.currentFiles.markingScheme = null;
      this.isMarkingSchemePdf = false;
    },

    async goToQuestionViewer() {
      if (!this.markingSchemeTitle || !this.dueDate || this.expressProcessing === null) {
        alert("Please add a title, due date, and express processing selection to continue");
      } else {
        this.markingSchemeLoading = true;
        this.processingProgress = null; // Reset progress
        try {
          const formData = new FormData();
          const dueDate = new Date(this.dueDate);
          dueDate.setUTCHours(23, 59, 59, 999);

          formData.append("file", this.currentFiles.markingScheme);
          formData.append("title", this.markingSchemeTitle);
          formData.append("due", dueDate.toISOString());
          formData.append("expressMode", this.expressProcessing === 'express');
          formData.append("created_by", this.instructorStore.email);
          formData.append("marking_scheme_id", this.markingSchemeId);

          const tenant = getTenant();

          // Extract CSRF token from cookies
          const csrfToken = document.cookie
            .split(";")
            .find(item => item.trim().startsWith("csrf_access_token="))
            ?.split("=")[1];

          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/grader/process-marking-scheme`, {
            method: 'POST',
            credentials: 'include', // ensures cookies are sent
            headers: {
              'X-Tenant': tenant || 'classi',
              ...(csrfToken && { 'X-CSRF-TOKEN': csrfToken })
            },
            body: formData
          });


          const reader = response.body.getReader();
          const decoder = new TextDecoder();

          while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            const lines = decoder.decode(value).split('\n');
            for (const line of lines) {
              if (!line) continue;

              try {
                const data = JSON.parse(line);
                this.processingProgress = data;

                console.log(this.processingProgress);

                if (data.status === 'complete') {
                  // Store the gsutil_url if it exists in the response
                  if (data.gsutil_url) {
                    this.gsutil_url = data.gsutil_url;
                  } else {
                    // For backward compatibility with existing assignments
                    this.markingSchemeId = data.marking_scheme_id;
                  }
                  
                  this.parsedQuestions = {};
                  for (const question of data.questions) {
                    this.parsedQuestions[question.question_number] = question;
                  }
                  this.markingSchemeLoading = false;
                  this.markingSchemeSuccess = true;
                  setTimeout(() => {
                    this.markingSchemeSuccess = false;
                    this.viewQuestions = true;
                  }, 1000);
                }
              } catch (e) {
                console.error('Error parsing line:', e);
              }
            }
          }
        } catch (error) {
          console.error(`Error processing marking scheme:`, error);
          this.markingSchemeLoading = false;
        }
      }
    },
    async saveEdits(question_number) {
      const question = this.parsedQuestions[question_number];

      // No need to call the API since the questions haven't been written to the database yet
      // Just update the local state and exit edit mode
      console.log(`Saving local edits for question ${question_number}`);
      this.editMode = -1;
    },
    calculateTotalMarks() {
      return this.questions.reduce((sum, q) => sum + Number(q.marks), 0);
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup-window {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 70vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--accent3);
  padding: 1vw;
  box-shadow: var(--box-shadow);
  z-index: 1001;
  border-radius: 1rem;
  overflow: none;
}

.popup-heading {
  width: 100%;
  text-align: center;
  font-size: 1.4vw;
  padding-top: 1vh;
}
</style>
