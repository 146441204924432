import { defineStore } from "pinia";
import { ref } from "vue";

export const useAlertStore = defineStore("alert", () => {
    const isVisible = ref(false);
    const text = ref("");
    const okText = ref("OK");
    const cancelText = ref("");
    const okCallback = ref(null);

    const showAlert = (alertText, okBtnText = "OK", cancelBtnText = "", callback = null) => {
        text.value = alertText;
        okText.value = okBtnText;
        cancelText.value = cancelBtnText;
        okCallback.value = callback;
        isVisible.value = true;
    };

    const hideAlert = () => {
        isVisible.value = false;
    };

    const executeCallback = () => {
        if (okCallback.value && typeof okCallback.value === 'function') {
            okCallback.value();
        }
        okCallback.value = null;
    };

    return {
        isVisible,
        text,
        okText,
        cancelText,
        okCallback,
        showAlert,
        hideAlert,
        executeCallback,
    };
});
