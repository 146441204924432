<template>
    <div class="page-container">
        <!-- Combined Chat Panel / Sidebar -->
        <div class="chat-panel" :class="{ 'sidebar-mode': isSidebarMode }">
            <!-- Hamburger button -->
            <button class="hamburger-btn" @click="toggleMode">
                <span class="material-symbols-outlined">
                    {{ isSidebarMode ? 'menu_open' : 'menu' }}
                </span>
            </button>

            <!-- Help button in chat panel -->
            <button class="panel-help-button" @click="showTips" title="Show Tips">
                <span class="material-symbols-outlined">help</span>
            </button>

            <!-- Sidebar Mode Content -->
            <div v-if="isSidebarMode">
                <Sidebar @themeChange="handleThemeChange" @toggle="handleToggle" :collapsed="false" />
            </div>

            <!-- Chat Panel Mode Content -->
            <div v-else class="chat-panel-content">
                <!-- Subject & textbooks picker -->
                <SubjectTextbookPicker ref="subjectPicker" :textbooks="allTextbooks" :initialSubject="null"
                    :initialCheckedIds="[]" :subjectLocked="currentChatHasMessages"
                    @subject-textbooks-changed="onSubjectTextbooksChanged" />

                <div class="chat-panel-header">Conversations</div>
                <div v-if="isLoadingConversations" class="spinner-container">
                    <LoadingSpinner />
                </div>
                <ul v-else class="chatList" v-if="!isChatPanelCollapsed">
                    <li v-for="(chat, index) in chatHistories" :key="chat.id"
                        :class="{ active: currentChatIndex === index }">
                        <div class="chatItem" @click="switchChat(index)">
                            <input v-if="editingChatIndex === index" v-model="editingChatTitle"
                                @blur="saveChatTitle(index)" @keyup.enter="saveChatTitle(index)"
                                @keyup.esc="editingChatIndex = null" ref="chatTitleInput" class="chat-title-input"
                                :maxlength="50" @click.stop />
                            <span v-else>{{ chat.title }}</span>
                        </div>
                        <div class="chatActions">
                            <span class="material-symbols-outlined rename-icon" title="Rename"
                                @click.stop="renameChat(index)">
                                edit
                            </span>
                            <span class="material-symbols-outlined delete-icon" title="Delete"
                                @click.stop="deleteChat(index)">
                                delete
                            </span>
                        </div>
                    </li>
                </ul>
                <button class="newChatBtn" :disabled="chatHistories.length >= 10" :title="chatHistories.length >= 10
                    ? 'You have reached the maximum of 10 chats. Please delete at least one chat to create a new one.'
                    : ''
                    " @click="createNewChat" v-if="!isChatPanelCollapsed">
                    New Chat
                </button>
            </div>
        </div>

        <!-- Main content area -->
        <div class="main-container" :class="{ 'sidebar-mode': isSidebarMode }" @click="handleMainContentClick">
            <!-- Tips Modal -->
            <div v-if="showTipsModal" class="tips-modal-overlay">
                <div class="tips-modal animate-in">
                    <span class="close" @click="closeTipsModal">✕</span>
                    <h2>Welcome to AURA Chat!</h2>
                    <div class="tips-content">
                        <div class="tip">
                            <span class="material-symbols-outlined tip-icon">book</span>
                            <div>
                                <h3>Select Textbooks</h3>
                                <p>Choose your subject and textbooks from the left panel to get started. You can select only one subject for a single conversation.</p>
                            </div>
                        </div>                        
                        <div class="tip">
                            <span class="material-symbols-outlined tip-icon">mic</span>
                            <div>
                                <h3>Voice Input</h3>
                                <p>Click the microphone icon to use voice input instead of typing. This feature is only available in Google Chrome.</p>
                            </div>
                        </div>
                        <div class="tip">
                            <span class="material-symbols-outlined tip-icon">add_circle</span>
                            <div>
                                <h3>Multiple Conversations</h3>
                                <p>Create up to 10 different conversations for different topics or classes. It is recommended to create a new conversation if you switch topics, or if the conversation becomes too long.</p>
                            </div>
                        </div>
                        <div class="tip">
                            <span class="material-symbols-outlined tip-icon">edit</span>
                            <div>
                                <h3>Rename Conversations</h3>
                                <p>Click the edit icon next to a conversation to give it a meaningful name for better organization.</p>
                            </div>
                        </div>
                        <div class="tip">
                            <span class="material-symbols-outlined tip-icon">help</span>
                            <div>
                                <h3>Need Help?</h3>
                                <p>Click the help button in the top left pane to see these tips again anytime.</p>
                            </div>
                        </div>
                    </div>
                    <button class="tips-button" @click="closeTipsModal">Got it!</button>
                </div>
            </div>

            <!-- Feedback Modal -->
            <div v-if="showFeedbackModal" class="feedback-modal-overlay">
                <div class="feedback-modal">
                    <span class="close" @click="closeFeedbackModal">✕</span>
                    <p style="font-size: 1.5vw; margin-bottom: 2vh;">Feedback</p>
                    <textarea v-model="feedbackInput" placeholder="Provide feedback or report a bug"></textarea>
                    <button @click="submitFeedback">Submit</button>
                </div>
            </div>

            <!-- Chat content area -->
            <div class="contentArea">
                <div class="chatContainer" style="position: relative;">
                    <div ref="chatOutput" class="chatOutput">
                        <div v-if="isLoadingMessages" class="spinner-container">
                            <LoadingSpinner />
                        </div>
                        <template v-else>
                            <!-- If no interactions, show initial message -->
                            <div v-if="currentInteractions.length === 0" class="initial-message">
                                <h1>Hello {{ instructorStore.firstName }} {{ instructorStore.title }}, I am <span
                                        class="aura">AURA.</span></h1>
                                <div class="byline">What can I help you with today?</div>
                                <div class="byline2">

                                    Selected Textbooks: {{
                                        currentChat?.textbookIds?.map(id =>
                                            allTextbooks.find(b => b.textbook_id === id)?.title
                                        ).join(', ') || 'No textbooks selected'
                                    }}

                                </div>
                                <div v-if="mergedExampleQueries.length > 0" class="example-queries">
                                    <ul>
                                        <li v-for="query in mergedExampleQueries" :key="query" class="example-query"
                                            @click="input = query">
                                            <span class="icon">💡</span> {{ query }}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- If we have interactions, display them -->
                            <div v-for="(interaction, idx) in currentInteractions" :key="idx">
                                <p v-if="interaction.sent" class="sentBox" v-html="formatReceived(interaction.sent)">
                                </p>
                                <p v-if="interaction.received" class="receivedBox"
                                    v-html="formatReceived(interaction.received)"></p>
                            </div>

                            <!-- Typing indicator -->
                            <div v-if="isWaitingForResponse" class="receivedBox typing-indicator">
                                <span class="ellipsis"><span>.</span><span>.</span><span>.</span></span>
                            </div>
                        </template>
                    </div>

                    <!-- Input row -->
                    <div class="inputRow">
                        <div class="inputWrapper" style="position: relative;">
                            <textarea v-model="input" class="chatInput" ref="textarea"
                                style="position: absolute; bottom: 0; top: auto; transform-origin: bottom;"
                                placeholder="Chat with your textbook..." @keydown="clickSendButton"
                                @input="adjustTextareaHeight"></textarea>
                            <span v-if="speechRecognitionSupported" @click="toggleDictation"
                                class="material-symbols-outlined dictationButton" :class="{ recording: isDictating }">
                                mic
                            </span>
                        </div>
                        <span ref="sendButton" :disabled="isSendDisabled" @click="sendMessage"
                            class="material-symbols-outlined sendButton">
                            send
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import io from "socket.io-client";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { throttle } from "lodash";

import { getTenant } from "@/utils/tenantStore";
import { useInstructorStore } from "@/store/instructorData";
import axiosInstance from "@/utils/axiosInstance";
import SubjectTextbookPicker from "@/components/SubjectTextbookPicker.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Sidebar from "@/components/InstructorSidebar.vue";

export default {
    name: "InstructorChat",
    components: {
        SubjectTextbookPicker,
        LoadingSpinner,
        Sidebar,
    },
    data() {
        return {
            // schoolLogo: require("@/assets/dpsrkp.png"),
            isCollapsed: false,

            // Feedback
            showFeedbackModal: false,
            feedbackInput: "",

            // Book data
            allTextbooks: [],

            // Conversations
            chatHistories: [],
            currentChatIndex: 0,

            // Chat UI
            input: "",
            isButtonDisabled: false,
            isWaitingForResponse: false,
            isDictating: false,
            speechRecognition: null,
            speechRecognitionSupported: false,
            socket: null,
            isChatPanelCollapsed: false,
            isTypesetting: false,

            // For error handling/timeouts
            requestTimeout: null,
            editingChatIndex: null,
            editingChatTitle: "",

            isLoadingConversations: false,
            isLoadingMessages: false,

            isSidebarMode: false,
            
            // Tips modal
            showTipsModal: false,
            hasSeenTips: false,
        };
    },
    computed: {
        instructorStore() {
            return useInstructorStore();
        },
        currentChat() {
            return this.chatHistories[this.currentChatIndex] || null;
        },
        currentInteractions() {
            return this.currentChat?.interactions || [];
        },
        currentChatHasMessages() {
            return this.currentInteractions.length > 0;
        },
        isSendDisabled() {
            const hasNoTextbooks =
                !this.currentChat ||
                !this.currentChat.textbookIds ||
                this.currentChat.textbookIds.length === 0;
            return this.isButtonDisabled || hasNoTextbooks || this.input.trim() === "";
        },
        mergedExampleQueries() {
            if (!this.currentChat || !this.currentChat.subject) return [];
            const subject = this.currentChat.subject;
            const tbIds = this.currentChat.textbookIds || [];

            // Only get examples from the first textbook
            if (tbIds.length === 0) return [];
            const firstBookId = tbIds[0];
            const book = this.allTextbooks.find(
                (tb) => tb.textbook_id === firstBookId && tb.subject === subject
            );
            return book?.example_queries || [];
        },
    },
    async created() {
        this.initializeSocket();
        this.checkSpeechRecognitionSupport();
        
        // First load textbooks
        await this.fetchAllTextbooks();
        
        // Then load conversations (which might need textbook data)
        await this.fetchConversations();
        
        // Check if this is the first visit
        this.checkFirstVisit();
    },
    methods: {
        handleMainContentClick() {
            if (this.isSidebarMode) {
                this.isSidebarMode = false;
                
                // When exiting sidebar mode by clicking on main content,
                // restore the subject and textbook selections
                if (this.currentChat) {
                    this.$nextTick(() => {
                        if (this.$refs.subjectPicker) {
                            this.$refs.subjectPicker.updateSelection({
                                newSubject: this.currentChat.subject,
                                newCheckedIds: this.currentChat.textbookIds,
                            });
                        }
                    });
                }
            }
        },
        handleToggle() {
            this.isSidebarMode = !this.isSidebarMode;
            
            // When switching back from sidebar mode to chat panel mode,
            // restore the subject and textbook selections
            if (!this.isSidebarMode && this.currentChat) {
                this.$nextTick(() => {
                    if (this.$refs.subjectPicker) {
                        this.$refs.subjectPicker.updateSelection({
                            newSubject: this.currentChat.subject,
                            newCheckedIds: this.currentChat.textbookIds,
                        });
                    }
                });
            }
        },
        handleThemeChange(currentTheme) {
            this.theme = currentTheme;
        },

        /* ---------------------- Feedback Modal ---------------------- */
        closeFeedbackModal() {
            this.showFeedbackModal = false;
        },
        submitFeedback() {
            this.feedbackInput = "";
            this.showFeedbackModal = false;
        },

        /* ---------------------- Subject & Textbooks ---------------------- */
        async fetchAllTextbooks() {
            try {
                const resp = await axiosInstance.get("/chatbot/list-textbooks");
                this.allTextbooks = resp.data || [];
                return this.allTextbooks;
            } catch (err) {
                console.error("Error fetching textbooks:", err);
                alert("Failed to load textbooks. Please try again later.");
                return [];
            }
        },
        onSubjectTextbooksChanged({ subject, textbookIds }) {
            if (!this.currentChat) return;

            // If subject changed and no textbooks selected, auto-select first textbook of new subject
            if (subject !== this.currentChat.subject && (!textbookIds || textbookIds.length === 0)) {
                const firstBookInSubject = this.allTextbooks.find(tb => tb.subject === subject);
                if (firstBookInSubject) {
                    textbookIds = [firstBookInSubject.textbook_id];
                    // Update the picker component
                    this.$nextTick(() => {
                        this.$refs.subjectPicker.updateSelection({
                            newSubject: subject,
                            newCheckedIds: textbookIds,
                        });
                    });
                }
            }

            this.currentChat.subject = subject;
            this.currentChat.textbookIds = textbookIds;
        },

        /* ---------------------- Conversations ---------------------- */
        async fetchConversations() {
            this.isLoadingConversations = true;
            try {
                const resp = await axiosInstance.post("/chatbot/get-conversations", {
                    instructorId: this.instructorStore.instructorId,
                    userType: "instructor"
                });
                let data = resp.data || [];

                // If no existing convos, create a default new chat
                if (data.length === 0) {
                    // Make sure we have textbooks before trying to use them
                    if (this.allTextbooks.length > 0) {
                        const firstBook = this.allTextbooks[0];
                        data = [
                            {
                                conversation_id: -1,
                                conversation_title: "New Chat",
                                subject: firstBook.subject,
                                textbookIds: [firstBook.textbook_id],
                            },
                        ];
                    } else {
                        // Fallback if no textbooks are available
                        data = [
                            {
                                conversation_id: -1,
                                conversation_title: "New Chat",
                                subject: null,
                                textbookIds: [],
                            },
                        ];
                    }
                }

                this.chatHistories = data.map((c) => ({
                    id: c.conversation_id,
                    title: c.conversation_title,
                    conversation_created: c.conversation_created,
                    subject: c.subject || null,
                    textbookIds: c.textbookIds || [], // Now properly handled as array
                    interactions: [],
                }));

                // Sort conversations by creation date
                this.chatHistories.sort(
                    (a, b) => new Date(b.conversation_created) - new Date(a.conversation_created)
                );

                this.currentChatIndex = 0;
                await this.loadConversation(0);
            } catch (err) {
                console.error("Error fetching conversations:", err);
                alert("Failed to load conversations. Please try again later.");
            } finally {
                this.isLoadingConversations = false;
            }
        },
        async loadConversation(index) {
            const chat = this.chatHistories[index];
            if (!chat) return;
            // Update the child
            this.$nextTick(() => {
                this.$refs.subjectPicker.updateSelection({
                    newSubject: chat.subject,
                    newCheckedIds: chat.textbookIds,
                });
            });

            // If saved conversation, fetch messages
            if (chat.id !== -1) {
                this.isLoadingMessages = true;
                try {
                    const resp = await axiosInstance.post("/chatbot/get-conversation-chats", {
                        conversation_id: chat.id,
                        instructorId: this.instructorStore.instructorId,
                        userType: "instructor"
                    });
                    const msgs = resp.data || [];
                    chat.interactions = msgs.map((m) => ({
                        sent: m.query,
                        received: m.response,
                    }));
                } catch (err) {
                    console.error("Error fetching messages:", err);
                    alert("Failed to load messages for this conversation. Please try again later.");
                } finally {
                    this.isLoadingMessages = false;
                }
            }
        },
        switchChat(index) {
            if (this.isWaitingForResponse) {
                alert("Cannot switch chat while waiting for a response.");
                return;
            }
            this.currentChatIndex = index;
            this.loadConversation(index);
        },

        /**
         * Creates a new chat and:
         * 1. Copies subject/textbooks from the current chat if they exist.
         * 2. Otherwise defaults to the first subject + first textbook in `allTextbooks`.
         */
        createNewChat() {
            if (this.chatHistories.length >= 10) return;

            // 1) Attempt to copy subject + textbooks from the current chat
            let newSubject = null;
            let newTextbooks = [];
            const current = this.currentChat;

            if (current && current.subject && current.textbookIds?.length) {
                newSubject = current.subject;
                newTextbooks = [...current.textbookIds];
            }

            // 2) If no existing subject or textbooks, fallback to the first in the list
            if ((!newSubject || newTextbooks.length === 0) && this.allTextbooks.length > 0) {
                // Just pick the first textbook's subject
                const firstBook = this.allTextbooks[0];
                newSubject = firstBook.subject;
                newTextbooks = [firstBook.textbook_id];
            }

            const newChat = {
                id: -1,
                title: "New Chat",
                subject: newSubject,
                textbookIds: newTextbooks,
                interactions: [],
            };

            this.chatHistories.unshift(newChat);
            this.currentChatIndex = 0;

            // Make sure the child picker reflects these
            this.$nextTick(() => {
                this.$refs.subjectPicker.updateSelection({
                    newSubject,
                    newCheckedIds: newTextbooks,
                });
            });
        },

        renameChat(index) {
            if (this.isWaitingForResponse) {
                alert("Cannot rename chat while waiting for a response.");
                return;
            }
            const chat = this.chatHistories[index];
            this.editingChatTitle = chat.title || "New Chat";
            this.editingChatIndex = index;
        },
        async saveChatTitle(index) {
            const chat = this.chatHistories[index];
            const newTitle = this.editingChatTitle.trim();

            if (newTitle && newTitle !== chat.title) {
                try {
                    await axiosInstance.post("/chatbot/rename-conversation", {
                        conversationId: chat.id,
                        conversationTitle: newTitle,
                        instructorId: this.instructorStore.instructorId,
                        userType: "instructor"
                    });
                    chat.title = newTitle;
                } catch (err) {
                    console.error("Failed to rename chat:", err);
                    alert("Failed to rename. Please try again later.");
                }
            }
            this.editingChatIndex = null;
        },
        deleteChat(index) {
            if (this.isWaitingForResponse) {
                alert("Cannot delete chat while waiting for a response.");
                return;
            }
            const chat = this.chatHistories[index];
            const currentName = chat.title || "New Chat";
            if (!confirm(`Are you sure you want to delete "${currentName}"?`)) {
                return;
            }
            if (chat.id !== -1) {
                axiosInstance
                    .post("/chatbot/delete-conversation", {
                        conversationId: chat.id,
                        instructorId: this.instructorStore.instructorId,
                        userType: "instructor"
                    })
                    .then(() => {
                        this.chatHistories.splice(index, 1);
                        if (this.chatHistories.length === 0) {
                            // Create a new default chat if this was the last one
                            const firstBook = this.allTextbooks[0];
                            const newChat = {
                                id: -1,
                                title: "New Chat",
                                subject: firstBook?.subject || null,
                                textbookIds: firstBook ? [firstBook.textbook_id] : [],
                                interactions: [],
                            };
                            this.chatHistories.push(newChat);
                        }

                        // Update current index and load the conversation
                        this.currentChatIndex = Math.min(index, this.chatHistories.length - 1);
                        this.loadConversation(this.currentChatIndex);
                    })
                    .catch((err) => {
                        console.error("Failed to delete chat:", err);
                        alert("Failed to delete. Please try again later.");
                    });
            } else {
                this.chatHistories.splice(index, 1);
                if (this.chatHistories.length === 0) {
                    // Create a new default chat if this was the last one
                    const firstBook = this.allTextbooks[0];
                    const newChat = {
                        id: -1,
                        title: "New Chat",
                        subject: firstBook?.subject || null,
                        textbookIds: firstBook ? [firstBook.textbook_id] : [],
                        interactions: [],
                    };
                    this.chatHistories.push(newChat);
                }

                // Update current index and load the conversation
                this.currentChatIndex = Math.min(index, this.chatHistories.length - 1);
                this.loadConversation(this.currentChatIndex);
            }
        },

        /* ---------------------- Messaging ---------------------- */
        async sendMessage() {
            if (this.isSendDisabled) return;

            this.isButtonDisabled = true;
            this.isWaitingForResponse = true;

            this.currentInteractions.push({ sent: this.input, received: "" });
            this.scrollToBottom();

            const chat = this.currentChat;
            // If not saved, create the conversation
            if (chat.id === -1) {
                try {
                    const resp = await axiosInstance.post("chatbot/new-conversation", {
                        title: chat.title,
                        instructorId: this.instructorStore.instructorId,
                        userType: "instructor",
                        subject: chat.subject,
                        textbookIds: chat.textbookIds,
                    });
                    chat.id = resp.data.conversation_id;
                } catch (err) {
                    console.error("Error creating conversation:", err);
                    alert("Failed to create new conversation. Please try again later.");
                    this.isWaitingForResponse = false;
                    this.isButtonDisabled = false;
                    return;
                }
            }

            // Simple request timeout
            this.requestTimeout = setTimeout(() => {
                if (this.isWaitingForResponse) {
                    this.isWaitingForResponse = false;
                    this.isButtonDisabled = false;
                    alert("No response from the server. Please try again later.");
                }
            }, 30000);

            // Send via socket
            this.socket.emit("message", {
                conversationId: chat.id,
                conversationTitle: chat.title,
                instructorId: this.instructorStore.instructorId,
                userType: "instructor",
                firstName: this.instructorStore.firstName,
                title: this.instructorStore.title,
                subject: chat.subject,
                textbookIds: chat.textbookIds,
                query: this.input,
            });

            this.input = "";
        },
        clickSendButton(event) {
            if (event.key === "Enter" && !event.shiftKey && this.input.trim() !== "") {
                event.preventDefault();
                this.$refs.sendButton.click();
                this.$refs.textarea.style.height = "6vh";
            }
        },

        /* ---------------------- Socket ---------------------- */
        initializeSocket() {
            this.socket = io(process.env.VUE_APP_API_BASE_URL, {
                transports: ["websocket", "polling"], // WebSockets first, fallback to polling
                upgrade: true,
                withCredentials: true,
                query: {
                    tenant: getTenant() || "classi", // Pass tenant in query params
                    csrf_token: document.cookie
                        .split(";")
                        .find((item) => item.trim().startsWith("csrf_access_token="))
                        ?.split("=")[1] || ""
                }
            });

            this.socket.on("connect", () => {
                console.log("WebSocket connected");
            });

            this.socket.on("response", (data) => {
                clearTimeout(this.requestTimeout);
                this.isWaitingForResponse = false;
                if (data.message === "[END]") {
                    this.isButtonDisabled = false;
                } else {
                    const last = this.currentInteractions[this.currentInteractions.length - 1];
                    if (last) {
                        last.received += data.message;
                    }
                    this.scrollToBottom();
                }
            });

            this.socket.on("disconnect", () => {
                console.log("WebSocket disconnected");
            });

            this.socket.on("connect_error", (error) => {
                console.error("WebSocket connection error:", error);
                alert("Could not connect to the server. Please check your network.");
            });
        },


        /* ---------------------- Logout & Nav ---------------------- */
        async logout() {
            await this.instructorStore.logout();
            this.$router.push("/login");
        },
        goToSchoolWebsite() {
            window.open("https://dpsrkp.net", "_blank");
        },

        /* ---------------------- UI Helpers ---------------------- */
        toggleMode() {
            this.isSidebarMode = !this.isSidebarMode;
            
            // When switching back from sidebar mode to chat panel mode,
            // restore the subject and textbook selections
            if (!this.isSidebarMode && this.currentChat) {
                this.$nextTick(() => {
                    if (this.$refs.subjectPicker) {
                        this.$refs.subjectPicker.updateSelection({
                            newSubject: this.currentChat.subject,
                            newCheckedIds: this.currentChat.textbookIds,
                        });
                    }
                });
            }
        },
        adjustTextareaHeight(event) {
            const textarea = event.target;
            
            // Store the current scroll position
            const scrollPos = textarea.scrollTop;
            
            // First, set a fixed height to get proper scrollHeight measurement
            textarea.style.height = '6vh';
            
            // Get the minimum height in pixels
            const minHeightVh = 6;
            const minHeightPx = minHeightVh * window.innerHeight / 100;
            
            // Calculate buffer for better height measurement
            const lineHeight = parseFloat(getComputedStyle(textarea).lineHeight) || 20;
            const paddingTop = parseFloat(getComputedStyle(textarea).paddingTop) || 0;
            const paddingBottom = parseFloat(getComputedStyle(textarea).paddingBottom) || 0;
            const buffer = paddingTop + paddingBottom;
            
            // Get current content height
            const contentHeight = textarea.scrollHeight - buffer;
            
            // Only increase height if content exceeds the current height
            if (contentHeight > minHeightPx) {
                // Calculate new height within constraints 
                const maxHeightVh = 30;
                const maxHeightPx = maxHeightVh * window.innerHeight / 100;
                
                // Don't add buffer to content height as scrollHeight already includes it
                const newHeight = Math.min(contentHeight, maxHeightPx);
                textarea.style.height = `${newHeight}px`;
            } else {
                // Reset to minimum height
                textarea.style.height = `${minHeightPx}px`;
            }
            
            // Restore scroll position
            textarea.scrollTop = scrollPos;
        },
        formatReceived(received) {
            // Temporarily replace LaTeX expressions to protect them from markdown parsing
            let processedText = received;
            
            // Replace display math ($$...$$) with placeholders
            const displayMathRegex = /(\$\$|\\\[)([^]*?)(\$\$|\\\])/g;
            const displayMathMatches = [];
            processedText = processedText.replace(displayMathRegex, (match) => {
                const id = `DISPLAY_MATH_${displayMathMatches.length}`;
                displayMathMatches.push(match);
                return id;
            });
            
            // Replace inline math ($...$) with placeholders
            const inlineMathRegex = /(\$|\\\()([^\$\n]+?)(\$|\\\))/g;
            const inlineMathMatches = [];
            processedText = processedText.replace(inlineMathRegex, (match) => {
                const id = `INLINE_MATH_${inlineMathMatches.length}`;
                inlineMathMatches.push(match);
                return id;
            });
            
            // Process with marked
            const sanitized = DOMPurify.sanitize(processedText);
            let html = marked.parse(sanitized, {
                breaks: true,
                headerIds: false,
                mangle: false,
            });
            
            // Restore math expressions
            displayMathMatches.forEach((match, i) => {
                html = html.replace(`DISPLAY_MATH_${i}`, match);
            });
            
            inlineMathMatches.forEach((match, i) => {
                html = html.replace(`INLINE_MATH_${i}`, match);
            });
            
            return html;
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.chatOutput;
                if (container) {
                    container.scrollTop = container.scrollHeight;
                }
            });
        },
        typesetMath() {
            if (this.isTypesetting) return;
            this.isTypesetting = true;
            this.$nextTick(() => {
                if (window.MathJax && this.$refs.chatOutput) {
                    // Typeset the entire chat output instead of just the last element
                    window.MathJax.typesetPromise([this.$refs.chatOutput])
                        .catch((err) => console.error("MathJax error:", err))
                        .finally(() => (this.isTypesetting = false));
                } else {
                    this.isTypesetting = false;
                }
            });
        },
        throttledTypesetMath: throttle(function () {
            this.typesetMath();
        }, 1000),
        checkSpeechRecognitionSupport() {
            const SpeechRecognition =
                window.SpeechRecognition || window.webkitSpeechRecognition;
            if (SpeechRecognition) {
                this.speechRecognitionSupported = true;
                this.speechRecognition = new SpeechRecognition();
                this.speechRecognition.lang = "en-US";
                this.speechRecognition.interimResults = false;
                this.speechRecognition.maxAlternatives = 1;
                this.speechRecognition.addEventListener("result", this.onSpeechResult);
                this.speechRecognition.addEventListener("end", this.onSpeechEnd);
                this.speechRecognition.addEventListener("error", this.onSpeechError);
            } else {
                this.speechRecognitionSupported = false;
            }
        },
        toggleDictation() {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (isSafari) {
                alert("Dictation is not supported in Safari. Please use a different browser.");
                return;
            }
            if (!this.speechRecognition) {
                alert("Speech recognition is not supported in this browser.");
                return;
            }
            if (this.isDictating) {
                this.speechRecognition.stop();
                this.isDictating = false;
            } else {
                this.speechRecognition.start();
                this.isDictating = true;
            }
        },
        onSpeechResult(event) {
            const transcript = Array.from(event.results)
                .map((result) => result[0].transcript)
                .join("");
            this.input += transcript;
        },
        onSpeechEnd() {
            this.isDictating = false;
        },
        onSpeechError(event) {
            console.error("Speech recognition error:", event.error);
            this.isDictating = false;
        },
        checkFirstVisit() {
            // Check localStorage to see if the user has seen the tips before
            const hasSeenTips = localStorage.getItem('aura_has_seen_tips');
            if (!hasSeenTips) {
                // If not, show the tips modal
                this.showTipsModal = true;
            }
        },
        closeTipsModal() {
            this.showTipsModal = false;
            // Set the flag in localStorage so we don't show the tips again
            localStorage.setItem('aura_has_seen_tips', 'true');
        },
        showTips() {
            this.showTipsModal = true;
        },
    },
    watch: {
        currentInteractions: {
            handler() {
                this.throttledTypesetMath();
            },
            deep: true,
        },
        editingChatIndex(newVal) {
            if (newVal !== null) {
                this.$nextTick(() => {
                    const input = this.$refs.chatTitleInput;
                    if (input && input[0]) {
                        input[0].focus();
                        input[0].select();
                    }
                });
            }
        }
    },
    updated() {
        this.scrollToBottom();
        this.throttledTypesetMath();
    },
    mounted() {
        this.throttledTypesetMath();
    },
    beforeDestroy() {
        if (this.socket) {
            this.socket.disconnect();
        }
        if (this.speechRecognition) {
            this.speechRecognition.abort();
        }
    },
};
</script>

<style scoped>
.page-container {
    display: flex;
    height: 100vh;
    color: var(--primary);
    background-color: #f8f9fa;
}

.beta-badge {
    color: #fff;
    background-color: #6c757d;
    text-align: center;
    padding: 0.3vw 0.6vw;
    margin-left: 0.3vw;
    border-radius: 0.3vw;
    font-size: 0.7vw;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

/* Feedback modal */
.feedback-button {
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-right: 2vw;
    padding: 0.4vw;
}

.feedback-button:hover {
    color: var(--classi2);
    background-color: rgba(0,0,0,0.05);
}

.feedback-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4px);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-modal {
    background-color: #fff;
    padding: 2.5vh 2.5vw;
    width: 38vw;
    border-radius: 1.2vw;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 10px 30px rgba(0,0,0,0.2);
    animation: modalFadeIn 0.3s ease;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.feedback-modal textarea {
    width: 100%;
    height: 20vh;
    resize: none;
    font-size: 1vw;
    padding: 1.2vh 1.2vw;
    margin-bottom: 2vh;
    border-radius: 0.8vw;
    border: 1px solid #e0e0e0;
    outline: none;
    transition: border-color 0.2s;
    font-family: var(--main-font);
}

.feedback-modal textarea:focus {
    border-color: var(--classi2);
    box-shadow: 0 0 0 2px rgba(var(--classi2-rgb), 0.1);
}

.feedback-modal button {
    align-self: flex-end;
    padding: 1vh 2vw;
    border-radius: 0.8vw;
    background-color: var(--classi2);
    color: #fff;
    border: none;
    font-size: 1vw;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 500;
}

.feedback-modal button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.close {
    position: absolute;
    top: 1.2vh;
    right: 1.8vw;
    font-size: 1.5vw;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.close:hover {
    opacity: 1;
}

/* Header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vh;
    height: 11vh;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.logo {
    display: flex;
    align-items: center;
    font-size: 2.5vw;
    font-weight: 100;
    letter-spacing: 0.2vw;
    font-family: var(--accent-font);
    text-decoration: none;
}

.classi1 {
    color: var(--classi1);
    text-transform: none;
    transition: transform 0.3s;
}

.classi2 {
    color: var(--classi2);
    text-transform: none;
    transition: transform 0.3s;
}

.classi1:hover,
.classi2:hover {
    transform: translateY(-5%);
}

.school-logo {
    cursor: pointer;
    width: 7vw;
    border-radius: 1vw;
    background-color: transparent;
    transition: transform 0.3s;
}

.school-logo:hover {
    transform: scale(1.05);
}

.logout-button {
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    padding: 0.6vw;
}

.logout-button:hover {
    color: var(--classi2);
    background-color: rgba(0,0,0,0.05);
}

.main-container {
    flex: 1;
    transition: margin-left 0.3s ease;
    position: relative;
}

.chat-panel {
    background-color: #fff;
    width: 18vw;
    border-right: 1px solid rgba(0,0,0,0.1);
    box-sizing: border-box;
    padding: 2.5vh 1.8vw;
    padding-top: 5vh;
    transition: all 0.3s ease;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 0 10px rgba(0,0,0,0.03);
}

.hamburger-btn {
    position: absolute;
    top: 2vh;
    left: 1vw;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0.5vh;
    z-index: 2;
    transition: all 0.2s;
}

.hamburger-btn .material-symbols-outlined {
    font-size: 1.5vw;
    transition: all 0.3s ease;
    transform: rotate(0deg);
}

.hamburger-btn:hover .material-symbols-outlined {
    color: var(--classi2);
    transform: rotate(360deg);
}

/* Panel help button styles */
.panel-help-button {
    position: absolute;
    top: 2vh;
    right: 1vw;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0.5vh;
    z-index: 2;
    transition: all 0.2s;
}

.panel-help-button .material-symbols-outlined {
    font-size: 1.5vw;
    color: var(--classi2);
    transition: all 0.3s ease;
}

.panel-help-button:hover .material-symbols-outlined {
    transform: scale(1.1) rotate(15deg);
}

.chat-panel-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.chat-panel-header {
    font-size: 1.3vw;
    font-weight: 600;
    margin: 1.5vh 0;
    color: #333;
    letter-spacing: 0.03em;
}

.chatList {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;
    overflow-y: auto;
}

.chatList li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.8vh 0;
    padding: 1.2vh 1vw;
    border-radius: 0.7rem;
    transition: all 0.2s;
    cursor: pointer;
}

.chatList li:hover {
    background-color: rgba(0,0,0,0.05);
}

.chatList li.active {
    background-color: rgba(var(--classi2-rgb), 0.1);
    border-left: 3px solid var(--classi2);
}

.chatItem {
    flex: 1;
    font-size: 0.9vw;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #444;
}

.chatActions {
    display: flex;
    gap: 0.8vw;
    opacity: 0.7;
}

.chatList li:hover .chatActions {
    opacity: 1;
}

.rename-icon,
.delete-icon {
    font-size: 1.1vw;
    cursor: pointer;
    transition: all 0.2s;
    padding: 0.2vw;
    border-radius: 50%;
}

.rename-icon:hover,
.delete-icon:hover {
    color: var(--classi2);
    background-color: rgba(0,0,0,0.05);
}

.panel-icon,
.logout-icon {
    font-size: 1.8vw;
    transition: all 0.2s;
}

.panel-icon:hover {
    color: var(--classi2);
}

.newChatBtn {
    width: 100%;
    padding: 1.2vw 1vw;
    background-color: var(--classi2);
    color: #fff;
    border: none;
    border-radius: 0.8rem;
    cursor: pointer;
    font-size: 1vw;
    margin-top: 2vh;
    transition: all 0.3s;
    font-weight: 500;
    box-shadow: 0 2px 6px rgba(var(--classi2-rgb), 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.newChatBtn::before {
    content: "+";
    margin-right: 0.5vw;
    font-size: 1.2vw;
    font-weight: 400;
}

.newChatBtn[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: none;
}

.newChatBtn:hover:not([disabled]) {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(var(--classi2-rgb), 0.4);
}

/* Chat content */
.contentArea {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* padding: 2vh 2.5vw; */
    /* background-color: #f8f9fa; */
    width: 100%;
}

.chatContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    /* border-radius: 1.5vw; */
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0,0,0,0.08);
    overflow: hidden;
}

.chatOutput {
    flex: 1;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 3vh 3vw 1vh 3vw;
    color: var(--secondary);
    border: none;
    text-align: left;
    font-size: 1vw;
    font-family: var(--main-font);
    scroll-behavior: smooth;
}

.initial-message {
    color: var(--primary);
    font-family: var(--accent-font);
    text-align: center;
    padding: 5vh 0;
}

.initial-message h1 {
    font-weight: 200;
    font-size: 2.5vw;
    padding-bottom: 1vh;
    background: linear-gradient(120deg, #333, var(--classi2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.initial-message .byline {
    font-size: 1.6vw;
    color: grey;
}

.byline2 {
    font-family: var(--main-font);
    color: grey;
    padding: 2vh 0;
    padding-left: 0.3vw;
    font-size: 1.1vw;
    font-weight: 500;
    letter-spacing: 0.02em;
}

.aura {
    color: var(--classi2);
    font-weight: 400;
}

.example-queries {
    min-width: 50vw;
    max-width: 70vw;
    width: fit-content;
    margin: 3vh auto;
    padding: 2.5vh 3vw;
    background-color: var(--accent1);
    border-radius: 2rem;
    font-family: var(--main-font);
    box-shadow: 0 8px 20px rgba(0,0,0,0.05);
    border: 1px solid rgba(0,0,0,0.05);
}

.example-query {
    width: fit-content;
    cursor: pointer;
    list-style: none;
    padding: 0.8vh 1.5vw;
    /* margin: 1vh 0; */
    border-radius: 2rem;
    transition: all 0.2s;
    font-size: 1vw;
}

.example-query:hover {
    color: var(--classi2);
}

.icon {
    margin-right: 1vw;
    color: gold;
}

/* Chat bubbles */
.receivedBox,
.sentBox {
    line-height: 2;
    width: fit-content;
    max-width: 65vw;
    padding: 1.5vh 2vw;
    margin-bottom: 2.5vh;
    border-radius: 1.2rem;
    overflow-wrap: break-word;
    overflow-x: auto;
    font-size: 1vw;
    animation: fadeInUp 0.3s ease;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.sentBox {
    margin-left: auto;
    margin-right: 0;
    background-color: var(--classi2);
    color: var(--secondary);
    border-bottom-right-radius: 0.1rem;
}

.receivedBox {
    margin-left: 0;
    margin-right: auto;
    background-color: var(--accent1);
    color: var(--primary);
    border-bottom-left-radius: 0.1rem;
}

.typing-indicator {
    display: flex;
    align-items: center;
    padding: 1vh 2vw;
}

.ellipsis {
    display: inline-block;
}

.ellipsis span {
    display: inline-block;
    font-size: 1.8vw;
    line-height: 0;
    animation: bounce 1s infinite;
    opacity: 0.7;
}

.ellipsis span:nth-child(1) {
    animation-delay: 0s;
}

.ellipsis span:nth-child(2) {
    animation-delay: 0.2s;
}

.ellipsis span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    0%, 60%, 100% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-0.8vh);
    }
}

/* Chat input row */
.inputRow {
    display: flex;
    width: 100%;
    padding: 2vh 3vw 3vh 3vw;
    box-sizing: border-box;
    align-items: center;
    background-color: #fff;
    border-top: 1px solid rgba(0,0,0,0.05);
}

.inputWrapper {
    position: relative;
    width: 98%;
    height: 6vh;
}

textarea {
    font-family: var(--main-font);
    font-size: 1vw;
    max-height: 30vh;
}

.chatInput {
    position: absolute;
    bottom: 0;
    top: auto;
    transform-origin: bottom;
    width: 100%;
    height: 100%;
    border-radius: 1.2vw;
    padding: 1.5vh 1vw;
    box-sizing: border-box;
    resize: none;
    outline: none;
    overflow: auto;
    border: 1px solid rgba(0,0,0,0.1);
    padding-right: 3.5vw;
    transition: height 0.1s ease, border-color 0.2s;
    box-shadow: 0 2px 10px rgba(0,0,0,0.03);
}

.chatInput:focus {
    border-color: var(--classi2);
    box-shadow: 0 4px 15px rgba(0,0,0,0.05);
}

.dictationButton {
    position: absolute;
    right: 2vw;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: rgba(0,0,0,0.5);
    font-size: 1.6vw;
    transition: all 0.2s;
    padding: 0.6vh;
    border-radius: 50%;
}

.dictationButton:hover {
    color: var(--classi2);
    background-color: rgba(0,0,0,0.05);
}

.dictationButton.recording {
    animation: pulse 1s infinite;
    color: #f44336;
    background-color: rgba(244, 67, 54, 0.1);
}

@keyframes pulse {
    0% {
        transform: translateY(-50%) scale(1);
    }
    50% {
        transform: translateY(-50%) scale(1.2);
        box-shadow: 0 0 0 10px rgba(244, 67, 54, 0.1);
    }
    100% {
        transform: translateY(-50%) scale(1);
    }
}

.sendButton {
    cursor: pointer;
    display: flex;
    width: 10%;
    height: 100%;
    background-color: var(--classi2);
    color: #fff;
    border: none;
    border-radius: 1vw;
    font-size: 1.7vw;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    margin-left: 1.5vw;
    box-shadow: 0 4px 10px rgba(var(--classi2-rgb), 0.3);
}

.sendButton:hover,
.sendButton:active {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(var(--classi2-rgb), 0.4);
}

.chat-title-input {
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    font-size: 0.9vw;
    padding: 0.5vh 0.5vw;
    outline: none;
    font-family: var(--main-font);
    transition: border-color 0.2s;
}

.chat-title-input:focus {
    border-bottom: 2px solid var(--classi2);
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 3vh 0;
}

/* Make the spinner smaller for this use case */
:deep(.spinner) {
    width: 3rem;
    height: 3rem;
    border-width: 0.25rem;
}

/* Tips Modal Styles */
.tips-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.tips-modal {
    background-color: #fff;
    padding: 4vh 4vw;
    width: 65vw;
    border-radius: 1.5vw;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.25);
}

.animate-in {
    animation: slideIn 0.4s ease;
}

@keyframes slideIn {
    from { 
        transform: translateY(30px);
        opacity: 0;
    }
    to { 
        transform: translateY(0);
        opacity: 1;
    }
}

.tips-modal h2 {
    color: var(--primary);
    font-size: 2vw;
    margin-bottom: 3vh;
    text-align: center;
    font-family: var(--accent-font);
    font-weight: 300;
    letter-spacing: 0.05em;
    background: linear-gradient(120deg, #333, var(--classi2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.tips-content {
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
}

.tip {
    display: flex;
    align-items: flex-start;
    gap: 1.5vw;
    padding: 2vh 2vw;
    border-radius: 1vw;
    background-color: rgba(var(--accent1-rgb), 0.5);
    transition: all 0.3s ease;
    border: 1px solid rgba(0,0,0,0.05);
}

.tip:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    background-color: rgba(var(--accent1-rgb), 0.7);
}

.tip-icon {
    font-size: 2vw;
    color: var(--classi2);
    padding: 1.2vh;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
}

.tip h3 {
    font-size: 1.2vw;
    margin-bottom: 0.8vh;
    color: var(--primary);
    font-weight: 600;
}

.tip p {
    font-size: 0.95vw;
    line-height: 1.6;
    color: #444;
}

.tips-button {
    align-self: center;
    margin-top: 4vh;
    padding: 1.3vh 3.5vw;
    background-color: var(--classi2);
    color: white;
    border: none;
    border-radius: 2rem;
    font-size: 1.1vw;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    box-shadow: 0 5px 15px rgba(var(--classi2-rgb), 0.3);
}

.tips-button:hover {
    transform: translateY(-3px) scale(1.03);
    box-shadow: 0 8px 20px rgba(var(--classi2-rgb), 0.4);
}

/* Add support for CSS variables with RGB values for opacity control */
:root {
    --classi2-rgb: 64, 158, 255; /* Example - replace with your actual color */
    --accent1-rgb: 240, 240, 245; /* Example - replace with your actual color */
}
</style>