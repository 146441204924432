<template>
    <div class="filter-container">
        <div class="filter-header" @click="toggleFilterVisibility">
            <div class="filter-title">
                <span class="material-symbols-outlined filter-icon">filter_alt</span>
                Filters
                <span class="material-symbols-outlined expand-icon">{{ isExpanded ? 'expand_less' : 'expand_more' }}</span>
            </div>
            <button @click.stop="$emit('clearFilters')" class="clear-all-button" v-if="hasActiveFilters">
                Clear All
            </button>
        </div>

        <div class="filter-body" v-if="isExpanded">
            <div class="filter-categories">
                <!-- Status Filter -->
                <div class="filter-category">
                    <div class="category-name">Status:</div>
                    <div class="filter-chips">
                        <div 
                            v-for="(value, key) in statusOptions" 
                            :key="key"
                            :class="['filter-chip', { active: filters.completion[key] }]"
                            :data-status="key"
                            @click="toggleStatus(key)"
                        >
                            <span class="chip-text">{{ value }}</span>
                            <span v-if="filters.completion[key]" class="material-symbols-outlined remove-icon">close</span>
                        </div>
                    </div>
                </div>

                <!-- Subject Filter -->
                <div class="filter-category" v-if="subjects.length > 0">
                    <div class="category-name">Subject:</div>
                    <div class="filter-chips">
                        <div 
                            v-for="subject in subjects" 
                            :key="subject"
                            :class="['filter-chip', { active: filters.subjects.includes(subject) }]"
                            @click="toggleSubject(subject)"
                        >
                            <span class="chip-text">{{ subject }}</span>
                            <span v-if="filters.subjects.includes(subject)" class="material-symbols-outlined remove-icon">close</span>
                        </div>
                    </div>
                </div>

                <!-- Due Date Filter -->
                <div class="filter-category">
                    <div class="category-name">Due Date:</div>
                    <div class="filter-chips">
                        <div 
                            v-for="(value, key) in dueDateOptions" 
                            :key="key"
                            :class="['filter-chip', { active: filters.dueDate[key] }]"
                            @click="toggleDueDate(key)"
                        >
                            <span class="chip-text">{{ value }}</span>
                            <span v-if="filters.dueDate[key]" class="material-symbols-outlined remove-icon">close</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Active Filters Summary -->
        <div class="active-filters-summary" v-if="!isExpanded && hasActiveFilters">
            <div class="active-filter-chips">
                <!-- Status Active Filters -->
                <div v-for="(value, key) in statuses" :key="key"
                    class="active-filter-chip" :class="statusClass(key)">
                    {{ value }}
                    <span class="material-symbols-outlined mini-remove" @click.stop="toggleStatus(key)">close</span>
                </div>
                
                <!-- Subject Active Filters -->
                <div v-for="subject in activeSubjects" :key="subject"
                    class="active-filter-chip subject">
                    {{ subject }}
                    <span class="material-symbols-outlined mini-remove" @click.stop="toggleSubject(subject)">close</span>
                </div>
                
                <!-- Due Date Active Filters -->
                <div v-for="(value, key) in dueDates" :key="key"
                    class="active-filter-chip due-date">
                    {{ value }}
                    <span class="material-symbols-outlined mini-remove" @click.stop="toggleDueDate(key)">close</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        filters: Object,
        subjects: Array,
    },
    data() {
        return {
            isExpanded: false,
            statusOptions: {
                graded: 'Graded',
                submitted: 'Submitted',
                inProgress: 'In Progress',
                incomplete: 'Incomplete'
            },
            dueDateOptions: {
                today: 'Today',
                thisWeek: 'This Week',
                thisMonth: 'This Month'
            }
        };
    },
    computed: {
        hasActiveFilters() {
            // Check if any filter is active
            return (
                this.hasActiveStatusFilters ||
                this.hasActiveSubjectFilters ||
                this.hasActiveDueDateFilters
            );
        },
        hasActiveStatusFilters() {
            return Object.values(this.filters.completion).some(value => value);
        },
        hasActiveSubjectFilters() {
            return this.filters.subjects.length > 0;
        },
        hasActiveDueDateFilters() {
            return Object.values(this.filters.dueDate).some(value => value);
        },
        // Computed properties to get only the active filters
        statuses() {
            const result = {};
            for (const key in this.statusOptions) {
                if (this.filters.completion[key]) {
                    result[key] = this.statusOptions[key];
                }
            }
            return result;
        },
        activeSubjects() {
            return this.filters.subjects;
        },
        dueDates() {
            const result = {};
            for (const key in this.dueDateOptions) {
                if (this.filters.dueDate[key]) {
                    result[key] = this.dueDateOptions[key];
                }
            }
            return result;
        }
    },
    methods: {
        toggleFilterVisibility(event) {
            // Prevent toggling if clicking the Clear All button or any remove buttons
            if (!event.target.classList.contains('clear-all-button') && 
                !event.target.classList.contains('mini-remove') &&
                !event.target.classList.contains('remove-icon')) {
                this.isExpanded = !this.isExpanded;
            }
        },
        toggleStatus(status) {
            this.filters.completion[status] = !this.filters.completion[status];
        },
        toggleSubject(subject) {
            const index = this.filters.subjects.indexOf(subject);
            if (index === -1) {
                this.filters.subjects.push(subject);
            } else {
                this.filters.subjects.splice(index, 1);
            }
        },
        toggleDueDate(dateOption) {
            // First check if we're turning off a currently active filter
            if (this.filters.dueDate[dateOption]) {
                // Just turn off the current one without activating another
                this.filters.dueDate[dateOption] = false;
            } else {
                // Clear all due date filters first
                Object.keys(this.filters.dueDate).forEach(key => {
                    this.filters.dueDate[key] = false;
                });
                
                // Then set the selected one
                this.filters.dueDate[dateOption] = true;
            }
        },
        statusClass(key) {
            if (key === 'graded') return 'status-graded';
            if (key === 'submitted') return 'status-submitted';
            if (key === 'inProgress') return 'status-inProgress';
            if (key === 'incomplete') return 'status-incomplete';
            return '';
        }
    }
};
</script>

<style scoped>
.filter-container {
    width: 100%;
    background-color: var(--accent3);
    border-radius: 0.8rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2vh 1.5vw;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: all 0.2s ease;
}

.filter-header:hover {
    background-color: rgba(0, 0, 0, 0.03);
}

.filter-title {
    display: flex;
    align-items: center;
    font-size: 1vw;
    font-weight: 600;
    color: var(--primary);
}

.filter-icon {
    margin-right: 0.5vw;
    font-size: 1.2vw;
    color: var(--classi2);
}

.expand-icon {
    margin-left: 0.5vw;
    font-size: 1.2vw;
    color: var(--classi2);
    transition: transform 0.3s ease;
}

.clear-all-button {
    background-color: transparent;
    color: var(--classi2);
    border: none;
    font-size: 0.9vw;
    cursor: pointer;
    padding: 0.5vh 0.8vw;
    border-radius: 0.5rem;
    transition: all 0.2s ease;
}

.clear-all-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
    text-decoration: underline;
}

.filter-body {
    padding: 0 1.5vw 1.5vh 1.5vw;
    background-color: var(--accent3);
    transition: all 0.3s ease;
}

.filter-categories {
    display: flex;
    flex-direction: column;
    gap: 1.2vh;
}

.filter-category {
    display: flex;
    align-items: flex-start;
}

.category-name {
    width: 5vw;
    font-size: 0.9vw;
    color: var(--accent4);
    font-weight: 500;
    padding-top: 0.3vh;
}

.filter-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8vh 0.8vw;
    flex: 1;
}

.filter-chip {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 2rem;
    padding: 0.5vh 1vw;
    font-size: 0.85vw;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid transparent;
}

.filter-chip:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.filter-chip.active {
    background-color: var(--classi2);
    color: white;
}

.filter-chip.active:hover {
    opacity: 0.9;
}

.chip-text {
    margin-right: 0.3vw;
}

.remove-icon {
    font-size: 0.8vw;
    font-weight: bold;
}

.active-filters-summary {
    padding: 0 1.5vw 1.2vh 1.5vw;
}

.active-filter-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6vh 0.6vw;
    margin-top: 0.2vh;
}

.active-filter-chip {
    display: flex;
    align-items: center;
    padding: 0.3vh 0.8vw;
    border-radius: 2rem;
    font-size: 0.85vw;
    background-color: var(--classi2);
    color: white;
}

/* Status-specific colors for active filter chips */
.active-filter-chip.status-graded {
    background-color: var(--graded);
}

.active-filter-chip.status-submitted {
    background-color: var(--submitted);
}

.active-filter-chip.status-inProgress {
    background-color: var(--in-progress);
}

.active-filter-chip.status-incomplete {
    background-color: var(--incomplete);
}

/* Colors for other types of filter chips */
.active-filter-chip.subject {
    background-color: var(--classi2);
}

.active-filter-chip.due-date {
    background-color: var(--accent2);
}

.mini-remove {
    font-size: 0.8vw;
    margin-left: 0.4vw;
    cursor: pointer;
}

.mini-remove:hover {
    opacity: 0.7;
}

/* Status-specific chip colors when active in expanded mode */
.filter-chip.active[data-status="graded"] {
    background-color: var(--graded);
}

.filter-chip.active[data-status="submitted"] {
    background-color: var(--submitted);
}

.filter-chip.active[data-status="inProgress"] {
    background-color: var(--in-progress);
}

.filter-chip.active[data-status="incomplete"] {
    background-color: var(--incomplete);
}
</style>
