<template>
  <div class="document-container">
    <div class="file-wrapper">
      <!-- Overlay for loading/success states -->
      <div class="file-overlay" v-if="loading || success">
        <div v-if="loading" class="loading-container">
          <div class="progress-container">
            <div class="progress-bar">
              <div
                class="progress-fill"
                :style="{ width: `${(processedPages / totalPages) * 100 || 0}%` }"
              ></div>
            </div>
            <p class="progress-text" v-if="totalPages">
              {{ processedPages }}/{{ totalPages }} pages processed
            </p>
            <p v-else class="progress-text">
              Uploading...
            </p>
          </div>
        </div>
        <div v-if="success" class="success-icon">
          <span class="material-symbols-outlined">check</span>
        </div>
      </div>

      <!-- File preview: PDF or image -->
      <iframe
        v-if="isPdf"
        :src="fileUrl"
        type="application/pdf"
        class="pdf-display"
      ></iframe>
      <img v-else :src="fileUrl" alt="Uploaded file" class="image-display" />
    </div>

    <!-- Buttons: remove or continue -->
    <div class="display-buttons">
      <div
        :class="['remove-button', { disabled: loading }]"
        @click="!loading && $emit('remove')"
      >
        Delete<span class="material-symbols-outlined">delete</span>
      </div>
      <div
        :class="['continue-button', { disabled: loading }]"
        @click="!loading && $emit('continue')"
      >
       Next <span class="material-symbols-outlined">arrow_forward</span>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  components: {
    LoadingSpinner,
  },
  props: {
    fileUrl: String,
    isPdf: Boolean,
    loading: Boolean,
    success: Boolean,
    fileType: String,
    // Example: 
    // { "pages":5, "total_batches": 2, "batch_size": 3 } (initial info)
    // or { "status":"processing","current_batch":1,"total_batches":2, "pages_in_current_batch": 3 } for incremental progress
    processingProgress: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processedPages: 0,
      totalPages: 0,
      currentBatch: 0,
      totalBatches: 0,
      batchSize: 0
    }
  },
  watch: {
    processingProgress: {
      handler(newVal) {
        if (!newVal) return;

        // If the server first sends initial information
        if (typeof newVal.pages === 'number') {
          this.totalPages = newVal.pages;
        }
        if (typeof newVal.total_batches === 'number') {
          this.totalBatches = newVal.total_batches;
        }
        if (typeof newVal.batch_size === 'number') {
          this.batchSize = newVal.batch_size;
        }

        // If we get a progress message with "status":"processing"
        if (newVal.status === 'processing') {
          // Update batch progress
          if (typeof newVal.current_batch === 'number') {
            this.currentBatch = newVal.current_batch;
            
            // Calculate processed pages based on batch information
            if (typeof newVal.pages_in_current_batch === 'number') {
              // If server provides the exact number of pages in this batch
              this.processedPages = this.calculateProcessedPages(
                this.currentBatch, 
                this.totalBatches, 
                this.totalPages, 
                newVal.pages_in_current_batch
              );
            } else if (typeof newVal.batch_size === 'number') {
              // If server provides the batch size
              const pagesInCurrentBatch = newVal.batch_size;
              this.processedPages = this.calculateProcessedPages(
                this.currentBatch, 
                this.totalBatches, 
                this.totalPages, 
                pagesInCurrentBatch
              );
            } else {
              // Fallback: estimate based on average pages per batch
              const avgPagesPerBatch = Math.ceil(this.totalPages / this.totalBatches);
              this.processedPages = Math.min(this.currentBatch * avgPagesPerBatch, this.totalPages);
            }
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    calculateProcessedPages(currentBatch, totalBatches, totalPages, pagesInCurrentBatch) {
      if (currentBatch === totalBatches) {
        // If this is the last batch, set to total pages
        return totalPages;
      } else if (currentBatch < totalBatches) {
        // For previous batches, calculate based on average pages per batch
        const previousBatches = currentBatch - 1;
        const avgPagesPerBatch = Math.ceil(totalPages / totalBatches);
        const previousPages = previousBatches * avgPagesPerBatch;
        
        // Add pages in current batch
        return Math.min(previousPages + pagesInCurrentBatch, totalPages);
      }
      
      // Fallback
      return Math.min(Math.ceil((currentBatch / totalBatches) * totalPages), totalPages);
    }
  }
};
</script>

<style scoped>
.document-container {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.file-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

/* Overlay */
.file-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

/* Loading state */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
}

.progress-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.progress-bar {
  width: 100%;
  height: 0.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 1rem;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: var(--classi2);
  transition: width 0.3s ease;
}

.progress-text {
  color: white;
  font-size: 1rem;
  text-align: center;
}

.loading-text {
  margin-top: 1rem;
  font-size: 1rem;
  color: white;
}

/* Success state */
.success-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #4caf50;
}
.success-icon .material-symbols-outlined {
  font-size: 40px;
  color: white;
}

/* PDF or image display */
.pdf-display {
  height: 100%;
  width: 100%;
}
.image-display {
  max-height: 65vh;
  max-width: 100%;
  object-fit: contain;
}

/* Buttons area */
.display-buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  font-size: 1.3vw;
  margin-top: 2vh;
}
.remove-button,
.continue-button {
  padding: 1vw 2vw;
  border: none;
  border-radius: 1rem;
  font-size: 1vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  transition: all 0.3s ease;
  box-shadow: var(--box-shadow);
}
.continue-button {
  background-color: var(--classi2);
  color: white;
}
.remove-button {
  background-color: var(--secondary);
  color: var(--primary);
}

.continue-button:hover:not(.disabled) {
  background-color: var(--classi2);
  color: white;
}

.remove-button:hover:not(.disabled) {
  background-color: var(--primary);
  color: var(--secondary);
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>