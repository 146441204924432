<template>
  <header class="instructor-header">
    
    <div class="page-title">
      <h1>{{ pageTitle }}</h1>
    </div>
    
    <div class="header-controls">
      <div class="notifications" @click="toggleNotificationsMenu">
        <span class="material-symbols-outlined">notifications</span>
        <div v-if="hasNotifications" class="notification-badge"></div>
        <div v-if="isNotificationsMenuVisible" class="notifications-menu">
          <div class="menu-header">Notifications</div>
          <div v-if="notifications.length === 0" class="no-notifications">
            No new notifications
          </div>
          <div v-else class="notification-list">
            <div v-for="(notification, index) in notifications" :key="index" class="notification-item">
              {{ notification.message }}
            </div>
          </div>
        </div>
      </div>
      
      <div class="profile" @click="toggleProfileMenu">
        <img :src="userAvatar" alt="User Avatar" class="avatar" />
        <div v-if="isProfileMenuVisible" class="profile-menu">
          <div class="menu-item" @click="goToProfile">
            <span class="material-symbols-outlined">person</span>
            Profile
          </div>
          <div class="menu-item" @click="goToSettings">
            <span class="material-symbols-outlined">settings</span>
            Settings
          </div>
          <div class="menu-divider"></div>
          <div class="menu-item logout" @click="logout">
            <span class="material-symbols-outlined">logout</span>
            Logout
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { useInstructorStore } from "@/store/instructorData";

export default {
  name: "InstructorHeader",
  props: {
    pageTitle: {
      type: String,
      default: "Dashboard"
    }
  },
  data() {
    return {
      isNotificationsMenuVisible: false,
      isProfileMenuVisible: false,
      notifications: [],
      userAvatar: require("@/assets/JohnSnow.jpg"),      
    };
  },
  created() {
    this.instructorStore = useInstructorStore();
    // Close menus when clicking outside
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    hasNotifications() {
      return this.notifications.length > 0;
    }
  },
  methods: {
    toggleNotificationsMenu(event) {
      event.stopPropagation();
      this.isNotificationsMenuVisible = !this.isNotificationsMenuVisible;
      this.isProfileMenuVisible = false;
    },
    toggleProfileMenu(event) {
      event.stopPropagation();
      this.isProfileMenuVisible = !this.isProfileMenuVisible;
      this.isNotificationsMenuVisible = false;
    },
    handleClickOutside(event) {
      const notifications = document.querySelector('.notifications');
      const profile = document.querySelector('.profile');
      
      if (!notifications?.contains(event.target) && !profile?.contains(event.target)) {
        this.isNotificationsMenuVisible = false;
        this.isProfileMenuVisible = false;
      }
    },
    goToProfile() {
      // Navigate to profile page
      this.isProfileMenuVisible = false;
    },
    goToSettings() {
      // Navigate to settings page
      this.isProfileMenuVisible = false;
    },
    logout() {
      this.instructorStore.logout();
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.instructor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6vh;
  position: relative;
  z-index: 10;
  margin-bottom: 1vh;
  width: 100%;
}

.page-title h1 {
  font-size: 2vw;
  font-weight: 500;
  color: grey;
  margin: 0;
  font-family: var(--accent-font);
}

.header-controls {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.notifications, .profile {
  position: relative;
  cursor: pointer;
}

.notifications .material-symbols-outlined {
  font-size: 1.6rem;
  color: var(--primary);
}

.notification-badge {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 8px;
  height: 8px;
  background-color: var(--classi2);
  border-radius: 50%;
}

.avatar {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  object-fit: cover;
  /* border: 2px solid var(--classi1); */
}

.notifications-menu, .profile-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--secondary);
  border-radius: 0.5rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  min-width: 200px;
  margin-top: 0.5rem;
  z-index: 100;
}

.notifications-menu {
  width: 300px;
}

.menu-header {
  padding: 0.8rem 1rem;
  font-weight: 500;
  color: var(--primary);
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.no-notifications, .notification-item {
  padding: 0.8rem 1rem;
  color: var(--text);
}

.no-notifications {
  text-align: center;
  color: var(--text);
  opacity: 0.7;
}

.notification-item {
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.notification-item:last-child {
  border-bottom: none;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 1rem;
  transition: background-color 0.2s;
  color: var(--text);
}

.menu-item:hover {
  background-color: rgba(0,0,0,0.05);
}

.menu-item .material-symbols-outlined {
  font-size: 1.2rem;
}

.menu-divider {
  height: 1px;
  background-color: rgba(0,0,0,0.1);
}

.logout {
  color: #d32f2f;
}
</style>
