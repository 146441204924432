import { defineStore } from "pinia";
import axiosInstance from "@/utils/axiosInstance";

export const useAssignmentStore = defineStore("assignmentData", {
    state: () => ({
        assignments: {},
    }),
    actions: {
        async getAssignments() {
            try {
                const assignmentsResponse = await axiosInstance.get(
                    "/students/get-assignments"
                );
                this.assignments = assignmentsResponse.data;
            } catch (error) {
                console.log("Assignments not found", error);
            }
        },
        async getAssignmentQuestions(assignmentId) {
            try {
                const assignmentQuestionsResponse = await axiosInstance.post(
                    "/students/get-assignment-questions",
                    {
                        assignment_id: assignmentId,
                    }
                );

                const data = assignmentQuestionsResponse.data;

                return data;
            } catch (error) {
                console.log("Assignment questions not found");
            }
        },
        async updateResponseContent(
            assignmentId,
            jsonContent,
            questionNumber,
            questionId
        ) {
            try {
                const setResponse = await axiosInstance.post(
                    "/students/set-assignment-response",
                    {
                        assignment_id: assignmentId,
                        question_id: questionId,
                        question_number: questionNumber,
                        response_content: jsonContent,
                    }
                );
                console.log(setResponse);
                
                if (this.assignments[assignmentId]) {
                    if (this.assignments[assignmentId].status === 'Incomplete') {
                        this.assignments[assignmentId].status = 'In Progress';
                    }
                }
            } catch (error) {
                console.log("Could not set response", error);
            }
        },
    },
    persist: {
        enabled: true,
    },
});
