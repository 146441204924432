<template>
    <div class="chatContainer">
        <div v-if="showRemainingChats" class="remaining-chats">
            Remaining chats today: {{ remainingChats }}
        </div>
        <div ref="chatOutput" class="chatOutput">
            <div v-if="interactions.length === 0" class="initial-message">
                <h1>Hello. I am <span class="aura">AURA.</span></h1>
                <div class="byline">What would you like to learn about today?</div>
                <div class="byline2">
                    Selected Textbook: CBSE Grade XII Microeconomics
                </div>
                <div class="example-queries">
                    <ul>
                        <li v-for="(query, index) in selectedTextbookExampleQueries" 
                            :key="index" 
                            class="example-query"
                            @click="setInput(query)">
                            <span class="icon">💡</span> {{ query }}
                        </li>
                    </ul>
                </div>
            </div>
            <div v-for="(interaction, index) in interactions" :key="index">
                <p v-if="interaction.sent" class="sentBox" v-html="formatReceived(interaction.sent)"></p>
                <p v-if="interaction.received" class="receivedBox" v-html="formatReceived(interaction.received)"></p>
            </div>
            <div v-if="isWaitingForResponse" class="receivedBox typing-indicator">
                <span class="ellipsis">
                    <span>.</span><span>.</span><span>.</span>
                </span>
            </div>
        </div>
        <div class="inputRow">
            <div class="inputWrapper" style="position: relative;">
                <textarea v-model="input" class="chatInput" ref="textarea"
                    style="position: absolute; bottom: 0; top: auto; transform-origin: bottom;"
                    placeholder="Chat with your textbook..." @keydown.enter.prevent="clickSendButton"
                    @input="adjustTextareaHeight"></textarea>
                <span v-if="speechRecognitionSupported" @click="toggleDictation"
                    class="material-symbols-outlined dictationButton" :class="{ recording: isDictating }">
                    mic
                </span>
            </div>
            <span ref="sendButton" :disabled="isButtonDisabled" @click="sendMessage"
                class="material-symbols-outlined sendButton">
                send
            </span>
        </div>
    </div>
</template>

<script>
import io from "socket.io-client";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { throttle } from "lodash";
import TextbookPicker from "@/components/TextbookPicker.vue";
import { useStudentStore } from "@/store/studentData";
import axiosInstance from "@/utils/axiosInstance";

export default {
    components: {
        TextbookPicker,
    },
    emits: ["textbook-selected"],
    data() {
        return {
            selectedTextbookExampleQueries: [
                "Explain circular flow of income to me like I am 5 years old.",
                "What is the relationship between Kanye West and microeconomics?",
                "Help me create a study plan for chapter 3.",
                "Quiz me about the firm's profit maximization problem."
            ],
            socket: null,
            interactions: [],
            input: "",
            isButtonDisabled: false,
            isTypesetting: false,
            isWaitingForResponse: false,
            isDictating: false,
            speechRecognition: null,
            speechRecognitionSupported: false,
            remainingChats: 5,
            showRemainingChats: false,
        };
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
    },
    created() {
        this.initializeSocket();
        this.checkSpeechRecognitionSupport();
        this.fetchRemainingChats();
    },
    methods: {
        initializeSocket() {
            this.socket = io(process.env.VUE_APP_API_BASE_URL, {
                transports: ["polling", "websocket"],
                upgrade: true,
            });

            this.socket.on("connect", () => {
                console.log("WebSocket connected");
            });

            this.socket.on("guest-response", (data) => {
                this.isWaitingForResponse = false;
                if (data.message === "[END]") {
                    this.isButtonDisabled = false;
                } else {
                    this.interactions[this.interactions.length - 1].received +=
                        data.message;
                    this.scrollToBottom();
                }
            });

            this.socket.on("disconnect", () => {
                console.log("WebSocket disconnected");
            });

            this.socket.on("connect_error", (error) => {
                console.error("WebSocket connection error:", error);
            });
        },
        formatReceived(received) {
            const rawHtml = marked.parse(received, {
                breaks: true,
                headerIds: false,
                mangle: false,
            });
            return DOMPurify.sanitize(rawHtml);
        },
        sendMessage() {
            if (this.isButtonDisabled || this.input.trim() === "") return;
            this.isButtonDisabled = true;
            this.isWaitingForResponse = true;
            this.interactions.push({
                sent: this.input,
                received: "",
            });
            this.scrollToBottom();
            this.socket.emit("guest-message", {
                textbookId: 1,
                query: this.input,
            });
            this.input = "";
            
            this.fetchRemainingChats();
        },
        async playTextToSpeech(text) {
            try {
                const response = await axiosInstance.post('/chatbot/guest-text-to-speech', {
                    text: text,
                }, {
                    responseType: 'blob',
                });

                const audioUrl = URL.createObjectURL(response.data);
                const audio = new Audio(audioUrl);

                audio.play();
            } catch (error) {
                console.error('Error playing text-to-speech audio:', error.message);
            }
        },
        clickSendButton(event) {
            if (event.key === "Enter" && !event.shiftKey && this.input.trim() !== "") {
                event.preventDefault();
                this.$refs.sendButton.click();
                if (this.$refs.textarea) {
                    this.$refs.textarea.style.height = "6vh";
                }
            }
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.chatOutput;
                if (container) {
                    container.scrollTop = container.scrollHeight;
                }
            });
        },
        adjustTextareaHeight(event) {
            const textarea = event.target;
            
            const scrollPos = textarea.scrollTop;
            
            textarea.style.height = '6vh';
            
            const minHeightVh = 6;
            const minHeightPx = minHeightVh * window.innerHeight / 100;
            
            const lineHeight = parseFloat(getComputedStyle(textarea).lineHeight) || 20;
            const paddingTop = parseFloat(getComputedStyle(textarea).paddingTop) || 0;
            const paddingBottom = parseFloat(getComputedStyle(textarea).paddingBottom) || 0;
            const buffer = paddingTop + paddingBottom;
            
            const contentHeight = textarea.scrollHeight - buffer;
            
            if (contentHeight > minHeightPx) {
                const maxHeightVh = 30;
                const maxHeightPx = maxHeightVh * window.innerHeight / 100;
                
                const newHeight = Math.min(contentHeight, maxHeightPx);
                textarea.style.height = `${newHeight}px`;
            } else {
                textarea.style.height = `${minHeightPx}px`;
            }
            
            textarea.scrollTop = scrollPos;
        },
        typesetMath() {
            if (this.isTypesetting) return;

            this.isTypesetting = true;
            this.$nextTick(() => {
                if (window.MathJax && this.$refs.chatOutput) {
                    window.MathJax.typesetPromise([this.$refs.chatOutput])
                        .catch((err) => console.error("MathJax error:", err))
                        .finally(() => (this.isTypesetting = false));
                } else {
                    this.isTypesetting = false;
                }
            });
        },
        throttledTypesetMath: throttle(function () {
            this.typesetMath();
        }, 1000),
        checkSpeechRecognitionSupport() {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

            if (SpeechRecognition) {
                this.speechRecognitionSupported = true;
                this.speechRecognition = new SpeechRecognition();
                this.speechRecognition.lang = 'en-US';
                this.speechRecognition.interimResults = false;
                this.speechRecognition.maxAlternatives = 1;

                this.speechRecognition.addEventListener('result', this.onSpeechResult);
                this.speechRecognition.addEventListener('end', this.onSpeechEnd);
                this.speechRecognition.addEventListener('error', this.onSpeechError);
            } else {
                this.speechRecognitionSupported = false;
            }
        },
        toggleDictation() {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

            if (isSafari) {
                alert("Dictation is not supported in Safari. Please use a different browser.");
                return;
            }

            if (!this.speechRecognition) {
                alert("Speech recognition is not supported in this browser.");
                return;
            }

            if (this.isDictating) {
                this.speechRecognition.stop();
                this.isDictating = false;
            } else {
                this.speechRecognition.start();
                this.isDictating = true;
            }
        },
        onSpeechResult(event) {
            const transcript = Array.from(event.results)
                .map(result => result[0].transcript)
                .join('');
            this.input += transcript;
        },
        onSpeechEnd() {
            this.isDictating = false;
        },
        onSpeechError(event) {
            console.error("Speech recognition error:", event.error);
            this.isDictating = false;
        },
        setInput(query) {
            this.input = query;
        },
        async fetchRemainingChats() {
            try {
                const response = await axiosInstance.get('/chatbot/guest-chat-remaining');
                this.remainingChats = response.data.remainingChats;
                this.showRemainingChats = true;
            } catch (error) {
                console.error('Error fetching remaining chats:', error.message);
            }
        },
    },
    watch: {
        interactions: {
            handler() {
                this.throttledTypesetMath();
            },
            deep: true,
        },
    },
    updated() {
        this.scrollToBottom();
        this.throttledTypesetMath();
    },
    mounted() {
        this.throttledTypesetMath();
    },
    beforeDestroy() {
        if (this.socket) {
            this.socket.disconnect();
        }
        if (this.speechRecognition) {
            this.speechRecognition.abort();
        }
    },
};
</script>

<style scoped>
.chatContainer {
    height: 80vh;
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0,0,0,0.25);
    border-radius: 1rem;
    overflow: hidden;
}

.remaining-chats {
    background-color: var(--classi2);
    color: white;
    padding: 0.7vh 1vw;
    border-radius: 0.8rem;
    margin: 1vh 3vw;
    text-align: center;
    font-size: 1vw;
    letter-spacing: 0.02em;
    box-shadow: 0 4px 10px rgba(var(--classi2-rgb), 0.3);
}

.chatOutput {
    flex: 1;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 3vh 3vw 1vh 3vw;
    color: var(--secondary);
    border: none;
    text-align: left;
    font-size: 1vw;
    font-family: var(--main-font);
    scroll-behavior: smooth;
}

.initial-message {
    color: var(--primary);
    font-family: var(--accent-font);
    text-align: center;
    padding: 5vh 0;
}

.initial-message h1 {
    font-weight: 200;
    font-size: 2.5vw;
    padding-bottom: 1vh;
    background: linear-gradient(120deg, #333, var(--classi2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.initial-message .byline {
    font-size: 1.6vw;
    color: grey;
}

.byline2 {
    font-family: var(--main-font);
    color: grey;
    padding: 2vh 0;
    font-size: 1.1vw;
    font-weight: 500;
    letter-spacing: 0.02em;
}

.aura {
    color: var(--classi2);
    font-weight: 400;
}

.example-queries {
    min-width: 50vw;
    max-width: 70vw;
    width: fit-content;
    margin: 3vh auto;
    padding: 2.5vh 3vw;
    background-color: var(--accent1);
    border-radius: 2rem;
    font-family: var(--main-font);
    box-shadow: 0 8px 20px rgba(0,0,0,0.05);
    border: 1px solid rgba(0,0,0,0.05);
}

.example-query {
    width: fit-content;
    cursor: pointer;
    list-style: none;
    padding: 0.8vh 1.5vw;
    border-radius: 2rem;
    transition: all 0.2s;
    font-size: 1vw;
}

.example-query:hover {
    color: var(--classi2);
}

.icon {
    margin-right: 1vw;
    color: gold;
}

.receivedBox,
.sentBox {
    line-height: 2;
    width: fit-content;
    max-width: 65vw;
    padding: 1.5vh 2vw;
    margin-bottom: 2.5vh;
    border-radius: 1.2rem;
    overflow-wrap: break-word;
    overflow-x: auto;
    font-size: 1vw;
    animation: fadeInUp 0.3s ease;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.sentBox {
    margin-left: auto;
    margin-right: 0;
    background-color: var(--classi2);
    color: var(--secondary);
    border-bottom-right-radius: 0.1rem;
}

.receivedBox {
    margin-left: 0;
    margin-right: auto;
    background-color: var(--accent1);
    color: var(--primary);
    border-bottom-left-radius: 0.1rem;
}

.typing-indicator {
    display: flex;
    align-items: center;
    padding: 1vh 2vw;
}

.ellipsis {
    display: inline-block;
}

.ellipsis span {
    display: inline-block;
    font-size: 1.8vw;
    line-height: 0;
    animation: bounce 1s infinite;
    opacity: 0.7;
}

.ellipsis span:nth-child(1) {
    animation-delay: 0s;
}

.ellipsis span:nth-child(2) {
    animation-delay: 0.2s;
}

.ellipsis span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    0%, 60%, 100% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-0.8vh);
    }
}

.inputRow {
    display: flex;
    width: 100%;
    padding: 2vh 3vw 3vh 3vw;
    box-sizing: border-box;
    align-items: center;
    background-color: #fff;
    border-top: 1px solid rgba(0,0,0,0.05);
}

.inputWrapper {
    position: relative;
    width: 98%;
    height: 6vh;
}

textarea {
    font-family: var(--main-font);
    font-size: 1vw;
    max-height: 30vh;
}

.chatInput {
    position: absolute;
    bottom: 0;
    top: auto;
    transform-origin: bottom;
    width: 100%;
    height: 100%;
    border-radius: 1.2vw;
    padding: 1.5vh 1vw;
    box-sizing: border-box;
    resize: none;
    outline: none;
    overflow: auto;
    border: 1px solid rgba(0,0,0,0.1);
    padding-right: 3.5vw;
    transition: height 0.1s ease, border-color 0.2s;
    box-shadow: 0 2px 10px rgba(0,0,0,0.03);
}

.chatInput:focus {
    border-color: var(--classi2);
    box-shadow: 0 4px 15px rgba(0,0,0,0.05);
}

.dictationButton {
    position: absolute;
    right: 2vw;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: rgba(0,0,0,0.5);
    font-size: 1.6vw;
    transition: all 0.2s;
    padding: 0.6vh;
    border-radius: 50%;
}

.dictationButton:hover {
    color: var(--classi2);
    background-color: rgba(0,0,0,0.05);
}

.dictationButton.recording {
    animation: pulse 1s infinite;
    color: #f44336;
    background-color: rgba(244, 67, 54, 0.1);
}

@keyframes pulse {
    0% {
        transform: translateY(-50%) scale(1);
    }
    50% {
        transform: translateY(-50%) scale(1.2);
        box-shadow: 0 0 0 10px rgba(244, 67, 54, 0.1);
    }
    100% {
        transform: translateY(-50%) scale(1);
    }
}

.sendButton {
    cursor: pointer;
    display: flex;
    width: 10%;
    height: 100%;
    background-color: var(--classi2);
    color: #fff;
    border: none;
    border-radius: 1vw;
    font-size: 1.7vw;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    margin-left: 1.5vw;
    box-shadow: 0 4px 10px rgba(var(--classi2-rgb), 0.3);
}

.sendButton:hover,
.sendButton:active {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(var(--classi2-rgb), 0.4);
}

:root {
    --classi2-rgb: 64, 158, 255;
    --accent1-rgb: 240, 240, 245;
}

@media screen and (max-width: 1024px) {
    .chatOutput {
        padding: 2vh 2vw;
    }

    .receivedBox,
    .sentBox {
        max-width: 75vw;
        font-size: 1.2vw;
    }

    .initial-message h1 {
        font-size: 3vw;
    }

    .initial-message .byline {
        font-size: 2vw;
    }

    .example-queries {
        min-width: 70vw;
        padding: 2vh 2vw;
    }

    .example-query {
        font-size: 1.2vw;
    }

    .inputRow {
        padding: 1.5vh 2vw 2vh 2vw;
    }

    .dictationButton {
        font-size: 1.8vw;
    }
}

@media screen and (max-width: 600px) {
    .chatContainer {
        padding: 1vh 1vw;
    }

    .chatOutput {
        padding: 1.5vh 2vw;
    }

    .receivedBox,
    .sentBox {
        max-width: 85vw;
        font-size: 3vw;
        padding: 1.2vh 3vw;
        border-radius: 1rem;
    }

    .initial-message h1 {
        font-size: 5vw;
    }

    .initial-message .byline {
        font-size: 3.5vw;
    }

    .byline2 {
        font-size: 2.5vw;
    }

    .example-queries {
        min-width: 80vw;
        padding: 1.5vh 1.5vw;
    }

    .example-query {
        font-size: 2.5vw;
    }

    .remaining-chats {
        font-size: 2.5vw;
        padding: 1vh 2vw;
    }

    .inputWrapper {
        height: 5vh;
    }

    .chatInput {
        font-size: 3vw;
    }

    .dictationButton {
        font-size: 4vw;
        right: 3vw;
    }

    .sendButton {
        font-size: 4vw;
        width: 15%;
    }
}
</style>