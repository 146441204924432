<template>
  <div class="chart-wrapper">
    <Bar :data="chartData" :options="barChartOptions" />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "GradesBarChart",
  components: {
    Bar
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    assignments: {
      type: Array,
      required: true,
    }
  },
  methods: {
    handleBarClick(event, elements) {
      if (elements.length > 0) {
        const element = elements[0];
        const datasetIndex = element.datasetIndex;
        const pointIndex = element.index;

        this.$emit("barClicked", datasetIndex, pointIndex);
      }
    },
  },
  computed: {
    barChartOptions() {
      const minPercentage = Math.min(
        ...this.assignments.map(
          (assignment) =>
            (parseFloat(assignment.marks_obtained) /
              assignment.marks_total) *
            100
        )
      );
      const maxPercentage = Math.max(
        ...this.assignments.map(
          (assignment) =>
            (parseFloat(assignment.marks_obtained) /
              assignment.marks_total) *
            100
        )
      );

      const minY = Math.max(0, Math.floor(minPercentage / 10) * 10 - 5);
      const maxY = Math.min(100, Math.ceil(maxPercentage / 10) * 10 + 5);

      return {
        onClick: this.handleBarClick,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: "Assignment",
            },
            grid: {
              display: false,
            },
            border: {
              width: 2,
              color: "#28282B",
            },
          },
          y: {
            beginAtZero: true,
            min: 0,
            max: 100,
            title: {
              display: true,
              text: "Percentage",
            },
            ticks: {
              callback: (value) => value + "%",
            },
            grid: {
              display: false,
            },
            border: {
              width: 2,
              color: "#28282B",
            },
            ticks: {
              color: "darkgrey",
            },
          },
        },
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
            },
          },
          tooltip: {
            padding: 10,
            displayColors: false,
            backgroundColor: "#28282B",
            cornerRadius: 20,
            callbacks: {
              title: (tooltipItems) => {
                return tooltipItems[0].dataset.label;
              },
              label: (context) =>
                `${context.raw.title}: ${context.raw.y.toFixed(
                  1
                )}%`,
            },
            titleFont: {
              size: 14,
            },
            bodyFont: {
              size: 14,
            },
          },
        },
      };
    }
  },
};
</script>

<style scoped>
.chart-wrapper {
  height: 100%;
  width: 100%;
}
</style>