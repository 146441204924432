<template>
    <div :class="['main-container', { collapsed: isCollapsed }]">
        <Sidebar @toggle="handleToggle" :collapsed="isCollapsed" />
        <div :class="['content-container', { collapsed: isCollapsed }]">
            <StudentHeader title="Classes" />
            <div v-if="isMainContent">
                <!-- <div class="top-bar">
                    <button class="timetable-button" @click="goToTimetable">
                        View Timetable
                        <span class="material-symbols-outlined timetable-icon">
                            schedule
                        </span>
                    </button>
                </div> -->
                <div class="content">
                    <div v-if="loading" class="loading">Loading classes...</div>
                    <div v-else-if="error" class="error">{{ error }}</div>
                    <div v-else class="classes-grid">
                        <div
                            class="class-card"
                            v-for="course in studentStore.courses"
                            :key="course.course_id"
                            @click="goToCourse(course)"
                        >
                            <div class="course-code">{{ course.course_code }}</div>
                            <div class="title">
                                <h3>{{ course.course_name }}</h3>
                            </div>
                            <div class="instructor">
                                <span class="material-symbols-outlined">person</span>
                                <p>{{ course.instructor }}</p>
                            </div>
                            <div class="card-footer">
                                <span class="material-symbols-outlined view-icon">visibility</span>
                                <span>View Course</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <router-view v-else> </router-view>
        </div>
    </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import { useStudentStore } from "@/store/studentData";
import Tooltip from "@/components/Tooltip.vue";

export default {
    name: "StudentClasses",
    components: {
        StudentHeader,
        Sidebar,
        Tooltip,
    },
    data() {
        return {
            classes: [],
            loading: false,
            error: null,
            isCollapsed: false,
        };
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
        isMainContent() {
            return this.$route.path === "/student/classes";
        },
    },
    methods: {
        askAura(courseName) {
            this.$router.push("/chatbot");
        },
        handleToggle(collapsed) {
            this.isCollapsed = !collapsed;
        },
        goToCourse(course) {
            this.$router.push({
                name: "StudentCourse",
                params: { courseCode: course.course_code },
            });
        },
        goToTimetable() {
            this.$router.push(`/timetable`);
        },
    },
};
</script>

<style scoped>
.main-container {
    display: flex;
    transition: all 0.3s ease-in-out;
    font-family: var(--main-font);
    margin: 0;
    margin: 2vh 2vw;
    height: 96vh;
}

.main-container.collapsed .content-container {
    margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
    margin-left: 18vw;
}

.content-container {
    flex: 1;
    transition: margin-left 0.3s ease-in-out;
    color: var(--primary);
}

.top-bar {
    height: 4vh;
    display: flex;
    justify-content: flex-end;
    margin-top: 2vh;
}

.timetable-button {
    margin-right: 1vw;
    margin-top: 2vh;
    background-color: var(--classi2);
    color: white;
    height: 4vh;
    width: 12vw;
    border: none;
    border-radius: 0.7vw;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8vw;
}

.timetable-icon {
    padding-left: 0.5vw;
}

.timetable-button:hover {
    opacity: 0.6;
}

.content {
    background-color: var(--background-color);
    margin-top: 2rem;
    padding: 1rem;
}

.classes-grid {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}

.class-card {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 1.5rem;
    background-color: white;
    color: var(--primary);
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
}

.class-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.course-code {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--classi2);
    background-color: rgba(var(--classi2-rgb), 0.1);
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
}

.title {
    margin-bottom: 1.5rem;
}

.title h3 {
    font-size: 1.2rem;
    line-height: 1.4;
    margin: 0;
}

.instructor {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--accent4);
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.instructor .material-symbols-outlined {
    font-size: 1.2rem;
}

.card-footer {
    margin-top: auto;
    padding-top: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--classi2);
    font-size: 0.9rem;
    font-weight: 500;
}

.view-icon {
    font-size: 1.2rem;
}

.loading, .error {
    text-align: center;
    padding: 2rem;
    font-size: 1rem;
}
</style>
